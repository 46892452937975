import { dispatchDeliveryOrderFilterSettings } from '../DTO/dispatchDeliveryOrder/filterSettings'
import { driverFilterSettings } from '../DTO/driver/filterSettings'
import { containerFilterSettings } from '../DTO/container/filterSettings'
import { locationFilterSettings } from '../DTO/location/filterSettings'
import { ApplicationContext } from '../../store/store.interface'
import { customerFilterSettings } from '../DTO/customer/filterSettings'
import { steamShipLineFilterSettings } from '../DTO/steamShipLine/filterSettings'
import { subClientFilterSettings } from '../DTO/subClient/filterSettings'
import { TabDTO } from '../../store/reducers/tabs/interfaces'
import { powerUnitFilterSettings } from '../DTO/powerUnit/filterSettings'

export function createIdListFromObjectList(array: any[]) {
  return array.map(({ id }) => id)
}

export const combinedColumnFilterSettings = () => ({
  [TabDTO.Type.dispatchDeliveryOrder]: dispatchDeliveryOrderFilterSettings,
  [TabDTO.Type.driver]: driverFilterSettings,
  [TabDTO.Type.container]: containerFilterSettings,
  [TabDTO.Type.location]: locationFilterSettings,
  [TabDTO.Type.customer]: customerFilterSettings,
  [TabDTO.Type.steamShipLine]: steamShipLineFilterSettings,
  [TabDTO.Type.subClient]: subClientFilterSettings,
  [TabDTO.Type.powerUnit]: powerUnitFilterSettings
})

export type TGridItemInfo = {
  applicationContext: ApplicationContext
  tabId: string
  unitId: string
}

export interface IModifyGridItemActions {
  getUnitInfo: TGridItemInfo
  reset(): void
  cancel(): void
  setFetching(state: boolean): void
  modify?(modifiedGridItem: any): void
  initialObjectState?: object
}

export type TModifyGridItem = {
  applicationContext: ApplicationContext
  tabId: string
}
