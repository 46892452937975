import { DispatchDeliveryOrderStreetTurnDTO } from '../../api/api'
import { ddoDirectory } from '../DTO/dispatchDeliveryOrder/directory'
import { createSelectListFromDirectory } from './index'

const status = createSelectListFromDirectory(ddoDirectory.status)
const loadType = createSelectListFromDirectory(ddoDirectory.loadType)
const cargoType = createSelectListFromDirectory(ddoDirectory.cargoType)
const streetTurn = createSelectListFromDirectory(ddoDirectory.streetTurnStatusLabel, [
  DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE
])

export const dispatchDeliveryOrderLists = {
  status,
  loadType,
  cargoType,
  streetTurn
}
