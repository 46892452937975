import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import { DispatchDeliveryOrderStreetTurnDTO } from '../../../../../api/origin/business-logic'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { Checkbox } from '../../../../UI/Radio'
import { ActionButton } from '../../../../UI/Buttons/ActionButton'
import { preventEvent } from '../../../../../services/functions/basic'

type Props = {
  selectedValues: DispatchDeliveryOrderStreetTurnDTO.StatusEnum[]
  onStatusChange: (values: DispatchDeliveryOrderStreetTurnDTO.StatusEnum[]) => void
  onApplyClick: () => void
}

export const DdoHeaderColumnStatusDropdown = (props: Props) => {
  const { selectedValues, onStatusChange, onApplyClick } = props
  const list: Record<string, DispatchDeliveryOrderStreetTurnDTO.StatusEnum[]> = {
    All: [
      DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE,
      DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED,
      DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED,
      DispatchDeliveryOrderStreetTurnDTO.StatusEnum.REJECTED
    ]
  }
  const titleColorList: Record<string, string> = { All: '#4F4F4F' }

  const removeSectionItems = (section: string) => {
    return onStatusChange(selectedValues.filter(item => !list[section].includes(item)))
  }

  const selectSectionItems = (section: string) => {
    const clearStatuses = selectedValues.filter(item => !list[section].includes(item))

    return onStatusChange(clearStatuses.concat(list[section]))
  }

  return (
    <StyledDropDown>
      {/* @ts-ignore */}
      {Object.keys(list).map((section: DriverGridStatusSection) => {
        const isSectionSelected = list[section].every(item => selectedValues.includes(item))

        return (
          <Section key={section}>
            <SectionColorLine style={{ backgroundColor: titleColorList[section] || '#4F4F4F' }} />
            <SectionTitle
              onClick={event => {
                preventEvent(event)
                if (isSectionSelected) {
                  removeSectionItems(section)
                } else {
                  selectSectionItems(section)
                }
              }}
            >
              <Checkbox
                active={isSectionSelected}
                style={{ color: isSectionSelected ? titleColorList[section] : '#4F4F4F' }}
              />
              <Label>{section}</Label>
            </SectionTitle>

            {list[section].length > 1 &&
              list[section].map(listItem => {
                const isListItemSelected = selectedValues.includes(listItem)

                return (
                  <SectionItem
                    key={listItem}
                    onClick={event => {
                      preventEvent(event)
                      const resultedList = isListItemSelected
                        ? selectedValues.filter(item => item !== listItem)
                        : selectedValues.concat(listItem)

                      return onStatusChange(resultedList)
                    }}
                  >
                    <ColorLine style={{ backgroundColor: '#4F4F4F' }} />
                    <Checkbox
                      active={isListItemSelected}
                      style={{ color: ddoDirectory.streetTurnStatusColor[listItem] }}
                    />
                    <Label>{ddoDirectory.streetTurnStatusLabel[listItem] || listItem}</Label>
                  </SectionItem>
                )
              })}
          </Section>
        )
      })}
      <ApplyButtonContainer>
        <ActionButton round={true} filled={true} disabled={!onApplyClick} onClick={onApplyClick}>
          Apply
        </ActionButton>
      </ApplyButtonContainer>
    </StyledDropDown>
  )
}

const StyledDropDown = styled.div`
  min-width: 200px;
  background-color: white;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: default;
  overflow: auto;
`
const Section = styled.div`
  position: relative;
  margin: 4px 0;
  user-select: none;
`
const ColorLine = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
`
const SectionColorLine = styled(ColorLine)`
  border-radius: 2px 0 0 2px;
`
const SectionItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #323e4e;
  padding: 3px 8px 3px 20px;
  cursor: pointer;

  &:last-child {
    ${ColorLine} {
      border-radius: 0 0 0 2px;
    }
  }

  &:hover {
    background-color: #f5f6fa;
  }
`
const Label = styled.div`
  color: #323e4e;
  margin-left: 5px;
`

const SectionTitle = styled(SectionItem)`
  font-weight: 500;
  padding: 5px 8px;

  span {
    text-transform: uppercase;
  }
`
const ApplyButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`
