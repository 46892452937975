// tslint:disable
/**
 * User-service Express API with Swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url'

import { Configuration } from './configuration'

const BASE_PATH = 'https://localhost'.replace(/\/+$/, '')

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|'
}

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string
  options: any
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration

  constructor(configuration?: Configuration, protected basePath: string = configuration.basePath) {
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError'
  constructor(public field: string, msg?: string) {
    super(msg)
  }
}

/**
 *
 * @export
 * @interface AccessTokenDTO
 */
export interface AccessTokenDTO {}

/**
 *
 * @export
 * @interface CreateUserDTO
 */
export interface CreateUserDTO {
  /**
   *
   * @type {string}
   * @memberof CreateUserDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof CreateUserDTO
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof CreateUserDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CreateUserDTO
   */
  lastName: string
  /**
   *
   * @type {boolean}
   * @memberof CreateUserDTO
   */
  mobileApp: boolean
  /**
   *
   * @type {string}
   * @memberof CreateUserDTO
   */
  phoneNumber: string
  /**
   *
   * @type {string}
   * @memberof CreateUserDTO
   */
  username: string
}

/**
 *
 * @export
 * @interface UpdatePasswordDTO
 */
export interface UpdatePasswordDTO {
  /**
   *
   * @type {string}
   * @memberof UpdatePasswordDTO
   */
  password: string
}

/**
 *
 * @export
 * @interface UpdateUserDTO
 */
export interface UpdateUserDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateUserDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserDTO
   */
  lastName?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserDTO
   */
  mobileApp: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateUserDTO
   */
  phoneNumber: string
}

/**
 *
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  avatar?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  company?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  job?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  lastName: string
  /**
   *
   * @type {boolean}
   * @memberof UserDTO
   */
  mobileApp: boolean
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  phoneNumber: string
  /**
   *
   * @type {UserType}
   * @memberof UserDTO
   */
  type: UserType
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  updatedAt?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  username: string
}

/**
 *
 * @export
 * @interface UserEventDTO
 */
export interface UserEventDTO {
  /**
   *
   * @type {string}
   * @memberof UserEventDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UserEventDTO
   */
  operationType: UserEventDTO.OperationTypeEnum
  /**
   *
   * @type {number}
   * @memberof UserEventDTO
   */
  time: number
  /**
   *
   * @type {string}
   * @memberof UserEventDTO
   */
  userId: string
}

/**
 * @export
 * @namespace UserEventDTO
 */
export namespace UserEventDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum OperationTypeEnum {
    CREATE = <any>'CREATE',
    UPDATE = <any>'UPDATE',
    DELETE = <any>'DELETE'
  }
}

/**
 *
 * @export
 * @enum {string}
 */
export enum UserType {
  OPERATOR = <any>'OPERATOR',
  DRIVER = <any>'DRIVER',
  SERVICE = <any>'SERVICE',
  SALESREP = <any>'SALES_REP'
}

/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options: any = {}): FetchArgs {
      const localVarPath = `/health-check`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options: any = {}): FetchArgs {
      const localVarPath = `/prometheus`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).appControllerHealthCheck(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).promControllerIndex(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options?: any) {
      return DefaultApiFp(configuration).appControllerHealthCheck(options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options?: any) {
      return DefaultApiFp(configuration).promControllerIndex(options)(basePath)
    }
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerHealthCheck(options?: any) {
    return DefaultApiFp(this.configuration).appControllerHealthCheck(options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public promControllerIndex(options?: any) {
    return DefaultApiFp(this.configuration).promControllerIndex(options)(this.basePath)
  }
}

/**
 * RegistrationControllerApi - fetch parameter creator
 * @export
 */
export const RegistrationControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary confirm registration code to obtain access token
     * @param {string} phoneNumber
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationControllerConfirmCode(phoneNumber: string, code: string, options: any = {}): FetchArgs {
      // verify required parameter 'phoneNumber' is not null or undefined
      if (phoneNumber === null || phoneNumber === undefined) {
        throw new RequiredError(
          'phoneNumber',
          'Required parameter phoneNumber was null or undefined when calling registrationControllerConfirmCode.'
        )
      }
      // verify required parameter 'code' is not null or undefined
      if (code === null || code === undefined) {
        throw new RequiredError(
          'code',
          'Required parameter code was null or undefined when calling registrationControllerConfirmCode.'
        )
      }
      const localVarPath = `/registration/token`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter['phoneNumber'] = phoneNumber
      }

      if (code !== undefined) {
        localVarQueryParameter['code'] = code
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get registration code for a new or existing user
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationControllerGetCode(phoneNumber: string, options: any = {}): FetchArgs {
      // verify required parameter 'phoneNumber' is not null or undefined
      if (phoneNumber === null || phoneNumber === undefined) {
        throw new RequiredError(
          'phoneNumber',
          'Required parameter phoneNumber was null or undefined when calling registrationControllerGetCode.'
        )
      }
      const localVarPath = `/registration/code`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter['phoneNumber'] = phoneNumber
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * RegistrationControllerApi - functional programming interface
 * @export
 */
export const RegistrationControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary confirm registration code to obtain access token
     * @param {string} phoneNumber
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationControllerConfirmCode(
      phoneNumber: string,
      code: string,
      options?: any
    ): (basePath?: string) => Promise<AccessTokenDTO> {
      const localVarFetchArgs = RegistrationControllerApiFetchParamCreator(
        configuration
      ).registrationControllerConfirmCode(phoneNumber, code, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get registration code for a new or existing user
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationControllerGetCode(phoneNumber: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = RegistrationControllerApiFetchParamCreator(configuration).registrationControllerGetCode(
        phoneNumber,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * RegistrationControllerApi - factory interface
 * @export
 */
export const RegistrationControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary confirm registration code to obtain access token
     * @param {string} phoneNumber
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationControllerConfirmCode(phoneNumber: string, code: string, options?: any) {
      return RegistrationControllerApiFp(configuration).registrationControllerConfirmCode(phoneNumber, code, options)(
        basePath
      )
    },
    /**
     *
     * @summary get registration code for a new or existing user
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationControllerGetCode(phoneNumber: string, options?: any) {
      return RegistrationControllerApiFp(configuration).registrationControllerGetCode(phoneNumber, options)(basePath)
    }
  }
}

/**
 * RegistrationControllerApi - object-oriented interface
 * @export
 * @class RegistrationControllerApi
 * @extends {BaseAPI}
 */
export class RegistrationControllerApi extends BaseAPI {
  /**
   *
   * @summary confirm registration code to obtain access token
   * @param {string} phoneNumber
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationControllerApi
   */
  public registrationControllerConfirmCode(phoneNumber: string, code: string, options?: any) {
    return RegistrationControllerApiFp(this.configuration).registrationControllerConfirmCode(
      phoneNumber,
      code,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary get registration code for a new or existing user
   * @param {string} phoneNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationControllerApi
   */
  public registrationControllerGetCode(phoneNumber: string, options?: any) {
    return RegistrationControllerApiFp(this.configuration).registrationControllerGetCode(phoneNumber, options)(
      this.basePath
    )
  }
}

/**
 * UserControllerApi - fetch parameter creator
 * @export
 */
export const UserControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a user
     * @param {CreateUserDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerCreate(body: CreateUserDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling userControllerCreate.'
        )
      }
      const localVarPath = `/user`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateUserDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary deactivate the user and clean up user email and sessions
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeactivate(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling userControllerDeactivate.'
        )
      }
      const localVarPath = `/user/deactivate/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary check if user with email exists, if yes - deactivate the user and clean up user email and sessions
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeactivateByEmail(email: string, options: any = {}): FetchArgs {
      // verify required parameter 'email' is not null or undefined
      if (email === null || email === undefined) {
        throw new RequiredError(
          'email',
          'Required parameter email was null or undefined when calling userControllerDeactivateByEmail.'
        )
      }
      const localVarPath = `/user/deactivate-by-email/{email}`.replace(
        `{${'email'}}`,
        encodeURIComponent(String(email))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary find the user
     * @param {string} [phoneNumber]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerFindUser(phoneNumber?: string, email?: string, options: any = {}): FetchArgs {
      const localVarPath = `/user/find`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter['phoneNumber'] = phoneNumber
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get list of all users or filtered by role
     * @param {string} [role]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetAll(role?: string, options: any = {}): FetchArgs {
      const localVarPath = `/user`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (role !== undefined) {
        localVarQueryParameter['role'] = role
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the user information
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUser(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling userControllerGetUser.')
      }
      const localVarPath = `/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary synchronize the user and his permissions from User-service to RabbitMQ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMigrate(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling userControllerMigrate.')
      }
      const localVarPath = `/user/{id}/migrate`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary synchronize the users and their permissions from User-service to RabbitMQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMigrateAll(options: any = {}): FetchArgs {
      const localVarPath = `/user/migrate`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary synchronize the users from Keycloak to RabbitMQ
     * @param {UserEventDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerSyncUsers(body: UserEventDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling userControllerSyncUsers.'
        )
      }
      const localVarPath = `/user/sync`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication basic required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarHeaderParameter['Authorization'] =
          'Basic ' + btoa(configuration.username + ':' + configuration.password)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UserEventDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary update the user
     * @param {string} id
     * @param {UpdateUserDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdate(id: string, body: UpdateUserDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling userControllerUpdate.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling userControllerUpdate.'
        )
      }
      const localVarPath = `/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UpdateUserDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary update the user password
     * @param {string} id
     * @param {UpdatePasswordDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdatePassword(id: string, body: UpdatePasswordDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling userControllerUpdatePassword.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling userControllerUpdatePassword.'
        )
      }
      const localVarPath = `/user/{id}/password`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UpdatePasswordDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a user
     * @param {CreateUserDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerCreate(body: CreateUserDTO, options?: any): (basePath?: string) => Promise<UserDTO> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerCreate(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary deactivate the user and clean up user email and sessions
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeactivate(id: string, options?: any): (basePath?: string) => Promise<UserDTO> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerDeactivate(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary check if user with email exists, if yes - deactivate the user and clean up user email and sessions
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeactivateByEmail(email: string, options?: any): (basePath?: string) => Promise<UserDTO> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerDeactivateByEmail(
        email,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary find the user
     * @param {string} [phoneNumber]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerFindUser(
      phoneNumber?: string,
      email?: string,
      options?: any
    ): (basePath?: string) => Promise<UserDTO> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerFindUser(
        phoneNumber,
        email,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get list of all users or filtered by role
     * @param {string} [role]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetAll(role?: string, options?: any): (basePath?: string) => Promise<Array<UserDTO>> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerGetAll(role, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the user information
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUser(id: string, options?: any): (basePath?: string) => Promise<UserDTO> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerGetUser(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary synchronize the user and his permissions from User-service to RabbitMQ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMigrate(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerMigrate(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary synchronize the users and their permissions from User-service to RabbitMQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMigrateAll(options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerMigrateAll(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary synchronize the users from Keycloak to RabbitMQ
     * @param {UserEventDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerSyncUsers(body: UserEventDTO, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerSyncUsers(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary update the user
     * @param {string} id
     * @param {UpdateUserDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdate(id: string, body: UpdateUserDTO, options?: any): (basePath?: string) => Promise<UserDTO> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerUpdate(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary update the user password
     * @param {string} id
     * @param {UpdatePasswordDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdatePassword(
      id: string,
      body: UpdatePasswordDTO,
      options?: any
    ): (basePath?: string) => Promise<UserDTO> {
      const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).userControllerUpdatePassword(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create a user
     * @param {CreateUserDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerCreate(body: CreateUserDTO, options?: any) {
      return UserControllerApiFp(configuration).userControllerCreate(body, options)(basePath)
    },
    /**
     *
     * @summary deactivate the user and clean up user email and sessions
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeactivate(id: string, options?: any) {
      return UserControllerApiFp(configuration).userControllerDeactivate(id, options)(basePath)
    },
    /**
     *
     * @summary check if user with email exists, if yes - deactivate the user and clean up user email and sessions
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeactivateByEmail(email: string, options?: any) {
      return UserControllerApiFp(configuration).userControllerDeactivateByEmail(email, options)(basePath)
    },
    /**
     *
     * @summary find the user
     * @param {string} [phoneNumber]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerFindUser(phoneNumber?: string, email?: string, options?: any) {
      return UserControllerApiFp(configuration).userControllerFindUser(phoneNumber, email, options)(basePath)
    },
    /**
     *
     * @summary get list of all users or filtered by role
     * @param {string} [role]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetAll(role?: string, options?: any) {
      return UserControllerApiFp(configuration).userControllerGetAll(role, options)(basePath)
    },
    /**
     *
     * @summary get the user information
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUser(id: string, options?: any) {
      return UserControllerApiFp(configuration).userControllerGetUser(id, options)(basePath)
    },
    /**
     *
     * @summary synchronize the user and his permissions from User-service to RabbitMQ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMigrate(id: string, options?: any) {
      return UserControllerApiFp(configuration).userControllerMigrate(id, options)(basePath)
    },
    /**
     *
     * @summary synchronize the users and their permissions from User-service to RabbitMQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerMigrateAll(options?: any) {
      return UserControllerApiFp(configuration).userControllerMigrateAll(options)(basePath)
    },
    /**
     *
     * @summary synchronize the users from Keycloak to RabbitMQ
     * @param {UserEventDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerSyncUsers(body: UserEventDTO, options?: any) {
      return UserControllerApiFp(configuration).userControllerSyncUsers(body, options)(basePath)
    },
    /**
     *
     * @summary update the user
     * @param {string} id
     * @param {UpdateUserDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdate(id: string, body: UpdateUserDTO, options?: any) {
      return UserControllerApiFp(configuration).userControllerUpdate(id, body, options)(basePath)
    },
    /**
     *
     * @summary update the user password
     * @param {string} id
     * @param {UpdatePasswordDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdatePassword(id: string, body: UpdatePasswordDTO, options?: any) {
      return UserControllerApiFp(configuration).userControllerUpdatePassword(id, body, options)(basePath)
    }
  }
}

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
  /**
   *
   * @summary create a user
   * @param {CreateUserDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerCreate(body: CreateUserDTO, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerCreate(body, options)(this.basePath)
  }

  /**
   *
   * @summary deactivate the user and clean up user email and sessions
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerDeactivate(id: string, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerDeactivate(id, options)(this.basePath)
  }

  /**
   *
   * @summary check if user with email exists, if yes - deactivate the user and clean up user email and sessions
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerDeactivateByEmail(email: string, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerDeactivateByEmail(email, options)(this.basePath)
  }

  /**
   *
   * @summary find the user
   * @param {string} [phoneNumber]
   * @param {string} [email]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerFindUser(phoneNumber?: string, email?: string, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerFindUser(phoneNumber, email, options)(this.basePath)
  }

  /**
   *
   * @summary get list of all users or filtered by role
   * @param {string} [role]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerGetAll(role?: string, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerGetAll(role, options)(this.basePath)
  }

  /**
   *
   * @summary get the user information
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerGetUser(id: string, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerGetUser(id, options)(this.basePath)
  }

  /**
   *
   * @summary synchronize the user and his permissions from User-service to RabbitMQ
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerMigrate(id: string, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerMigrate(id, options)(this.basePath)
  }

  /**
   *
   * @summary synchronize the users and their permissions from User-service to RabbitMQ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerMigrateAll(options?: any) {
    return UserControllerApiFp(this.configuration).userControllerMigrateAll(options)(this.basePath)
  }

  /**
   *
   * @summary synchronize the users from Keycloak to RabbitMQ
   * @param {UserEventDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerSyncUsers(body: UserEventDTO, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerSyncUsers(body, options)(this.basePath)
  }

  /**
   *
   * @summary update the user
   * @param {string} id
   * @param {UpdateUserDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerUpdate(id: string, body: UpdateUserDTO, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerUpdate(id, body, options)(this.basePath)
  }

  /**
   *
   * @summary update the user password
   * @param {string} id
   * @param {UpdatePasswordDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public userControllerUpdatePassword(id: string, body: UpdatePasswordDTO, options?: any) {
    return UserControllerApiFp(this.configuration).userControllerUpdatePassword(id, body, options)(this.basePath)
  }
}

export type DateISOString = string // 2018-04-15T08:36:01.711Z
