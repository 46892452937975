import * as React from 'react'
import { Radio } from '../../../../Radio'
import { ManualStatusesTitle, SManualStatus, SManualStatusLabel } from './styles'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO } from '../../../../../../api/origin/business-logic'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'

type Props = {
  disabled?: boolean
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  initialStatus: DispatchDeliveryOrderViewDTO.StatusEnum
  currentStatus: DispatchDeliveryOrderViewDTO.StatusEnum
  onChangeStatus: (status: DispatchDeliveryOrderViewDTO.StatusEnum) => void
}

export const ManualStatuses = (props: Props) => {
  const { deliveryOrderType, currentStatus, initialStatus, disabled, onChangeStatus } = props
  const statusListByDOType = {
    [DeliveryOrderViewDTO.TypeEnum.IMPORT]: {
      NEW: [DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED, DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED],
      ' ': [DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE],
      HOLD: [
        DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
        DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
        DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED
      ],
      RELEASED: [
        DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
        DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
        DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED
      ]
    },
    [DeliveryOrderViewDTO.TypeEnum.EXPORT]: {
      NEW: [DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED, DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED],
      ' ': [DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE, DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE]
    },
    [DeliveryOrderViewDTO.TypeEnum.REPOSITION]: {
      NEW: [DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED, DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED]
    }
  }

  const statusList = statusListByDOType[deliveryOrderType]
  const disableNotNewStatuses = initialStatus === DispatchDeliveryOrderViewDTO.StatusEnum.NEW
  const newStatuses = [
    DispatchDeliveryOrderViewDTO.StatusEnum.NEW,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED
  ]

  return (
    <>
      {Object.keys(statusList).map((title, index) => (
        <div key={title + index}>
          {Boolean(title) && <ManualStatusesTitle>{title}</ManualStatusesTitle>}
          {statusList[title].map((status: DispatchDeliveryOrderViewDTO.StatusEnum) => {
            const _disabled = disabled || (disableNotNewStatuses && !newStatuses.includes(status))

            return (
              <SManualStatus key={status} className={title}>
                <Radio disabled={_disabled} active={currentStatus === status} onClick={() => onChangeStatus(status)} />
                <SManualStatusLabel>{ddoDirectory.status[status]}</SManualStatusLabel>
              </SManualStatus>
            )
          })}
        </div>
      ))}
    </>
  )
}
