import actionCreatorFactory, { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa'
export const businessAsyncActionCreator: ActionCreatorFactory = actionCreatorFactory('BUSINESS/ASYNC')
import {
  FetchAPI,
  FetchArgs,
  AccountingDataFilter,
  ActiveEquipmentDTO,
  ActivitiesDTO,
  ActivitiesGridItemDTO,
  ActivitiesViewDTO,
  ActivityLocationNameDTO,
  AddressDTO,
  AddressSimpleDTO,
  ApiError,
  BankingDetailsDTO,
  BooleanDTO,
  BsqRateDTO,
  BusinessPartnerDTO,
  BusinessPartnerGridItemDTO,
  BusinessPartnerViewDTO,
  BuySideQuoteDTO,
  BuySideQuoteMiscSurchargeDTO,
  BuySideQuoteSurchargeDTO,
  CargoDTO,
  ConfirmedLocalDateTimeRangeDTO,
  ContactDTO,
  ContainerDTO,
  ContainerSearchDTO,
  ContainerTypeDTO,
  ContainerViewDTO,
  CountryDTO,
  CustomerBusinessPartnerViewDTO,
  CustomerDTO,
  CustomerDdoCount,
  CustomerGridItemDTO,
  CustomerNameDTO,
  CustomerViewDTO,
  DailyCountDTO,
  DashboardDTO,
  DateTimeRangeDTO,
  DeliveryOrderAccountingReportDTO,
  DeliveryOrderDTO,
  DeliveryOrderGridItemDTO,
  DeliveryOrderSearchDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderAccountingReportDTO,
  DispatchDeliveryOrderAuditTrailDTO,
  DispatchDeliveryOrderDTO,
  DispatchDeliveryOrderDeliveryStageDTO,
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderGridItemWithActivitiesDTO,
  DispatchDeliveryOrderNumber,
  DispatchDeliveryOrderPickupStageDTO,
  DispatchDeliveryOrderReturnStageDTO,
  DispatchDeliveryOrderSearchDTO,
  DispatchDeliveryOrderSearchRequestDTO,
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentDTO,
  DocumentFileDTO,
  DocumentMetadataDTO,
  DocumentValidationDTO,
  DocumentationActivityDTO,
  DotSafetyInspectionDTO,
  DriverAvailabilityDTO,
  DriverDTO,
  DriverEntitiesDTO,
  DriverGridItemDTO,
  DriverNameDTO,
  DriverSimpleDTO,
  DriverStatusDTO,
  DriverViewDTO,
  EquipmentDTO,
  EquipmentSearchDTO,
  FeeScheduleDTO,
  Filter,
  FuelPrice,
  HazmatDTO,
  InsuranceDTO,
  JobOrderTrackingDataDTO,
  LocalDateTimeRangeDTO,
  LocationDTO,
  LocationGridItemDTO,
  LocationReducedDTO,
  LocationSimpleDTO,
  LocationViewDTO,
  MaintenanceInspectionDTO,
  MoveBuildDTO,
  MoveBuildDocumentationActivityDTO,
  MoveBuildTransportationActivityDTO,
  MoveBuildVendorDTO,
  MoveDeliveryOrderDetailsDTO,
  MoveDispatchDeliveryOrderContainerDTO,
  MoveDispatchDeliveryOrderDTO,
  MoveDispatchDeliveryOrderDetailsDTO,
  MoveDispatchDeliveryOrderEquipmentDTO,
  MoveDispatchDeliveryOrderEquipmentMapDTO,
  MoveDispatchDeliveryOrderWeightDTO,
  MoveItemActivityDTO,
  MoveItemActivityDetailsDTO,
  MoveItemActivityLocationContactDTO,
  MoveItemActivityLocationDTO,
  MoveItemActivityLocationDetailsDTO,
  NewOrderCount,
  NonPlannedActivityCountDTO,
  OngoingActivityDTO,
  OngoingActivityGroupDTO,
  OrganizationDTO,
  OrganizationInsuranceDTO,
  OrganizationViewDTO,
  PhaseKeyPerformanceIndicator,
  PowerUnitDTO,
  PowerUnitRegistrationDTO,
  PowerUnitSearchDTO,
  PowerUnitViewDTO,
  ProgressBarState,
  RegistrationDTO,
  RepairDTO,
  SellSideQuoteDTO,
  SellSideQuoteMiscSurchargeDTO,
  SellSideQuoteSurchargeDTO,
  SsqRateDTO,
  StateDTO,
  SteamShipLineDTO,
  SteamShipLineNameDTO,
  SteamShipLineViewDTO,
  SubClientBusinessPartnerViewDTO,
  SubClientDTO,
  SubClientSearchDTO,
  SubClientViewDTO,
  TerminalIntegrationDTO,
  TerminalMismatchDTO,
  TerminalNoteDTO,
  TransportationActivityBobtailFromDTO,
  TransportationActivityBobtailToDTO,
  TransportationActivityDTO,
  TransportationActivityGridItemDTO,
  TransportationActivityViewDTO,
  TroubleTicketDTO,
  TroubleTicketUpdateDTO,
  UISettingsDTO,
  VendorDTO,
  VendorNameDTO,
  VendorViewDTO,
  WorkOrderActivityDTO,
  WorkOrderContactDTO,
  WorkOrderDTO,
  WorkOrderHistoryDTO,
  WorkOrderLocationDTO,
  WorkOrderRefDTO,
  DateISOString
} from './api'
export interface IAccountingDataFindAllRequest {
  body: AccountingDataFilter
  options?: any
}
export type IAccountingDataFindAllSuccess = DispatchDeliveryOrderAccountingReportDTO[]
export type IAccountingDataFindAllError = any
export const accountingDataFindAllAction = businessAsyncActionCreator.async<
  IAccountingDataFindAllRequest,
  IAccountingDataFindAllSuccess,
  IAccountingDataFindAllError
>('ACCOUNTINGDATA_FINDALL')

export interface IActivitySchedulerFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IActivitySchedulerFindAllSuccess = OngoingActivityGroupDTO[]
export type IActivitySchedulerFindAllError = any
export const activitySchedulerFindAllAction = businessAsyncActionCreator.async<
  IActivitySchedulerFindAllRequest,
  IActivitySchedulerFindAllSuccess,
  IActivitySchedulerFindAllError
>('ACTIVITYSCHEDULER_FINDALL')

export interface IActivitySchedulerFindVendorsAssignedOnDeliveryStageRequest {
  date: string
  options?: any
}
export type IActivitySchedulerFindVendorsAssignedOnDeliveryStageSuccess = string[]
export type IActivitySchedulerFindVendorsAssignedOnDeliveryStageError = any
export const activitySchedulerFindVendorsAssignedOnDeliveryStageAction = businessAsyncActionCreator.async<
  IActivitySchedulerFindVendorsAssignedOnDeliveryStageRequest,
  IActivitySchedulerFindVendorsAssignedOnDeliveryStageSuccess,
  IActivitySchedulerFindVendorsAssignedOnDeliveryStageError
>('ACTIVITYSCHEDULER_FINDVENDORSASSIGNEDONDELIVERYSTAGE')

export interface IActivitySchedulerGetDailyCountsRequest {
  date: string
  options?: any
}
export type IActivitySchedulerGetDailyCountsSuccess = DailyCountDTO
export type IActivitySchedulerGetDailyCountsError = any
export const activitySchedulerGetDailyCountsAction = businessAsyncActionCreator.async<
  IActivitySchedulerGetDailyCountsRequest,
  IActivitySchedulerGetDailyCountsSuccess,
  IActivitySchedulerGetDailyCountsError
>('ACTIVITYSCHEDULER_GETDAILYCOUNTS')

export interface IActivitySchedulerGetKeyPerformanceIndicatorRequest {
  timezoneOffset: number
  options?: any
}
export type IActivitySchedulerGetKeyPerformanceIndicatorSuccess = PhaseKeyPerformanceIndicator
export type IActivitySchedulerGetKeyPerformanceIndicatorError = any
export const activitySchedulerGetKeyPerformanceIndicatorAction = businessAsyncActionCreator.async<
  IActivitySchedulerGetKeyPerformanceIndicatorRequest,
  IActivitySchedulerGetKeyPerformanceIndicatorSuccess,
  IActivitySchedulerGetKeyPerformanceIndicatorError
>('ACTIVITYSCHEDULER_GETKEYPERFORMANCEINDICATOR')

export interface IActivitySchedulerRebuildOngoingActivityGroupsRequest {
  ddoFilter?: string
  options?: any
}
export type IActivitySchedulerRebuildOngoingActivityGroupsSuccess = Response
export type IActivitySchedulerRebuildOngoingActivityGroupsError = any
export const activitySchedulerRebuildOngoingActivityGroupsAction = businessAsyncActionCreator.async<
  IActivitySchedulerRebuildOngoingActivityGroupsRequest,
  IActivitySchedulerRebuildOngoingActivityGroupsSuccess,
  IActivitySchedulerRebuildOngoingActivityGroupsError
>('ACTIVITYSCHEDULER_REBUILDONGOINGACTIVITYGROUPS')

export interface IActivitySchedulerRebuildOngoingActivityGroupsForDdoRequest {
  dispatchDeliveryOrderId: string
  options?: any
}
export type IActivitySchedulerRebuildOngoingActivityGroupsForDdoSuccess = Response
export type IActivitySchedulerRebuildOngoingActivityGroupsForDdoError = any
export const activitySchedulerRebuildOngoingActivityGroupsForDdoAction = businessAsyncActionCreator.async<
  IActivitySchedulerRebuildOngoingActivityGroupsForDdoRequest,
  IActivitySchedulerRebuildOngoingActivityGroupsForDdoSuccess,
  IActivitySchedulerRebuildOngoingActivityGroupsForDdoError
>('ACTIVITYSCHEDULER_REBUILDONGOINGACTIVITYGROUPSFORDDO')

export interface IAuditTrailGetAuditTrailRequest {
  id: string
  options?: any
}
export type IAuditTrailGetAuditTrailSuccess = DispatchDeliveryOrderAuditTrailDTO[]
export type IAuditTrailGetAuditTrailError = any
export const auditTrailGetAuditTrailAction = businessAsyncActionCreator.async<
  IAuditTrailGetAuditTrailRequest,
  IAuditTrailGetAuditTrailSuccess,
  IAuditTrailGetAuditTrailError
>('AUDITTRAIL_GETAUDITTRAIL')

export interface IBusinessPartnerDeleteRequest {
  id: string
  options?: any
}
export type IBusinessPartnerDeleteSuccess = Response
export type IBusinessPartnerDeleteError = any
export const businessPartnerDeleteAction = businessAsyncActionCreator.async<
  IBusinessPartnerDeleteRequest,
  IBusinessPartnerDeleteSuccess,
  IBusinessPartnerDeleteError
>('BUSINESSPARTNER_DELETE')

export interface IBusinessPartnerCreateRequest {
  body: BusinessPartnerDTO
  options?: any
}
export type IBusinessPartnerCreateSuccess = BusinessPartnerViewDTO
export type IBusinessPartnerCreateError = any
export const businessPartnerCreateAction = businessAsyncActionCreator.async<
  IBusinessPartnerCreateRequest,
  IBusinessPartnerCreateSuccess,
  IBusinessPartnerCreateError
>('BUSINESSPARTNER_CREATE')

export interface IBusinessPartnerFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IBusinessPartnerFindAllSuccess = BusinessPartnerViewDTO[]
export type IBusinessPartnerFindAllError = any
export const businessPartnerFindAllAction = businessAsyncActionCreator.async<
  IBusinessPartnerFindAllRequest,
  IBusinessPartnerFindAllSuccess,
  IBusinessPartnerFindAllError
>('BUSINESSPARTNER_FINDALL')

export interface IBusinessPartnerFindByIdRequest {
  id: string
  options?: any
}
export type IBusinessPartnerFindByIdSuccess = BusinessPartnerViewDTO
export type IBusinessPartnerFindByIdError = any
export const businessPartnerFindByIdAction = businessAsyncActionCreator.async<
  IBusinessPartnerFindByIdRequest,
  IBusinessPartnerFindByIdSuccess,
  IBusinessPartnerFindByIdError
>('BUSINESSPARTNER_FINDBYID')

export interface IBusinessPartnerUpdateRequest {
  id: string
  body: BusinessPartnerDTO
  options?: any
}
export type IBusinessPartnerUpdateSuccess = BusinessPartnerViewDTO
export type IBusinessPartnerUpdateError = any
export const businessPartnerUpdateAction = businessAsyncActionCreator.async<
  IBusinessPartnerUpdateRequest,
  IBusinessPartnerUpdateSuccess,
  IBusinessPartnerUpdateError
>('BUSINESSPARTNER_UPDATE')

export interface IBuySideQuoteApplyRateRequest {
  body: BsqRateDTO
  options?: any
}
export type IBuySideQuoteApplyRateSuccess = Response
export type IBuySideQuoteApplyRateError = any
export const buySideQuoteApplyRateAction = businessAsyncActionCreator.async<
  IBuySideQuoteApplyRateRequest,
  IBuySideQuoteApplyRateSuccess,
  IBuySideQuoteApplyRateError
>('BUYSIDEQUOTE_APPLYRATE')

export interface IBuySideQuoteCreateBuySideQuoteRequest {
  body: BuySideQuoteDTO
  options?: any
}
export type IBuySideQuoteCreateBuySideQuoteSuccess = BuySideQuoteDTO
export type IBuySideQuoteCreateBuySideQuoteError = any
export const buySideQuoteCreateBuySideQuoteAction = businessAsyncActionCreator.async<
  IBuySideQuoteCreateBuySideQuoteRequest,
  IBuySideQuoteCreateBuySideQuoteSuccess,
  IBuySideQuoteCreateBuySideQuoteError
>('BUYSIDEQUOTE_CREATEBUYSIDEQUOTE')

export interface IBuySideQuoteDeleteBuySideQuoteRequest {
  id: string
  options?: any
}
export type IBuySideQuoteDeleteBuySideQuoteSuccess = Response
export type IBuySideQuoteDeleteBuySideQuoteError = any
export const buySideQuoteDeleteBuySideQuoteAction = businessAsyncActionCreator.async<
  IBuySideQuoteDeleteBuySideQuoteRequest,
  IBuySideQuoteDeleteBuySideQuoteSuccess,
  IBuySideQuoteDeleteBuySideQuoteError
>('BUYSIDEQUOTE_DELETEBUYSIDEQUOTE')

export interface IBuySideQuoteFindByIdRequest {
  id: string
  options?: any
}
export type IBuySideQuoteFindByIdSuccess = BuySideQuoteDTO
export type IBuySideQuoteFindByIdError = any
export const buySideQuoteFindByIdAction = businessAsyncActionCreator.async<
  IBuySideQuoteFindByIdRequest,
  IBuySideQuoteFindByIdSuccess,
  IBuySideQuoteFindByIdError
>('BUYSIDEQUOTE_FINDBYID')

export interface IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdRequest {
  dispatchDeliveryOrderId: string
  options?: any
}
export type IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdSuccess = BuySideQuoteDTO[]
export type IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdError = any
export const buySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdAction = businessAsyncActionCreator.async<
  IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdRequest,
  IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdSuccess,
  IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdError
>('BUYSIDEQUOTE_GETBUYSIDEQUOTEBYDISPATCHDELIVERYORDERID')

export interface IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdListRequest {
  body: Array<string>
  options?: any
}
export type IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdListSuccess = BuySideQuoteDTO[]
export type IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdListError = any
export const buySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdListAction = businessAsyncActionCreator.async<
  IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdListRequest,
  IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdListSuccess,
  IBuySideQuoteGetBuySideQuoteByDispatchDeliveryOrderIdListError
>('BUYSIDEQUOTE_GETBUYSIDEQUOTEBYDISPATCHDELIVERYORDERIDLIST')

export interface IBuySideQuoteUpdateRequest {
  id: string
  body: BuySideQuoteDTO
  options?: any
}
export type IBuySideQuoteUpdateSuccess = BuySideQuoteDTO
export type IBuySideQuoteUpdateError = any
export const buySideQuoteUpdateAction = businessAsyncActionCreator.async<
  IBuySideQuoteUpdateRequest,
  IBuySideQuoteUpdateSuccess,
  IBuySideQuoteUpdateError
>('BUYSIDEQUOTE_UPDATE')

export interface ICargoDeleteRequest {
  id: string
  options?: any
}
export type ICargoDeleteSuccess = Response
export type ICargoDeleteError = any
export const cargoDeleteAction = businessAsyncActionCreator.async<
  ICargoDeleteRequest,
  ICargoDeleteSuccess,
  ICargoDeleteError
>('CARGO_DELETE')

export interface ICargoCreateRequest {
  body: CargoDTO
  options?: any
}
export type ICargoCreateSuccess = CargoDTO
export type ICargoCreateError = any
export const cargoCreateAction = businessAsyncActionCreator.async<
  ICargoCreateRequest,
  ICargoCreateSuccess,
  ICargoCreateError
>('CARGO_CREATE')

export interface ICargoFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type ICargoFindAllSuccess = CargoDTO[]
export type ICargoFindAllError = any
export const cargoFindAllAction = businessAsyncActionCreator.async<
  ICargoFindAllRequest,
  ICargoFindAllSuccess,
  ICargoFindAllError
>('CARGO_FINDALL')

export interface ICargoFindByIdRequest {
  id: string
  options?: any
}
export type ICargoFindByIdSuccess = CargoDTO
export type ICargoFindByIdError = any
export const cargoFindByIdAction = businessAsyncActionCreator.async<
  ICargoFindByIdRequest,
  ICargoFindByIdSuccess,
  ICargoFindByIdError
>('CARGO_FINDBYID')

export interface ICargoUpdateRequest {
  id: string
  body: CargoDTO
  options?: any
}
export type ICargoUpdateSuccess = CargoDTO
export type ICargoUpdateError = any
export const cargoUpdateAction = businessAsyncActionCreator.async<
  ICargoUpdateRequest,
  ICargoUpdateSuccess,
  ICargoUpdateError
>('CARGO_UPDATE')

export interface IContactDeleteRequest {
  id: string
  options?: any
}
export type IContactDeleteSuccess = Response
export type IContactDeleteError = any
export const contactDeleteAction = businessAsyncActionCreator.async<
  IContactDeleteRequest,
  IContactDeleteSuccess,
  IContactDeleteError
>('CONTACT_DELETE')

export interface IContactCreateRequest {
  body: ContactDTO
  options?: any
}
export type IContactCreateSuccess = ContactDTO
export type IContactCreateError = any
export const contactCreateAction = businessAsyncActionCreator.async<
  IContactCreateRequest,
  IContactCreateSuccess,
  IContactCreateError
>('CONTACT_CREATE')

export interface IContactFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IContactFindAllSuccess = ContactDTO[]
export type IContactFindAllError = any
export const contactFindAllAction = businessAsyncActionCreator.async<
  IContactFindAllRequest,
  IContactFindAllSuccess,
  IContactFindAllError
>('CONTACT_FINDALL')

export interface IContactFindByIdRequest {
  id: string
  options?: any
}
export type IContactFindByIdSuccess = ContactDTO
export type IContactFindByIdError = any
export const contactFindByIdAction = businessAsyncActionCreator.async<
  IContactFindByIdRequest,
  IContactFindByIdSuccess,
  IContactFindByIdError
>('CONTACT_FINDBYID')

export interface IContactUpdateRequest {
  id: string
  body: ContactDTO
  options?: any
}
export type IContactUpdateSuccess = ContactDTO
export type IContactUpdateError = any
export const contactUpdateAction = businessAsyncActionCreator.async<
  IContactUpdateRequest,
  IContactUpdateSuccess,
  IContactUpdateError
>('CONTACT_UPDATE')

export interface IContainerDeleteRequest {
  id: string
  options?: any
}
export type IContainerDeleteSuccess = Response
export type IContainerDeleteError = any
export const containerDeleteAction = businessAsyncActionCreator.async<
  IContainerDeleteRequest,
  IContainerDeleteSuccess,
  IContainerDeleteError
>('CONTAINER_DELETE')

export interface IContainerCreateRequest {
  body: ContainerDTO
  options?: any
}
export type IContainerCreateSuccess = ContainerViewDTO
export type IContainerCreateError = any
export const containerCreateAction = businessAsyncActionCreator.async<
  IContainerCreateRequest,
  IContainerCreateSuccess,
  IContainerCreateError
>('CONTAINER_CREATE')

export interface IContainerFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IContainerFindAllSuccess = ContainerViewDTO[]
export type IContainerFindAllError = any
export const containerFindAllAction = businessAsyncActionCreator.async<
  IContainerFindAllRequest,
  IContainerFindAllSuccess,
  IContainerFindAllError
>('CONTAINER_FINDALL')

export interface IContainerFindByIdRequest {
  id: string
  options?: any
}
export type IContainerFindByIdSuccess = ContainerViewDTO
export type IContainerFindByIdError = any
export const containerFindByIdAction = businessAsyncActionCreator.async<
  IContainerFindByIdRequest,
  IContainerFindByIdSuccess,
  IContainerFindByIdError
>('CONTAINER_FINDBYID')

export interface IContainerSearchRequest {
  containerTypeId?: string
  limit?: number
  steamShipLineId?: string
  term?: string
  options?: any
}
export type IContainerSearchSuccess = ContainerSearchDTO[]
export type IContainerSearchError = any
export const containerSearchAction = businessAsyncActionCreator.async<
  IContainerSearchRequest,
  IContainerSearchSuccess,
  IContainerSearchError
>('CONTAINER_SEARCH')

export interface IContainerUpdateRequest {
  id: string
  body: ContainerDTO
  options?: any
}
export type IContainerUpdateSuccess = ContainerViewDTO
export type IContainerUpdateError = any
export const containerUpdateAction = businessAsyncActionCreator.async<
  IContainerUpdateRequest,
  IContainerUpdateSuccess,
  IContainerUpdateError
>('CONTAINER_UPDATE')

export interface IContainerTypeDeleteRequest {
  id: string
  options?: any
}
export type IContainerTypeDeleteSuccess = Response
export type IContainerTypeDeleteError = any
export const containerTypeDeleteAction = businessAsyncActionCreator.async<
  IContainerTypeDeleteRequest,
  IContainerTypeDeleteSuccess,
  IContainerTypeDeleteError
>('CONTAINERTYPE_DELETE')

export interface IContainerTypeCreateRequest {
  body: ContainerTypeDTO
  options?: any
}
export type IContainerTypeCreateSuccess = ContainerTypeDTO
export type IContainerTypeCreateError = any
export const containerTypeCreateAction = businessAsyncActionCreator.async<
  IContainerTypeCreateRequest,
  IContainerTypeCreateSuccess,
  IContainerTypeCreateError
>('CONTAINERTYPE_CREATE')

export interface IContainerTypeFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IContainerTypeFindAllSuccess = ContainerTypeDTO[]
export type IContainerTypeFindAllError = any
export const containerTypeFindAllAction = businessAsyncActionCreator.async<
  IContainerTypeFindAllRequest,
  IContainerTypeFindAllSuccess,
  IContainerTypeFindAllError
>('CONTAINERTYPE_FINDALL')

export interface IContainerTypeFindByIdRequest {
  id: string
  options?: any
}
export type IContainerTypeFindByIdSuccess = ContainerTypeDTO
export type IContainerTypeFindByIdError = any
export const containerTypeFindByIdAction = businessAsyncActionCreator.async<
  IContainerTypeFindByIdRequest,
  IContainerTypeFindByIdSuccess,
  IContainerTypeFindByIdError
>('CONTAINERTYPE_FINDBYID')

export interface IContainerTypeUpdateRequest {
  id: string
  body: ContainerTypeDTO
  options?: any
}
export type IContainerTypeUpdateSuccess = ContainerTypeDTO
export type IContainerTypeUpdateError = any
export const containerTypeUpdateAction = businessAsyncActionCreator.async<
  IContainerTypeUpdateRequest,
  IContainerTypeUpdateSuccess,
  IContainerTypeUpdateError
>('CONTAINERTYPE_UPDATE')

export interface ICountryFindAllRequest {
  options?: any
}
export type ICountryFindAllSuccess = CountryDTO[]
export type ICountryFindAllError = any
export const countryFindAllAction = businessAsyncActionCreator.async<
  ICountryFindAllRequest,
  ICountryFindAllSuccess,
  ICountryFindAllError
>('COUNTRY_FINDALL')

export interface ICustomerDeleteRequest {
  id: string
  options?: any
}
export type ICustomerDeleteSuccess = Response
export type ICustomerDeleteError = any
export const customerDeleteAction = businessAsyncActionCreator.async<
  ICustomerDeleteRequest,
  ICustomerDeleteSuccess,
  ICustomerDeleteError
>('CUSTOMER_DELETE')

export interface ICustomerCreateRequest {
  body: CustomerDTO
  options?: any
}
export type ICustomerCreateSuccess = CustomerViewDTO
export type ICustomerCreateError = any
export const customerCreateAction = businessAsyncActionCreator.async<
  ICustomerCreateRequest,
  ICustomerCreateSuccess,
  ICustomerCreateError
>('CUSTOMER_CREATE')

export interface ICustomerFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type ICustomerFindAllSuccess = CustomerGridItemDTO[]
export type ICustomerFindAllError = any
export const customerFindAllAction = businessAsyncActionCreator.async<
  ICustomerFindAllRequest,
  ICustomerFindAllSuccess,
  ICustomerFindAllError
>('CUSTOMER_FINDALL')

export interface ICustomerFindByIdRequest {
  id: string
  options?: any
}
export type ICustomerFindByIdSuccess = CustomerViewDTO
export type ICustomerFindByIdError = any
export const customerFindByIdAction = businessAsyncActionCreator.async<
  ICustomerFindByIdRequest,
  ICustomerFindByIdSuccess,
  ICustomerFindByIdError
>('CUSTOMER_FINDBYID')

export interface ICustomerSearchRequest {
  limit?: number
  term?: string
  options?: any
}
export type ICustomerSearchSuccess = CustomerNameDTO[]
export type ICustomerSearchError = any
export const customerSearchAction = businessAsyncActionCreator.async<
  ICustomerSearchRequest,
  ICustomerSearchSuccess,
  ICustomerSearchError
>('CUSTOMER_SEARCH')

export interface ICustomerUpdateRequest {
  id: string
  body: CustomerDTO
  options?: any
}
export type ICustomerUpdateSuccess = CustomerViewDTO
export type ICustomerUpdateError = any
export const customerUpdateAction = businessAsyncActionCreator.async<
  ICustomerUpdateRequest,
  ICustomerUpdateSuccess,
  ICustomerUpdateError
>('CUSTOMER_UPDATE')

export interface IDashboardGetDashboardRequest {
  options?: any
}
export type IDashboardGetDashboardSuccess = DashboardDTO
export type IDashboardGetDashboardError = any
export const dashboardGetDashboardAction = businessAsyncActionCreator.async<
  IDashboardGetDashboardRequest,
  IDashboardGetDashboardSuccess,
  IDashboardGetDashboardError
>('DASHBOARD_GETDASHBOARD')

export interface IDeliveryOrderDeleteRequest {
  id: string
  options?: any
}
export type IDeliveryOrderDeleteSuccess = Response
export type IDeliveryOrderDeleteError = any
export const deliveryOrderDeleteAction = businessAsyncActionCreator.async<
  IDeliveryOrderDeleteRequest,
  IDeliveryOrderDeleteSuccess,
  IDeliveryOrderDeleteError
>('DELIVERYORDER_DELETE')

export interface IDeliveryOrderCheckByReferenceNumberRequest {
  referenceNumber: string
  steamShipLineId?: string
  options?: any
}
export type IDeliveryOrderCheckByReferenceNumberSuccess = DeliveryOrderSearchDTO[]
export type IDeliveryOrderCheckByReferenceNumberError = any
export const deliveryOrderCheckByReferenceNumberAction = businessAsyncActionCreator.async<
  IDeliveryOrderCheckByReferenceNumberRequest,
  IDeliveryOrderCheckByReferenceNumberSuccess,
  IDeliveryOrderCheckByReferenceNumberError
>('DELIVERYORDER_CHECKBYREFERENCENUMBER')

export interface IDeliveryOrderCreateRequest {
  body: DeliveryOrderDTO
  options?: any
}
export type IDeliveryOrderCreateSuccess = DeliveryOrderViewDTO
export type IDeliveryOrderCreateError = any
export const deliveryOrderCreateAction = businessAsyncActionCreator.async<
  IDeliveryOrderCreateRequest,
  IDeliveryOrderCreateSuccess,
  IDeliveryOrderCreateError
>('DELIVERYORDER_CREATE')

export interface IDeliveryOrderFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IDeliveryOrderFindAllSuccess = DeliveryOrderViewDTO[]
export type IDeliveryOrderFindAllError = any
export const deliveryOrderFindAllAction = businessAsyncActionCreator.async<
  IDeliveryOrderFindAllRequest,
  IDeliveryOrderFindAllSuccess,
  IDeliveryOrderFindAllError
>('DELIVERYORDER_FINDALL')

export interface IDeliveryOrderFindByIdRequest {
  id: string
  options?: any
}
export type IDeliveryOrderFindByIdSuccess = DeliveryOrderViewDTO
export type IDeliveryOrderFindByIdError = any
export const deliveryOrderFindByIdAction = businessAsyncActionCreator.async<
  IDeliveryOrderFindByIdRequest,
  IDeliveryOrderFindByIdSuccess,
  IDeliveryOrderFindByIdError
>('DELIVERYORDER_FINDBYID')

export interface IDeliveryOrderNotifyRequest {
  id: string
  options?: any
}
export type IDeliveryOrderNotifySuccess = Response
export type IDeliveryOrderNotifyError = any
export const deliveryOrderNotifyAction = businessAsyncActionCreator.async<
  IDeliveryOrderNotifyRequest,
  IDeliveryOrderNotifySuccess,
  IDeliveryOrderNotifyError
>('DELIVERYORDER_NOTIFY')

export interface IDeliveryOrderUpdateRequest {
  id: string
  body: DeliveryOrderDTO
  options?: any
}
export type IDeliveryOrderUpdateSuccess = DeliveryOrderViewDTO
export type IDeliveryOrderUpdateError = any
export const deliveryOrderUpdateAction = businessAsyncActionCreator.async<
  IDeliveryOrderUpdateRequest,
  IDeliveryOrderUpdateSuccess,
  IDeliveryOrderUpdateError
>('DELIVERYORDER_UPDATE')

export interface IDispatchDeliveryOrderDeleteRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderDeleteSuccess = Response
export type IDispatchDeliveryOrderDeleteError = any
export const dispatchDeliveryOrderDeleteAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderDeleteRequest,
  IDispatchDeliveryOrderDeleteSuccess,
  IDispatchDeliveryOrderDeleteError
>('DISPATCHDELIVERYORDER_DELETE')

export interface IDispatchDeliveryOrderBatchUpdateRequest {
  body: Array<DispatchDeliveryOrderDTO>
  options?: any
}
export type IDispatchDeliveryOrderBatchUpdateSuccess = DispatchDeliveryOrderViewDTO[]
export type IDispatchDeliveryOrderBatchUpdateError = any
export const dispatchDeliveryOrderBatchUpdateAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderBatchUpdateRequest,
  IDispatchDeliveryOrderBatchUpdateSuccess,
  IDispatchDeliveryOrderBatchUpdateError
>('DISPATCHDELIVERYORDER_BATCHUPDATE')

export interface IDispatchDeliveryOrderCancelOrderRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderCancelOrderSuccess = Response
export type IDispatchDeliveryOrderCancelOrderError = any
export const dispatchDeliveryOrderCancelOrderAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderCancelOrderRequest,
  IDispatchDeliveryOrderCancelOrderSuccess,
  IDispatchDeliveryOrderCancelOrderError
>('DISPATCHDELIVERYORDER_CANCELORDER')

export interface IDispatchDeliveryOrderCheckTerminalRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderCheckTerminalSuccess = DispatchDeliveryOrderViewDTO
export type IDispatchDeliveryOrderCheckTerminalError = any
export const dispatchDeliveryOrderCheckTerminalAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderCheckTerminalRequest,
  IDispatchDeliveryOrderCheckTerminalSuccess,
  IDispatchDeliveryOrderCheckTerminalError
>('DISPATCHDELIVERYORDER_CHECKTERMINAL')

export interface IDispatchDeliveryOrderCountAllNonCompletedRequest {
  options?: any
}
export type IDispatchDeliveryOrderCountAllNonCompletedSuccess = number
export type IDispatchDeliveryOrderCountAllNonCompletedError = any
export const dispatchDeliveryOrderCountAllNonCompletedAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderCountAllNonCompletedRequest,
  IDispatchDeliveryOrderCountAllNonCompletedSuccess,
  IDispatchDeliveryOrderCountAllNonCompletedError
>('DISPATCHDELIVERYORDER_COUNTALLNONCOMPLETED')

export interface IDispatchDeliveryOrderCreateRequest {
  body: DispatchDeliveryOrderDTO
  options?: any
}
export type IDispatchDeliveryOrderCreateSuccess = DispatchDeliveryOrderViewDTO
export type IDispatchDeliveryOrderCreateError = any
export const dispatchDeliveryOrderCreateAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderCreateRequest,
  IDispatchDeliveryOrderCreateSuccess,
  IDispatchDeliveryOrderCreateError
>('DISPATCHDELIVERYORDER_CREATE')

export interface IDispatchDeliveryOrderDeleteActivitiesRequest {
  id: string
  body: ActivitiesDTO
  options?: any
}
export type IDispatchDeliveryOrderDeleteActivitiesSuccess = Response
export type IDispatchDeliveryOrderDeleteActivitiesError = any
export const dispatchDeliveryOrderDeleteActivitiesAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderDeleteActivitiesRequest,
  IDispatchDeliveryOrderDeleteActivitiesSuccess,
  IDispatchDeliveryOrderDeleteActivitiesError
>('DISPATCHDELIVERYORDER_DELETEACTIVITIES')

export interface IDispatchDeliveryOrderFindActivitiesRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderFindActivitiesSuccess = ActivitiesViewDTO
export type IDispatchDeliveryOrderFindActivitiesError = any
export const dispatchDeliveryOrderFindActivitiesAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderFindActivitiesRequest,
  IDispatchDeliveryOrderFindActivitiesSuccess,
  IDispatchDeliveryOrderFindActivitiesError
>('DISPATCHDELIVERYORDER_FINDACTIVITIES')

export interface IDispatchDeliveryOrderFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IDispatchDeliveryOrderFindAllSuccess = DispatchDeliveryOrderViewDTO[]
export type IDispatchDeliveryOrderFindAllError = any
export const dispatchDeliveryOrderFindAllAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderFindAllRequest,
  IDispatchDeliveryOrderFindAllSuccess,
  IDispatchDeliveryOrderFindAllError
>('DISPATCHDELIVERYORDER_FINDALL')

export interface IDispatchDeliveryOrderFindAllNonCompletedRequest {
  options?: any
}
export type IDispatchDeliveryOrderFindAllNonCompletedSuccess = DispatchDeliveryOrderNumber[]
export type IDispatchDeliveryOrderFindAllNonCompletedError = any
export const dispatchDeliveryOrderFindAllNonCompletedAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderFindAllNonCompletedRequest,
  IDispatchDeliveryOrderFindAllNonCompletedSuccess,
  IDispatchDeliveryOrderFindAllNonCompletedError
>('DISPATCHDELIVERYORDER_FINDALLNONCOMPLETED')

export interface IDispatchDeliveryOrderFindByIdRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderFindByIdSuccess = DispatchDeliveryOrderViewDTO
export type IDispatchDeliveryOrderFindByIdError = any
export const dispatchDeliveryOrderFindByIdAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderFindByIdRequest,
  IDispatchDeliveryOrderFindByIdSuccess,
  IDispatchDeliveryOrderFindByIdError
>('DISPATCHDELIVERYORDER_FINDBYID')

export interface IDispatchDeliveryOrderFindDeclinedVendorsRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderFindDeclinedVendorsSuccess = VendorNameDTO[]
export type IDispatchDeliveryOrderFindDeclinedVendorsError = any
export const dispatchDeliveryOrderFindDeclinedVendorsAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderFindDeclinedVendorsRequest,
  IDispatchDeliveryOrderFindDeclinedVendorsSuccess,
  IDispatchDeliveryOrderFindDeclinedVendorsError
>('DISPATCHDELIVERYORDER_FINDDECLINEDVENDORS')

export interface IDispatchDeliveryOrderFindVendorsRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderFindVendorsSuccess = VendorNameDTO[]
export type IDispatchDeliveryOrderFindVendorsError = any
export const dispatchDeliveryOrderFindVendorsAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderFindVendorsRequest,
  IDispatchDeliveryOrderFindVendorsSuccess,
  IDispatchDeliveryOrderFindVendorsError
>('DISPATCHDELIVERYORDER_FINDVENDORS')

export interface IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersSuccess = DispatchDeliveryOrderGridItemDTO[]
export type IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersError = any
export const dispatchDeliveryOrderGetGridDispatchDeliveryOrdersAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersRequest,
  IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersSuccess,
  IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersError
>('DISPATCHDELIVERYORDER_GETGRIDDISPATCHDELIVERYORDERS')

export interface IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersWithActivitiesRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersWithActivitiesSuccess = DispatchDeliveryOrderGridItemWithActivitiesDTO[]
export type IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersWithActivitiesError = any
export const dispatchDeliveryOrderGetGridDispatchDeliveryOrdersWithActivitiesAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersWithActivitiesRequest,
  IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersWithActivitiesSuccess,
  IDispatchDeliveryOrderGetGridDispatchDeliveryOrdersWithActivitiesError
>('DISPATCHDELIVERYORDER_GETGRIDDISPATCHDELIVERYORDERSWITHACTIVITIES')

export interface IDispatchDeliveryOrderGetHazmatRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderGetHazmatSuccess = HazmatDTO
export type IDispatchDeliveryOrderGetHazmatError = any
export const dispatchDeliveryOrderGetHazmatAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderGetHazmatRequest,
  IDispatchDeliveryOrderGetHazmatSuccess,
  IDispatchDeliveryOrderGetHazmatError
>('DISPATCHDELIVERYORDER_GETHAZMAT')

export interface IDispatchDeliveryOrderGetMaxDispatchDeliveryOrderNumberRequest {
  options?: any
}
export type IDispatchDeliveryOrderGetMaxDispatchDeliveryOrderNumberSuccess = number
export type IDispatchDeliveryOrderGetMaxDispatchDeliveryOrderNumberError = any
export const dispatchDeliveryOrderGetMaxDispatchDeliveryOrderNumberAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderGetMaxDispatchDeliveryOrderNumberRequest,
  IDispatchDeliveryOrderGetMaxDispatchDeliveryOrderNumberSuccess,
  IDispatchDeliveryOrderGetMaxDispatchDeliveryOrderNumberError
>('DISPATCHDELIVERYORDER_GETMAXDISPATCHDELIVERYORDERNUMBER')

export interface IDispatchDeliveryOrderGetPageableGridDispatchDeliveryOrdersRequest {
  filter?: string
  pageNo?: number
  pageSize?: number
  sort?: string
  options?: any
}
export type IDispatchDeliveryOrderGetPageableGridDispatchDeliveryOrdersSuccess = DispatchDeliveryOrderGridItemDTO[]
export type IDispatchDeliveryOrderGetPageableGridDispatchDeliveryOrdersError = any
export const dispatchDeliveryOrderGetPageableGridDispatchDeliveryOrdersAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderGetPageableGridDispatchDeliveryOrdersRequest,
  IDispatchDeliveryOrderGetPageableGridDispatchDeliveryOrdersSuccess,
  IDispatchDeliveryOrderGetPageableGridDispatchDeliveryOrdersError
>('DISPATCHDELIVERYORDER_GETPAGEABLEGRIDDISPATCHDELIVERYORDERS')

export interface IDispatchDeliveryOrderNotifyRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderNotifySuccess = Response
export type IDispatchDeliveryOrderNotifyError = any
export const dispatchDeliveryOrderNotifyAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderNotifyRequest,
  IDispatchDeliveryOrderNotifySuccess,
  IDispatchDeliveryOrderNotifyError
>('DISPATCHDELIVERYORDER_NOTIFY')

export interface IDispatchDeliveryOrderSearchRequest {
  body: DispatchDeliveryOrderSearchRequestDTO
  options?: any
}
export type IDispatchDeliveryOrderSearchSuccess = DispatchDeliveryOrderSearchDTO[]
export type IDispatchDeliveryOrderSearchError = any
export const dispatchDeliveryOrderSearchAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderSearchRequest,
  IDispatchDeliveryOrderSearchSuccess,
  IDispatchDeliveryOrderSearchError
>('DISPATCHDELIVERYORDER_SEARCH')

export interface IDispatchDeliveryOrderSearchByNumberRequest {
  term: string
  limit?: number
  options?: any
}
export type IDispatchDeliveryOrderSearchByNumberSuccess = DispatchDeliveryOrderNumber[]
export type IDispatchDeliveryOrderSearchByNumberError = any
export const dispatchDeliveryOrderSearchByNumberAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderSearchByNumberRequest,
  IDispatchDeliveryOrderSearchByNumberSuccess,
  IDispatchDeliveryOrderSearchByNumberError
>('DISPATCHDELIVERYORDER_SEARCHBYNUMBER')

export interface IDispatchDeliveryOrderUpdateRequest {
  id: string
  body: DispatchDeliveryOrderDTO
  options?: any
}
export type IDispatchDeliveryOrderUpdateSuccess = DispatchDeliveryOrderViewDTO
export type IDispatchDeliveryOrderUpdateError = any
export const dispatchDeliveryOrderUpdateAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderUpdateRequest,
  IDispatchDeliveryOrderUpdateSuccess,
  IDispatchDeliveryOrderUpdateError
>('DISPATCHDELIVERYORDER_UPDATE')

export interface IDispatchDeliveryOrderUpdateActivitiesRequest {
  id: string
  body: ActivitiesDTO
  options?: any
}
export type IDispatchDeliveryOrderUpdateActivitiesSuccess = ActivitiesViewDTO
export type IDispatchDeliveryOrderUpdateActivitiesError = any
export const dispatchDeliveryOrderUpdateActivitiesAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderUpdateActivitiesRequest,
  IDispatchDeliveryOrderUpdateActivitiesSuccess,
  IDispatchDeliveryOrderUpdateActivitiesError
>('DISPATCHDELIVERYORDER_UPDATEACTIVITIES')

export interface IDispatchDeliveryOrderStreetTurnApproveRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderStreetTurnApproveSuccess = DispatchDeliveryOrderStreetTurnDTO
export type IDispatchDeliveryOrderStreetTurnApproveError = any
export const dispatchDeliveryOrderStreetTurnApproveAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderStreetTurnApproveRequest,
  IDispatchDeliveryOrderStreetTurnApproveSuccess,
  IDispatchDeliveryOrderStreetTurnApproveError
>('DISPATCHDELIVERYORDERSTREETTURN_APPROVE')

export interface IDispatchDeliveryOrderStreetTurnCancelRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderStreetTurnCancelSuccess = DispatchDeliveryOrderStreetTurnDTO
export type IDispatchDeliveryOrderStreetTurnCancelError = any
export const dispatchDeliveryOrderStreetTurnCancelAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderStreetTurnCancelRequest,
  IDispatchDeliveryOrderStreetTurnCancelSuccess,
  IDispatchDeliveryOrderStreetTurnCancelError
>('DISPATCHDELIVERYORDERSTREETTURN_CANCEL')

export interface IDispatchDeliveryOrderStreetTurnFindAllRequest {
  ddoId: string
  options?: any
}
export type IDispatchDeliveryOrderStreetTurnFindAllSuccess = DispatchDeliveryOrderGridItemDTO[]
export type IDispatchDeliveryOrderStreetTurnFindAllError = any
export const dispatchDeliveryOrderStreetTurnFindAllAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderStreetTurnFindAllRequest,
  IDispatchDeliveryOrderStreetTurnFindAllSuccess,
  IDispatchDeliveryOrderStreetTurnFindAllError
>('DISPATCHDELIVERYORDERSTREETTURN_FINDALL')

export interface IDispatchDeliveryOrderStreetTurnRejectRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderStreetTurnRejectSuccess = DispatchDeliveryOrderStreetTurnDTO
export type IDispatchDeliveryOrderStreetTurnRejectError = any
export const dispatchDeliveryOrderStreetTurnRejectAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderStreetTurnRejectRequest,
  IDispatchDeliveryOrderStreetTurnRejectSuccess,
  IDispatchDeliveryOrderStreetTurnRejectError
>('DISPATCHDELIVERYORDERSTREETTURN_REJECT')

export interface IDispatchDeliveryOrderStreetTurnSubmitRequest {
  id: string
  options?: any
}
export type IDispatchDeliveryOrderStreetTurnSubmitSuccess = DispatchDeliveryOrderStreetTurnDTO
export type IDispatchDeliveryOrderStreetTurnSubmitError = any
export const dispatchDeliveryOrderStreetTurnSubmitAction = businessAsyncActionCreator.async<
  IDispatchDeliveryOrderStreetTurnSubmitRequest,
  IDispatchDeliveryOrderStreetTurnSubmitSuccess,
  IDispatchDeliveryOrderStreetTurnSubmitError
>('DISPATCHDELIVERYORDERSTREETTURN_SUBMIT')

export interface IDocumentationActivityDeleteRequest {
  id: string
  options?: any
}
export type IDocumentationActivityDeleteSuccess = Response
export type IDocumentationActivityDeleteError = any
export const documentationActivityDeleteAction = businessAsyncActionCreator.async<
  IDocumentationActivityDeleteRequest,
  IDocumentationActivityDeleteSuccess,
  IDocumentationActivityDeleteError
>('DOCUMENTATIONACTIVITY_DELETE')

export interface IDocumentationActivityFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IDocumentationActivityFindAllSuccess = DocumentationActivityDTO[]
export type IDocumentationActivityFindAllError = any
export const documentationActivityFindAllAction = businessAsyncActionCreator.async<
  IDocumentationActivityFindAllRequest,
  IDocumentationActivityFindAllSuccess,
  IDocumentationActivityFindAllError
>('DOCUMENTATIONACTIVITY_FINDALL')

export interface IDocumentationActivityFindByIdRequest {
  id: string
  options?: any
}
export type IDocumentationActivityFindByIdSuccess = DocumentationActivityDTO
export type IDocumentationActivityFindByIdError = any
export const documentationActivityFindByIdAction = businessAsyncActionCreator.async<
  IDocumentationActivityFindByIdRequest,
  IDocumentationActivityFindByIdSuccess,
  IDocumentationActivityFindByIdError
>('DOCUMENTATIONACTIVITY_FINDBYID')

export interface IDriverDeleteRequest {
  id: string
  options?: any
}
export type IDriverDeleteSuccess = Response
export type IDriverDeleteError = any
export const driverDeleteAction = businessAsyncActionCreator.async<
  IDriverDeleteRequest,
  IDriverDeleteSuccess,
  IDriverDeleteError
>('DRIVER_DELETE')

export interface IDriverAvailabilityRequest {
  options?: any
}
export type IDriverAvailabilitySuccess = DriverAvailabilityDTO
export type IDriverAvailabilityError = any
export const driverAvailabilityAction = businessAsyncActionCreator.async<
  IDriverAvailabilityRequest,
  IDriverAvailabilitySuccess,
  IDriverAvailabilityError
>('DRIVER_AVAILABILITY')

export interface IDriverCheckTaxIdRequest {
  taxId: string
  options?: any
}
export type IDriverCheckTaxIdSuccess = Response
export type IDriverCheckTaxIdError = any
export const driverCheckTaxIdAction = businessAsyncActionCreator.async<
  IDriverCheckTaxIdRequest,
  IDriverCheckTaxIdSuccess,
  IDriverCheckTaxIdError
>('DRIVER_CHECKTAXID')

export interface IDriverCreateRequest {
  body: DriverDTO
  options?: any
}
export type IDriverCreateSuccess = DriverViewDTO
export type IDriverCreateError = any
export const driverCreateAction = businessAsyncActionCreator.async<
  IDriverCreateRequest,
  IDriverCreateSuccess,
  IDriverCreateError
>('DRIVER_CREATE')

export interface IDriverFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IDriverFindAllSuccess = DriverGridItemDTO[]
export type IDriverFindAllError = any
export const driverFindAllAction = businessAsyncActionCreator.async<
  IDriverFindAllRequest,
  IDriverFindAllSuccess,
  IDriverFindAllError
>('DRIVER_FINDALL')

export interface IDriverFindAllSimpleRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IDriverFindAllSimpleSuccess = DriverSimpleDTO[]
export type IDriverFindAllSimpleError = any
export const driverFindAllSimpleAction = businessAsyncActionCreator.async<
  IDriverFindAllSimpleRequest,
  IDriverFindAllSimpleSuccess,
  IDriverFindAllSimpleError
>('DRIVER_FINDALLSIMPLE')

export interface IDriverFindByDriverStatusRequest {
  statuses: string
  options?: any
}
export type IDriverFindByDriverStatusSuccess = DriverStatusDTO[]
export type IDriverFindByDriverStatusError = any
export const driverFindByDriverStatusAction = businessAsyncActionCreator.async<
  IDriverFindByDriverStatusRequest,
  IDriverFindByDriverStatusSuccess,
  IDriverFindByDriverStatusError
>('DRIVER_FINDBYDRIVERSTATUS')

export interface IDriverFindByIdRequest {
  id: string
  options?: any
}
export type IDriverFindByIdSuccess = DriverViewDTO
export type IDriverFindByIdError = any
export const driverFindByIdAction = businessAsyncActionCreator.async<
  IDriverFindByIdRequest,
  IDriverFindByIdSuccess,
  IDriverFindByIdError
>('DRIVER_FINDBYID')

export interface IDriverGetEntitiesRequest {
  id: string
  options?: any
}
export type IDriverGetEntitiesSuccess = DriverEntitiesDTO
export type IDriverGetEntitiesError = any
export const driverGetEntitiesAction = businessAsyncActionCreator.async<
  IDriverGetEntitiesRequest,
  IDriverGetEntitiesSuccess,
  IDriverGetEntitiesError
>('DRIVER_GETENTITIES')

export interface IDriverOffDutyRequest {
  options?: any
}
export type IDriverOffDutySuccess = DriverAvailabilityDTO
export type IDriverOffDutyError = any
export const driverOffDutyAction = businessAsyncActionCreator.async<
  IDriverOffDutyRequest,
  IDriverOffDutySuccess,
  IDriverOffDutyError
>('DRIVER_OFFDUTY')

export interface IDriverOnDutyRequest {
  options?: any
}
export type IDriverOnDutySuccess = DriverAvailabilityDTO
export type IDriverOnDutyError = any
export const driverOnDutyAction = businessAsyncActionCreator.async<
  IDriverOnDutyRequest,
  IDriverOnDutySuccess,
  IDriverOnDutyError
>('DRIVER_ONDUTY')

export interface IDriverSearchRequest {
  limit?: number
  term?: string
  options?: any
}
export type IDriverSearchSuccess = DriverNameDTO[]
export type IDriverSearchError = any
export const driverSearchAction = businessAsyncActionCreator.async<
  IDriverSearchRequest,
  IDriverSearchSuccess,
  IDriverSearchError
>('DRIVER_SEARCH')

export interface IDriverUpdateRequest {
  id: string
  body: DriverDTO
  options?: any
}
export type IDriverUpdateSuccess = DriverViewDTO
export type IDriverUpdateError = any
export const driverUpdateAction = businessAsyncActionCreator.async<
  IDriverUpdateRequest,
  IDriverUpdateSuccess,
  IDriverUpdateError
>('DRIVER_UPDATE')

export interface IDriverUpdateAuthIdRequest {
  id: string
  body: DriverDTO
  options?: any
}
export type IDriverUpdateAuthIdSuccess = DriverDTO
export type IDriverUpdateAuthIdError = any
export const driverUpdateAuthIdAction = businessAsyncActionCreator.async<
  IDriverUpdateAuthIdRequest,
  IDriverUpdateAuthIdSuccess,
  IDriverUpdateAuthIdError
>('DRIVER_UPDATEAUTHID')

export interface IDriverWsTestRequest {
  options?: any
}
export type IDriverWsTestSuccess = Response
export type IDriverWsTestError = any
export const driverWsTestAction = businessAsyncActionCreator.async<
  IDriverWsTestRequest,
  IDriverWsTestSuccess,
  IDriverWsTestError
>('DRIVER_WSTEST')

export interface IEquipmentDeleteRequest {
  id: string
  options?: any
}
export type IEquipmentDeleteSuccess = Response
export type IEquipmentDeleteError = any
export const equipmentDeleteAction = businessAsyncActionCreator.async<
  IEquipmentDeleteRequest,
  IEquipmentDeleteSuccess,
  IEquipmentDeleteError
>('EQUIPMENT_DELETE')

export interface IEquipmentCreateRequest {
  body: EquipmentDTO
  options?: any
}
export type IEquipmentCreateSuccess = EquipmentDTO
export type IEquipmentCreateError = any
export const equipmentCreateAction = businessAsyncActionCreator.async<
  IEquipmentCreateRequest,
  IEquipmentCreateSuccess,
  IEquipmentCreateError
>('EQUIPMENT_CREATE')

export interface IEquipmentFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IEquipmentFindAllSuccess = EquipmentDTO[]
export type IEquipmentFindAllError = any
export const equipmentFindAllAction = businessAsyncActionCreator.async<
  IEquipmentFindAllRequest,
  IEquipmentFindAllSuccess,
  IEquipmentFindAllError
>('EQUIPMENT_FINDALL')

export interface IEquipmentFindByIdRequest {
  id: string
  options?: any
}
export type IEquipmentFindByIdSuccess = EquipmentDTO
export type IEquipmentFindByIdError = any
export const equipmentFindByIdAction = businessAsyncActionCreator.async<
  IEquipmentFindByIdRequest,
  IEquipmentFindByIdSuccess,
  IEquipmentFindByIdError
>('EQUIPMENT_FINDBYID')

export interface IEquipmentSearchRequest {
  limit?: number
  term?: string
  options?: any
}
export type IEquipmentSearchSuccess = EquipmentSearchDTO[]
export type IEquipmentSearchError = any
export const equipmentSearchAction = businessAsyncActionCreator.async<
  IEquipmentSearchRequest,
  IEquipmentSearchSuccess,
  IEquipmentSearchError
>('EQUIPMENT_SEARCH')

export interface IEquipmentUpdateRequest {
  id: string
  body: EquipmentDTO
  options?: any
}
export type IEquipmentUpdateSuccess = EquipmentDTO
export type IEquipmentUpdateError = any
export const equipmentUpdateAction = businessAsyncActionCreator.async<
  IEquipmentUpdateRequest,
  IEquipmentUpdateSuccess,
  IEquipmentUpdateError
>('EQUIPMENT_UPDATE')

export interface IFileResetVendorFilesRequest {
  type:
    | 'VENDOR_LICENSE'
    | 'VENDOR_LICENSE_BACK'
    | 'VENDOR_MEDICAL_EXAM'
    | 'VENDOR_TWIC'
    | 'VENDOR_PORT'
    | 'VENDOR_W4'
    | 'VENDOR_W9'
    | 'VENDOR_I9'
    | 'VENDOR_APPLICATION'
    | 'VENDOR_LEASE'
    | 'VENDOR_PROOF_EIN_TAX_ID'
    | 'MACHINERY_REGISTRATION'
    | 'MACHINERY_DOT_SAFETY_INSPECTION'
    | 'MACHINERY_INSURANCE_COVERAGE'
  body: Array<string>
  options?: any
}
export type IFileResetVendorFilesSuccess = Response
export type IFileResetVendorFilesError = any
export const fileResetVendorFilesAction = businessAsyncActionCreator.async<
  IFileResetVendorFilesRequest,
  IFileResetVendorFilesSuccess,
  IFileResetVendorFilesError
>('FILE_RESETVENDORFILES')

export interface IFuelPriceGetFuelPriceRequest {
  options?: any
}
export type IFuelPriceGetFuelPriceSuccess = FuelPrice
export type IFuelPriceGetFuelPriceError = any
export const fuelPriceGetFuelPriceAction = businessAsyncActionCreator.async<
  IFuelPriceGetFuelPriceRequest,
  IFuelPriceGetFuelPriceSuccess,
  IFuelPriceGetFuelPriceError
>('FUELPRICE_GETFUELPRICE')

export interface IHazmatFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IHazmatFindAllSuccess = HazmatDTO[]
export type IHazmatFindAllError = any
export const hazmatFindAllAction = businessAsyncActionCreator.async<
  IHazmatFindAllRequest,
  IHazmatFindAllSuccess,
  IHazmatFindAllError
>('HAZMAT_FINDALL')

export interface IHazmatFindByIdRequest {
  id: string
  options?: any
}
export type IHazmatFindByIdSuccess = HazmatDTO
export type IHazmatFindByIdError = any
export const hazmatFindByIdAction = businessAsyncActionCreator.async<
  IHazmatFindByIdRequest,
  IHazmatFindByIdSuccess,
  IHazmatFindByIdError
>('HAZMAT_FINDBYID')

export interface IHazmatSearchRequest {
  limit?: number
  term?: string
  options?: any
}
export type IHazmatSearchSuccess = HazmatDTO[]
export type IHazmatSearchError = any
export const hazmatSearchAction = businessAsyncActionCreator.async<
  IHazmatSearchRequest,
  IHazmatSearchSuccess,
  IHazmatSearchError
>('HAZMAT_SEARCH')

export interface IIndexGetRequest {
  options?: any
}
export type IIndexGetSuccess = Response
export type IIndexGetError = any
export const indexGetAction = businessAsyncActionCreator.async<IIndexGetRequest, IIndexGetSuccess, IIndexGetError>(
  'INDEX_GET'
)

export interface IJobOrderGetTrackingDataByCargoReferenceNumberRequest {
  cargoReferenceNumber: string
  options?: any
}
export type IJobOrderGetTrackingDataByCargoReferenceNumberSuccess = JobOrderTrackingDataDTO
export type IJobOrderGetTrackingDataByCargoReferenceNumberError = any
export const jobOrderGetTrackingDataByCargoReferenceNumberAction = businessAsyncActionCreator.async<
  IJobOrderGetTrackingDataByCargoReferenceNumberRequest,
  IJobOrderGetTrackingDataByCargoReferenceNumberSuccess,
  IJobOrderGetTrackingDataByCargoReferenceNumberError
>('JOBORDER_GETTRACKINGDATABYCARGOREFERENCENUMBER')

export interface ILocationDeleteRequest {
  id: string
  options?: any
}
export type ILocationDeleteSuccess = Response
export type ILocationDeleteError = any
export const locationDeleteAction = businessAsyncActionCreator.async<
  ILocationDeleteRequest,
  ILocationDeleteSuccess,
  ILocationDeleteError
>('LOCATION_DELETE')

export interface ILocationCreateRequest {
  body: LocationDTO
  options?: any
}
export type ILocationCreateSuccess = LocationViewDTO
export type ILocationCreateError = any
export const locationCreateAction = businessAsyncActionCreator.async<
  ILocationCreateRequest,
  ILocationCreateSuccess,
  ILocationCreateError
>('LOCATION_CREATE')

export interface ILocationFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type ILocationFindAllSuccess = LocationViewDTO[]
export type ILocationFindAllError = any
export const locationFindAllAction = businessAsyncActionCreator.async<
  ILocationFindAllRequest,
  ILocationFindAllSuccess,
  ILocationFindAllError
>('LOCATION_FINDALL')

export interface ILocationFindByIdRequest {
  id: string
  options?: any
}
export type ILocationFindByIdSuccess = LocationViewDTO
export type ILocationFindByIdError = any
export const locationFindByIdAction = businessAsyncActionCreator.async<
  ILocationFindByIdRequest,
  ILocationFindByIdSuccess,
  ILocationFindByIdError
>('LOCATION_FINDBYID')

export interface ILocationGetGridLocationsRequest {
  filter?: string
  sort?: string
  options?: any
}
export type ILocationGetGridLocationsSuccess = LocationGridItemDTO[]
export type ILocationGetGridLocationsError = any
export const locationGetGridLocationsAction = businessAsyncActionCreator.async<
  ILocationGetGridLocationsRequest,
  ILocationGetGridLocationsSuccess,
  ILocationGetGridLocationsError
>('LOCATION_GETGRIDLOCATIONS')

export interface ILocationSearchRequest {
  limit?: number
  term?: string
  options?: any
}
export type ILocationSearchSuccess = LocationReducedDTO[]
export type ILocationSearchError = any
export const locationSearchAction = businessAsyncActionCreator.async<
  ILocationSearchRequest,
  ILocationSearchSuccess,
  ILocationSearchError
>('LOCATION_SEARCH')

export interface ILocationUpdateRequest {
  id: string
  body: LocationDTO
  options?: any
}
export type ILocationUpdateSuccess = LocationViewDTO
export type ILocationUpdateError = any
export const locationUpdateAction = businessAsyncActionCreator.async<
  ILocationUpdateRequest,
  ILocationUpdateSuccess,
  ILocationUpdateError
>('LOCATION_UPDATE')

export interface IMoveApiAssignBobtailRequest {
  fromId: string
  toId: string
  options?: any
}
export type IMoveApiAssignBobtailSuccess = Response
export type IMoveApiAssignBobtailError = any
export const moveApiAssignBobtailAction = businessAsyncActionCreator.async<
  IMoveApiAssignBobtailRequest,
  IMoveApiAssignBobtailSuccess,
  IMoveApiAssignBobtailError
>('MOVEAPI_ASSIGNBOBTAIL')

export interface IMoveApiCheckIsLastDdoActivityRequest {
  id: string
  options?: any
}
export type IMoveApiCheckIsLastDdoActivitySuccess = BooleanDTO
export type IMoveApiCheckIsLastDdoActivityError = any
export const moveApiCheckIsLastDdoActivityAction = businessAsyncActionCreator.async<
  IMoveApiCheckIsLastDdoActivityRequest,
  IMoveApiCheckIsLastDdoActivitySuccess,
  IMoveApiCheckIsLastDdoActivityError
>('MOVEAPI_CHECKISLASTDDOACTIVITY')

export interface IMoveApiCheckVendorAccessRequest {
  authUserId: string
  id: string
  options?: any
}
export type IMoveApiCheckVendorAccessSuccess = BooleanDTO
export type IMoveApiCheckVendorAccessError = any
export const moveApiCheckVendorAccessAction = businessAsyncActionCreator.async<
  IMoveApiCheckVendorAccessRequest,
  IMoveApiCheckVendorAccessSuccess,
  IMoveApiCheckVendorAccessError
>('MOVEAPI_CHECKVENDORACCESS')

export interface IMoveApiCompleteActivityRequest {
  date: Date
  id: string
  userName: string
  options?: any
}
export type IMoveApiCompleteActivitySuccess = MoveItemActivityDetailsDTO
export type IMoveApiCompleteActivityError = any
export const moveApiCompleteActivityAction = businessAsyncActionCreator.async<
  IMoveApiCompleteActivityRequest,
  IMoveApiCompleteActivitySuccess,
  IMoveApiCompleteActivityError
>('MOVEAPI_COMPLETEACTIVITY')

export interface IMoveApiCopyEquipmentRequest {
  fromId: string
  toId: string
  options?: any
}
export type IMoveApiCopyEquipmentSuccess = Response
export type IMoveApiCopyEquipmentError = any
export const moveApiCopyEquipmentAction = businessAsyncActionCreator.async<
  IMoveApiCopyEquipmentRequest,
  IMoveApiCopyEquipmentSuccess,
  IMoveApiCopyEquipmentError
>('MOVEAPI_COPYEQUIPMENT')

export interface IMoveApiGetActivityRequest {
  id: string
  options?: any
}
export type IMoveApiGetActivitySuccess = MoveItemActivityDetailsDTO
export type IMoveApiGetActivityError = any
export const moveApiGetActivityAction = businessAsyncActionCreator.async<
  IMoveApiGetActivityRequest,
  IMoveApiGetActivitySuccess,
  IMoveApiGetActivityError
>('MOVEAPI_GETACTIVITY')

export interface IMoveApiGetActivityIsCompletedByNumberRequest {
  activityNumber: number
  ddoId: string
  options?: any
}
export type IMoveApiGetActivityIsCompletedByNumberSuccess = BooleanDTO
export type IMoveApiGetActivityIsCompletedByNumberError = any
export const moveApiGetActivityIsCompletedByNumberAction = businessAsyncActionCreator.async<
  IMoveApiGetActivityIsCompletedByNumberRequest,
  IMoveApiGetActivityIsCompletedByNumberSuccess,
  IMoveApiGetActivityIsCompletedByNumberError
>('MOVEAPI_GETACTIVITYISCOMPLETEDBYNUMBER')

export interface IMoveApiGetActivityIsTransportationByNumberRequest {
  activityNumber: number
  ddoId: string
  options?: any
}
export type IMoveApiGetActivityIsTransportationByNumberSuccess = BooleanDTO
export type IMoveApiGetActivityIsTransportationByNumberError = any
export const moveApiGetActivityIsTransportationByNumberAction = businessAsyncActionCreator.async<
  IMoveApiGetActivityIsTransportationByNumberRequest,
  IMoveApiGetActivityIsTransportationByNumberSuccess,
  IMoveApiGetActivityIsTransportationByNumberError
>('MOVEAPI_GETACTIVITYISTRANSPORTATIONBYNUMBER')

export interface IMoveApiGetActivityListRequest {
  body: Array<string>
  options?: any
}
export type IMoveApiGetActivityListSuccess = MoveItemActivityDTO[]
export type IMoveApiGetActivityListError = any
export const moveApiGetActivityListAction = businessAsyncActionCreator.async<
  IMoveApiGetActivityListRequest,
  IMoveApiGetActivityListSuccess,
  IMoveApiGetActivityListError
>('MOVEAPI_GETACTIVITYLIST')

export interface IMoveApiGetActivityTroubleTicketListRequest {
  body: Array<string>
  options?: any
}
export type IMoveApiGetActivityTroubleTicketListSuccess = TroubleTicketDTO[]
export type IMoveApiGetActivityTroubleTicketListError = any
export const moveApiGetActivityTroubleTicketListAction = businessAsyncActionCreator.async<
  IMoveApiGetActivityTroubleTicketListRequest,
  IMoveApiGetActivityTroubleTicketListSuccess,
  IMoveApiGetActivityTroubleTicketListError
>('MOVEAPI_GETACTIVITYTROUBLETICKETLIST')

export interface IMoveApiGetDdoStreetTurnLinkedDdoIdRequest {
  ddoId: string
  deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION'
  options?: any
}
export type IMoveApiGetDdoStreetTurnLinkedDdoIdSuccess = string
export type IMoveApiGetDdoStreetTurnLinkedDdoIdError = any
export const moveApiGetDdoStreetTurnLinkedDdoIdAction = businessAsyncActionCreator.async<
  IMoveApiGetDdoStreetTurnLinkedDdoIdRequest,
  IMoveApiGetDdoStreetTurnLinkedDdoIdSuccess,
  IMoveApiGetDdoStreetTurnLinkedDdoIdError
>('MOVEAPI_GETDDOSTREETTURNLINKEDDDOID')

export interface IMoveApiGetDispatchDeliveryOrderRequest {
  id: string
  options?: any
}
export type IMoveApiGetDispatchDeliveryOrderSuccess = MoveDispatchDeliveryOrderDetailsDTO
export type IMoveApiGetDispatchDeliveryOrderError = any
export const moveApiGetDispatchDeliveryOrderAction = businessAsyncActionCreator.async<
  IMoveApiGetDispatchDeliveryOrderRequest,
  IMoveApiGetDispatchDeliveryOrderSuccess,
  IMoveApiGetDispatchDeliveryOrderError
>('MOVEAPI_GETDISPATCHDELIVERYORDER')

export interface IMoveApiGetDispatchDeliveryOrderEquipmentListRequest {
  body: Array<string>
  options?: any
}
export type IMoveApiGetDispatchDeliveryOrderEquipmentListSuccess = MoveDispatchDeliveryOrderEquipmentMapDTO[]
export type IMoveApiGetDispatchDeliveryOrderEquipmentListError = any
export const moveApiGetDispatchDeliveryOrderEquipmentListAction = businessAsyncActionCreator.async<
  IMoveApiGetDispatchDeliveryOrderEquipmentListRequest,
  IMoveApiGetDispatchDeliveryOrderEquipmentListSuccess,
  IMoveApiGetDispatchDeliveryOrderEquipmentListError
>('MOVEAPI_GETDISPATCHDELIVERYORDEREQUIPMENTLIST')

export interface IMoveApiGetDispatchDeliveryOrderIdListRequest {
  body: Array<Filter>
  options?: any
}
export type IMoveApiGetDispatchDeliveryOrderIdListSuccess = string[]
export type IMoveApiGetDispatchDeliveryOrderIdListError = any
export const moveApiGetDispatchDeliveryOrderIdListAction = businessAsyncActionCreator.async<
  IMoveApiGetDispatchDeliveryOrderIdListRequest,
  IMoveApiGetDispatchDeliveryOrderIdListSuccess,
  IMoveApiGetDispatchDeliveryOrderIdListError
>('MOVEAPI_GETDISPATCHDELIVERYORDERIDLIST')

export interface IMoveApiGetDispatchDeliveryOrderListRequest {
  body: Array<string>
  options?: any
}
export type IMoveApiGetDispatchDeliveryOrderListSuccess = MoveDispatchDeliveryOrderDTO[]
export type IMoveApiGetDispatchDeliveryOrderListError = any
export const moveApiGetDispatchDeliveryOrderListAction = businessAsyncActionCreator.async<
  IMoveApiGetDispatchDeliveryOrderListRequest,
  IMoveApiGetDispatchDeliveryOrderListSuccess,
  IMoveApiGetDispatchDeliveryOrderListError
>('MOVEAPI_GETDISPATCHDELIVERYORDERLIST')

export interface IMoveApiGetDispatchDeliveryOrderNumberRequest {
  id: string
  options?: any
}
export type IMoveApiGetDispatchDeliveryOrderNumberSuccess = number
export type IMoveApiGetDispatchDeliveryOrderNumberError = any
export const moveApiGetDispatchDeliveryOrderNumberAction = businessAsyncActionCreator.async<
  IMoveApiGetDispatchDeliveryOrderNumberRequest,
  IMoveApiGetDispatchDeliveryOrderNumberSuccess,
  IMoveApiGetDispatchDeliveryOrderNumberError
>('MOVEAPI_GETDISPATCHDELIVERYORDERNUMBER')

export interface IMoveApiGetDispatchDeliveryOrderStatusRequest {
  id: string
  options?: any
}
export type IMoveApiGetDispatchDeliveryOrderStatusSuccess = string
export type IMoveApiGetDispatchDeliveryOrderStatusError = any
export const moveApiGetDispatchDeliveryOrderStatusAction = businessAsyncActionCreator.async<
  IMoveApiGetDispatchDeliveryOrderStatusRequest,
  IMoveApiGetDispatchDeliveryOrderStatusSuccess,
  IMoveApiGetDispatchDeliveryOrderStatusError
>('MOVEAPI_GETDISPATCHDELIVERYORDERSTATUS')

export interface IMoveApiGetFullActivityListRequest {
  body: Array<string>
  options?: any
}
export type IMoveApiGetFullActivityListSuccess = MoveItemActivityDetailsDTO[]
export type IMoveApiGetFullActivityListError = any
export const moveApiGetFullActivityListAction = businessAsyncActionCreator.async<
  IMoveApiGetFullActivityListRequest,
  IMoveApiGetFullActivityListSuccess,
  IMoveApiGetFullActivityListError
>('MOVEAPI_GETFULLACTIVITYLIST')

export interface IMoveApiGetMovesBuildDataRequest {
  ddoId: string
  options?: any
}
export type IMoveApiGetMovesBuildDataSuccess = MoveBuildDTO
export type IMoveApiGetMovesBuildDataError = any
export const moveApiGetMovesBuildDataAction = businessAsyncActionCreator.async<
  IMoveApiGetMovesBuildDataRequest,
  IMoveApiGetMovesBuildDataSuccess,
  IMoveApiGetMovesBuildDataError
>('MOVEAPI_GETMOVESBUILDDATA')

export interface IMoveApiGetMovesBuildDocumentationActivitiesByDdoRequest {
  ddoId: string
  options?: any
}
export type IMoveApiGetMovesBuildDocumentationActivitiesByDdoSuccess = MoveBuildDocumentationActivityDTO[]
export type IMoveApiGetMovesBuildDocumentationActivitiesByDdoError = any
export const moveApiGetMovesBuildDocumentationActivitiesByDdoAction = businessAsyncActionCreator.async<
  IMoveApiGetMovesBuildDocumentationActivitiesByDdoRequest,
  IMoveApiGetMovesBuildDocumentationActivitiesByDdoSuccess,
  IMoveApiGetMovesBuildDocumentationActivitiesByDdoError
>('MOVEAPI_GETMOVESBUILDDOCUMENTATIONACTIVITIESBYDDO')

export interface IMoveApiGetMovesBuildTransportationActivitiesRequest {
  body: Array<string>
  options?: any
}
export type IMoveApiGetMovesBuildTransportationActivitiesSuccess = MoveBuildTransportationActivityDTO[]
export type IMoveApiGetMovesBuildTransportationActivitiesError = any
export const moveApiGetMovesBuildTransportationActivitiesAction = businessAsyncActionCreator.async<
  IMoveApiGetMovesBuildTransportationActivitiesRequest,
  IMoveApiGetMovesBuildTransportationActivitiesSuccess,
  IMoveApiGetMovesBuildTransportationActivitiesError
>('MOVEAPI_GETMOVESBUILDTRANSPORTATIONACTIVITIES')

export interface IMoveApiGetTroubleTicketDocumentsRequest {
  id: string
  options?: any
}
export type IMoveApiGetTroubleTicketDocumentsSuccess = TroubleTicketDTO[]
export type IMoveApiGetTroubleTicketDocumentsError = any
export const moveApiGetTroubleTicketDocumentsAction = businessAsyncActionCreator.async<
  IMoveApiGetTroubleTicketDocumentsRequest,
  IMoveApiGetTroubleTicketDocumentsSuccess,
  IMoveApiGetTroubleTicketDocumentsError
>('MOVEAPI_GETTROUBLETICKETDOCUMENTS')

export interface IMoveApiGetVendorUserIdRequest {
  id: string
  options?: any
}
export type IMoveApiGetVendorUserIdSuccess = string
export type IMoveApiGetVendorUserIdError = any
export const moveApiGetVendorUserIdAction = businessAsyncActionCreator.async<
  IMoveApiGetVendorUserIdRequest,
  IMoveApiGetVendorUserIdSuccess,
  IMoveApiGetVendorUserIdError
>('MOVEAPI_GETVENDORUSERID')

export interface IMoveApiRollbackBobtailByActivitiesFromRequest {
  body: Array<string>
  options?: any
}
export type IMoveApiRollbackBobtailByActivitiesFromSuccess = Response
export type IMoveApiRollbackBobtailByActivitiesFromError = any
export const moveApiRollbackBobtailByActivitiesFromAction = businessAsyncActionCreator.async<
  IMoveApiRollbackBobtailByActivitiesFromRequest,
  IMoveApiRollbackBobtailByActivitiesFromSuccess,
  IMoveApiRollbackBobtailByActivitiesFromError
>('MOVEAPI_ROLLBACKBOBTAILBYACTIVITIESFROM')

export interface IMoveApiRollbackBobtailByActivitiesToRequest {
  body: Array<string>
  options?: any
}
export type IMoveApiRollbackBobtailByActivitiesToSuccess = Response
export type IMoveApiRollbackBobtailByActivitiesToError = any
export const moveApiRollbackBobtailByActivitiesToAction = businessAsyncActionCreator.async<
  IMoveApiRollbackBobtailByActivitiesToRequest,
  IMoveApiRollbackBobtailByActivitiesToSuccess,
  IMoveApiRollbackBobtailByActivitiesToError
>('MOVEAPI_ROLLBACKBOBTAILBYACTIVITIESTO')

export interface IMoveApiStartActivityRequest {
  date: Date
  id: string
  userName: string
  options?: any
}
export type IMoveApiStartActivitySuccess = MoveItemActivityDetailsDTO
export type IMoveApiStartActivityError = any
export const moveApiStartActivityAction = businessAsyncActionCreator.async<
  IMoveApiStartActivityRequest,
  IMoveApiStartActivitySuccess,
  IMoveApiStartActivityError
>('MOVEAPI_STARTACTIVITY')

export interface IMoveApiUpdateActivitiesStatusRequest {
  status:
    | 'NEW'
    | 'DRIVER_PLANNED'
    | 'DRIVER_REFUSED'
    | 'DRIVER_ASSIGNED'
    | 'DRIVER_CONFIRMED'
    | 'IN_PROCESS'
    | 'COMPLETED'
    | 'UNSUCCESSFUL'
  userName: string
  body: Array<string>
  options?: any
}
export type IMoveApiUpdateActivitiesStatusSuccess = Response
export type IMoveApiUpdateActivitiesStatusError = any
export const moveApiUpdateActivitiesStatusAction = businessAsyncActionCreator.async<
  IMoveApiUpdateActivitiesStatusRequest,
  IMoveApiUpdateActivitiesStatusSuccess,
  IMoveApiUpdateActivitiesStatusError
>('MOVEAPI_UPDATEACTIVITIESSTATUS')

export interface IMoveApiUpdateActivityActualDatesRequest {
  date: Date
  id: string
  userName: string
  options?: any
}
export type IMoveApiUpdateActivityActualDatesSuccess = MoveItemActivityDetailsDTO
export type IMoveApiUpdateActivityActualDatesError = any
export const moveApiUpdateActivityActualDatesAction = businessAsyncActionCreator.async<
  IMoveApiUpdateActivityActualDatesRequest,
  IMoveApiUpdateActivityActualDatesSuccess,
  IMoveApiUpdateActivityActualDatesError
>('MOVEAPI_UPDATEACTIVITYACTUALDATES')

export interface IMoveApiUpdateActivityStatusRequest {
  id: string
  status:
    | 'NEW'
    | 'DRIVER_PLANNED'
    | 'DRIVER_REFUSED'
    | 'DRIVER_ASSIGNED'
    | 'DRIVER_CONFIRMED'
    | 'IN_PROCESS'
    | 'COMPLETED'
    | 'UNSUCCESSFUL'
  userName: string
  options?: any
}
export type IMoveApiUpdateActivityStatusSuccess = MoveItemActivityDetailsDTO
export type IMoveApiUpdateActivityStatusError = any
export const moveApiUpdateActivityStatusAction = businessAsyncActionCreator.async<
  IMoveApiUpdateActivityStatusRequest,
  IMoveApiUpdateActivityStatusSuccess,
  IMoveApiUpdateActivityStatusError
>('MOVEAPI_UPDATEACTIVITYSTATUS')

export interface IMoveApiUpdateDispatchDeliveryOrderContainerRequest {
  id: string
  vendorUserId: string
  body: MoveDispatchDeliveryOrderContainerDTO
  options?: any
}
export type IMoveApiUpdateDispatchDeliveryOrderContainerSuccess = MoveDispatchDeliveryOrderDetailsDTO
export type IMoveApiUpdateDispatchDeliveryOrderContainerError = any
export const moveApiUpdateDispatchDeliveryOrderContainerAction = businessAsyncActionCreator.async<
  IMoveApiUpdateDispatchDeliveryOrderContainerRequest,
  IMoveApiUpdateDispatchDeliveryOrderContainerSuccess,
  IMoveApiUpdateDispatchDeliveryOrderContainerError
>('MOVEAPI_UPDATEDISPATCHDELIVERYORDERCONTAINER')

export interface IMoveApiUpdateDispatchDeliveryOrderEquipmentRequest {
  id: string
  vendorUserId: string
  body: MoveDispatchDeliveryOrderEquipmentDTO
  options?: any
}
export type IMoveApiUpdateDispatchDeliveryOrderEquipmentSuccess = MoveDispatchDeliveryOrderDetailsDTO
export type IMoveApiUpdateDispatchDeliveryOrderEquipmentError = any
export const moveApiUpdateDispatchDeliveryOrderEquipmentAction = businessAsyncActionCreator.async<
  IMoveApiUpdateDispatchDeliveryOrderEquipmentRequest,
  IMoveApiUpdateDispatchDeliveryOrderEquipmentSuccess,
  IMoveApiUpdateDispatchDeliveryOrderEquipmentError
>('MOVEAPI_UPDATEDISPATCHDELIVERYORDEREQUIPMENT')

export interface IMoveApiUpdateDispatchDeliveryOrderWeightRequest {
  id: string
  vendorUserId: string
  body: MoveDispatchDeliveryOrderWeightDTO
  options?: any
}
export type IMoveApiUpdateDispatchDeliveryOrderWeightSuccess = MoveDispatchDeliveryOrderDetailsDTO
export type IMoveApiUpdateDispatchDeliveryOrderWeightError = any
export const moveApiUpdateDispatchDeliveryOrderWeightAction = businessAsyncActionCreator.async<
  IMoveApiUpdateDispatchDeliveryOrderWeightRequest,
  IMoveApiUpdateDispatchDeliveryOrderWeightSuccess,
  IMoveApiUpdateDispatchDeliveryOrderWeightError
>('MOVEAPI_UPDATEDISPATCHDELIVERYORDERWEIGHT')

export interface INonPlannedActivityGetDeliveryStageCompletedNoReturnedRequest {
  filter?: string
  sort?: string
  options?: any
}
export type INonPlannedActivityGetDeliveryStageCompletedNoReturnedSuccess = DispatchDeliveryOrderGridItemDTO[]
export type INonPlannedActivityGetDeliveryStageCompletedNoReturnedError = any
export const nonPlannedActivityGetDeliveryStageCompletedNoReturnedAction = businessAsyncActionCreator.async<
  INonPlannedActivityGetDeliveryStageCompletedNoReturnedRequest,
  INonPlannedActivityGetDeliveryStageCompletedNoReturnedSuccess,
  INonPlannedActivityGetDeliveryStageCompletedNoReturnedError
>('NONPLANNEDACTIVITY_GETDELIVERYSTAGECOMPLETEDNORETURNED')

export interface INonPlannedActivityGetDroppedAtDeliveryLocationRequest {
  filter?: string
  sort?: string
  options?: any
}
export type INonPlannedActivityGetDroppedAtDeliveryLocationSuccess = DispatchDeliveryOrderGridItemDTO[]
export type INonPlannedActivityGetDroppedAtDeliveryLocationError = any
export const nonPlannedActivityGetDroppedAtDeliveryLocationAction = businessAsyncActionCreator.async<
  INonPlannedActivityGetDroppedAtDeliveryLocationRequest,
  INonPlannedActivityGetDroppedAtDeliveryLocationSuccess,
  INonPlannedActivityGetDroppedAtDeliveryLocationError
>('NONPLANNEDACTIVITY_GETDROPPEDATDELIVERYLOCATION')

export interface INonPlannedActivityGetDroppedAtYardRequest {
  filter?: string
  sort?: string
  options?: any
}
export type INonPlannedActivityGetDroppedAtYardSuccess = DispatchDeliveryOrderGridItemDTO[]
export type INonPlannedActivityGetDroppedAtYardError = any
export const nonPlannedActivityGetDroppedAtYardAction = businessAsyncActionCreator.async<
  INonPlannedActivityGetDroppedAtYardRequest,
  INonPlannedActivityGetDroppedAtYardSuccess,
  INonPlannedActivityGetDroppedAtYardError
>('NONPLANNEDACTIVITY_GETDROPPEDATYARD')

export interface INonPlannedActivityGetNonPlannedActivityCountsRequest {
  options?: any
}
export type INonPlannedActivityGetNonPlannedActivityCountsSuccess = NonPlannedActivityCountDTO
export type INonPlannedActivityGetNonPlannedActivityCountsError = any
export const nonPlannedActivityGetNonPlannedActivityCountsAction = businessAsyncActionCreator.async<
  INonPlannedActivityGetNonPlannedActivityCountsRequest,
  INonPlannedActivityGetNonPlannedActivityCountsSuccess,
  INonPlannedActivityGetNonPlannedActivityCountsError
>('NONPLANNEDACTIVITY_GETNONPLANNEDACTIVITYCOUNTS')

export interface INonPlannedActivityGetPickedUpNoDeliveryRequest {
  filter?: string
  sort?: string
  options?: any
}
export type INonPlannedActivityGetPickedUpNoDeliverySuccess = DispatchDeliveryOrderGridItemDTO[]
export type INonPlannedActivityGetPickedUpNoDeliveryError = any
export const nonPlannedActivityGetPickedUpNoDeliveryAction = businessAsyncActionCreator.async<
  INonPlannedActivityGetPickedUpNoDeliveryRequest,
  INonPlannedActivityGetPickedUpNoDeliverySuccess,
  INonPlannedActivityGetPickedUpNoDeliveryError
>('NONPLANNEDACTIVITY_GETPICKEDUPNODELIVERY')

export interface IOrganizationDeleteRequest {
  id: string
  options?: any
}
export type IOrganizationDeleteSuccess = Response
export type IOrganizationDeleteError = any
export const organizationDeleteAction = businessAsyncActionCreator.async<
  IOrganizationDeleteRequest,
  IOrganizationDeleteSuccess,
  IOrganizationDeleteError
>('ORGANIZATION_DELETE')

export interface IOrganizationCreateRequest {
  body: OrganizationDTO
  options?: any
}
export type IOrganizationCreateSuccess = OrganizationViewDTO
export type IOrganizationCreateError = any
export const organizationCreateAction = businessAsyncActionCreator.async<
  IOrganizationCreateRequest,
  IOrganizationCreateSuccess,
  IOrganizationCreateError
>('ORGANIZATION_CREATE')

export interface IOrganizationFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IOrganizationFindAllSuccess = OrganizationViewDTO[]
export type IOrganizationFindAllError = any
export const organizationFindAllAction = businessAsyncActionCreator.async<
  IOrganizationFindAllRequest,
  IOrganizationFindAllSuccess,
  IOrganizationFindAllError
>('ORGANIZATION_FINDALL')

export interface IOrganizationFindByIdRequest {
  id: string
  options?: any
}
export type IOrganizationFindByIdSuccess = OrganizationViewDTO
export type IOrganizationFindByIdError = any
export const organizationFindByIdAction = businessAsyncActionCreator.async<
  IOrganizationFindByIdRequest,
  IOrganizationFindByIdSuccess,
  IOrganizationFindByIdError
>('ORGANIZATION_FINDBYID')

export interface IOrganizationUpdateRequest {
  id: string
  body: OrganizationDTO
  options?: any
}
export type IOrganizationUpdateSuccess = OrganizationViewDTO
export type IOrganizationUpdateError = any
export const organizationUpdateAction = businessAsyncActionCreator.async<
  IOrganizationUpdateRequest,
  IOrganizationUpdateSuccess,
  IOrganizationUpdateError
>('ORGANIZATION_UPDATE')

export interface IPowerUnitDeleteRequest {
  id: string
  options?: any
}
export type IPowerUnitDeleteSuccess = Response
export type IPowerUnitDeleteError = any
export const powerUnitDeleteAction = businessAsyncActionCreator.async<
  IPowerUnitDeleteRequest,
  IPowerUnitDeleteSuccess,
  IPowerUnitDeleteError
>('POWERUNIT_DELETE')

export interface IPowerUnitCheckTaxIdRequest {
  vin: string
  options?: any
}
export type IPowerUnitCheckTaxIdSuccess = Response
export type IPowerUnitCheckTaxIdError = any
export const powerUnitCheckTaxIdAction = businessAsyncActionCreator.async<
  IPowerUnitCheckTaxIdRequest,
  IPowerUnitCheckTaxIdSuccess,
  IPowerUnitCheckTaxIdError
>('POWERUNIT_CHECKTAXID')

export interface IPowerUnitCreateRequest {
  body: PowerUnitDTO
  options?: any
}
export type IPowerUnitCreateSuccess = PowerUnitViewDTO
export type IPowerUnitCreateError = any
export const powerUnitCreateAction = businessAsyncActionCreator.async<
  IPowerUnitCreateRequest,
  IPowerUnitCreateSuccess,
  IPowerUnitCreateError
>('POWERUNIT_CREATE')

export interface IPowerUnitFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IPowerUnitFindAllSuccess = PowerUnitViewDTO[]
export type IPowerUnitFindAllError = any
export const powerUnitFindAllAction = businessAsyncActionCreator.async<
  IPowerUnitFindAllRequest,
  IPowerUnitFindAllSuccess,
  IPowerUnitFindAllError
>('POWERUNIT_FINDALL')

export interface IPowerUnitFindByIdRequest {
  id: string
  options?: any
}
export type IPowerUnitFindByIdSuccess = PowerUnitViewDTO
export type IPowerUnitFindByIdError = any
export const powerUnitFindByIdAction = businessAsyncActionCreator.async<
  IPowerUnitFindByIdRequest,
  IPowerUnitFindByIdSuccess,
  IPowerUnitFindByIdError
>('POWERUNIT_FINDBYID')

export interface IPowerUnitSearchRequest {
  limit?: number
  term?: string
  options?: any
}
export type IPowerUnitSearchSuccess = PowerUnitSearchDTO[]
export type IPowerUnitSearchError = any
export const powerUnitSearchAction = businessAsyncActionCreator.async<
  IPowerUnitSearchRequest,
  IPowerUnitSearchSuccess,
  IPowerUnitSearchError
>('POWERUNIT_SEARCH')

export interface IPowerUnitUpdateRequest {
  id: string
  body: PowerUnitDTO
  options?: any
}
export type IPowerUnitUpdateSuccess = PowerUnitViewDTO
export type IPowerUnitUpdateError = any
export const powerUnitUpdateAction = businessAsyncActionCreator.async<
  IPowerUnitUpdateRequest,
  IPowerUnitUpdateSuccess,
  IPowerUnitUpdateError
>('POWERUNIT_UPDATE')

export interface ISellSideQuoteApplyRateRequest {
  body: SsqRateDTO
  options?: any
}
export type ISellSideQuoteApplyRateSuccess = Response
export type ISellSideQuoteApplyRateError = any
export const sellSideQuoteApplyRateAction = businessAsyncActionCreator.async<
  ISellSideQuoteApplyRateRequest,
  ISellSideQuoteApplyRateSuccess,
  ISellSideQuoteApplyRateError
>('SELLSIDEQUOTE_APPLYRATE')

export interface ISellSideQuoteFindByIdRequest {
  id: string
  options?: any
}
export type ISellSideQuoteFindByIdSuccess = SellSideQuoteDTO
export type ISellSideQuoteFindByIdError = any
export const sellSideQuoteFindByIdAction = businessAsyncActionCreator.async<
  ISellSideQuoteFindByIdRequest,
  ISellSideQuoteFindByIdSuccess,
  ISellSideQuoteFindByIdError
>('SELLSIDEQUOTE_FINDBYID')

export interface ISellSideQuoteUpdateRequest {
  id: string
  body: SellSideQuoteDTO
  options?: any
}
export type ISellSideQuoteUpdateSuccess = SellSideQuoteDTO
export type ISellSideQuoteUpdateError = any
export const sellSideQuoteUpdateAction = businessAsyncActionCreator.async<
  ISellSideQuoteUpdateRequest,
  ISellSideQuoteUpdateSuccess,
  ISellSideQuoteUpdateError
>('SELLSIDEQUOTE_UPDATE')

export interface IStateFindAllRequest {
  options?: any
}
export type IStateFindAllSuccess = StateDTO[]
export type IStateFindAllError = any
export const stateFindAllAction = businessAsyncActionCreator.async<
  IStateFindAllRequest,
  IStateFindAllSuccess,
  IStateFindAllError
>('STATE_FINDALL')

export interface ISteamShipLineDeleteRequest {
  id: string
  options?: any
}
export type ISteamShipLineDeleteSuccess = Response
export type ISteamShipLineDeleteError = any
export const steamShipLineDeleteAction = businessAsyncActionCreator.async<
  ISteamShipLineDeleteRequest,
  ISteamShipLineDeleteSuccess,
  ISteamShipLineDeleteError
>('STEAMSHIPLINE_DELETE')

export interface ISteamShipLineCreateRequest {
  body: SteamShipLineDTO
  options?: any
}
export type ISteamShipLineCreateSuccess = SteamShipLineViewDTO
export type ISteamShipLineCreateError = any
export const steamShipLineCreateAction = businessAsyncActionCreator.async<
  ISteamShipLineCreateRequest,
  ISteamShipLineCreateSuccess,
  ISteamShipLineCreateError
>('STEAMSHIPLINE_CREATE')

export interface ISteamShipLineFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type ISteamShipLineFindAllSuccess = SteamShipLineViewDTO[]
export type ISteamShipLineFindAllError = any
export const steamShipLineFindAllAction = businessAsyncActionCreator.async<
  ISteamShipLineFindAllRequest,
  ISteamShipLineFindAllSuccess,
  ISteamShipLineFindAllError
>('STEAMSHIPLINE_FINDALL')

export interface ISteamShipLineFindByIdRequest {
  id: string
  options?: any
}
export type ISteamShipLineFindByIdSuccess = SteamShipLineViewDTO
export type ISteamShipLineFindByIdError = any
export const steamShipLineFindByIdAction = businessAsyncActionCreator.async<
  ISteamShipLineFindByIdRequest,
  ISteamShipLineFindByIdSuccess,
  ISteamShipLineFindByIdError
>('STEAMSHIPLINE_FINDBYID')

export interface ISteamShipLineSearchRequest {
  limit?: number
  term?: string
  options?: any
}
export type ISteamShipLineSearchSuccess = SteamShipLineNameDTO[]
export type ISteamShipLineSearchError = any
export const steamShipLineSearchAction = businessAsyncActionCreator.async<
  ISteamShipLineSearchRequest,
  ISteamShipLineSearchSuccess,
  ISteamShipLineSearchError
>('STEAMSHIPLINE_SEARCH')

export interface ISteamShipLineUpdateRequest {
  id: string
  body: SteamShipLineDTO
  options?: any
}
export type ISteamShipLineUpdateSuccess = SteamShipLineViewDTO
export type ISteamShipLineUpdateError = any
export const steamShipLineUpdateAction = businessAsyncActionCreator.async<
  ISteamShipLineUpdateRequest,
  ISteamShipLineUpdateSuccess,
  ISteamShipLineUpdateError
>('STEAMSHIPLINE_UPDATE')

export interface ISubClientDeleteRequest {
  id: string
  options?: any
}
export type ISubClientDeleteSuccess = Response
export type ISubClientDeleteError = any
export const subClientDeleteAction = businessAsyncActionCreator.async<
  ISubClientDeleteRequest,
  ISubClientDeleteSuccess,
  ISubClientDeleteError
>('SUBCLIENT_DELETE')

export interface ISubClientCreateRequest {
  body: SubClientDTO
  options?: any
}
export type ISubClientCreateSuccess = SubClientViewDTO
export type ISubClientCreateError = any
export const subClientCreateAction = businessAsyncActionCreator.async<
  ISubClientCreateRequest,
  ISubClientCreateSuccess,
  ISubClientCreateError
>('SUBCLIENT_CREATE')

export interface ISubClientFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type ISubClientFindAllSuccess = SubClientViewDTO[]
export type ISubClientFindAllError = any
export const subClientFindAllAction = businessAsyncActionCreator.async<
  ISubClientFindAllRequest,
  ISubClientFindAllSuccess,
  ISubClientFindAllError
>('SUBCLIENT_FINDALL')

export interface ISubClientFindByIdRequest {
  id: string
  options?: any
}
export type ISubClientFindByIdSuccess = SubClientViewDTO
export type ISubClientFindByIdError = any
export const subClientFindByIdAction = businessAsyncActionCreator.async<
  ISubClientFindByIdRequest,
  ISubClientFindByIdSuccess,
  ISubClientFindByIdError
>('SUBCLIENT_FINDBYID')

export interface ISubClientSearchRequest {
  customerId: string
  limit?: number
  term?: string
  options?: any
}
export type ISubClientSearchSuccess = SubClientSearchDTO[]
export type ISubClientSearchError = any
export const subClientSearchAction = businessAsyncActionCreator.async<
  ISubClientSearchRequest,
  ISubClientSearchSuccess,
  ISubClientSearchError
>('SUBCLIENT_SEARCH')

export interface ISubClientUpdateRequest {
  id: string
  body: SubClientDTO
  options?: any
}
export type ISubClientUpdateSuccess = SubClientViewDTO
export type ISubClientUpdateError = any
export const subClientUpdateAction = businessAsyncActionCreator.async<
  ISubClientUpdateRequest,
  ISubClientUpdateSuccess,
  ISubClientUpdateError
>('SUBCLIENT_UPDATE')

export interface ITerminalMismatchGetTerminalMismatchesRequest {
  dispatchDeliveryOrderId: string
  options?: any
}
export type ITerminalMismatchGetTerminalMismatchesSuccess = TerminalMismatchDTO[]
export type ITerminalMismatchGetTerminalMismatchesError = any
export const terminalMismatchGetTerminalMismatchesAction = businessAsyncActionCreator.async<
  ITerminalMismatchGetTerminalMismatchesRequest,
  ITerminalMismatchGetTerminalMismatchesSuccess,
  ITerminalMismatchGetTerminalMismatchesError
>('TERMINALMISMATCH_GETTERMINALMISMATCHES')

export interface ITerminalNoteGetTerminalNotesRequest {
  dispatchDeliveryOrderId: string
  options?: any
}
export type ITerminalNoteGetTerminalNotesSuccess = TerminalNoteDTO[]
export type ITerminalNoteGetTerminalNotesError = any
export const terminalNoteGetTerminalNotesAction = businessAsyncActionCreator.async<
  ITerminalNoteGetTerminalNotesRequest,
  ITerminalNoteGetTerminalNotesSuccess,
  ITerminalNoteGetTerminalNotesError
>('TERMINALNOTE_GETTERMINALNOTES')

export interface ITransportationActivityDeleteRequest {
  id: string
  options?: any
}
export type ITransportationActivityDeleteSuccess = Response
export type ITransportationActivityDeleteError = any
export const transportationActivityDeleteAction = businessAsyncActionCreator.async<
  ITransportationActivityDeleteRequest,
  ITransportationActivityDeleteSuccess,
  ITransportationActivityDeleteError
>('TRANSPORTATIONACTIVITY_DELETE')

export interface ITransportationActivityFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type ITransportationActivityFindAllSuccess = TransportationActivityViewDTO[]
export type ITransportationActivityFindAllError = any
export const transportationActivityFindAllAction = businessAsyncActionCreator.async<
  ITransportationActivityFindAllRequest,
  ITransportationActivityFindAllSuccess,
  ITransportationActivityFindAllError
>('TRANSPORTATIONACTIVITY_FINDALL')

export interface ITransportationActivityFindByIdRequest {
  id: string
  options?: any
}
export type ITransportationActivityFindByIdSuccess = TransportationActivityViewDTO
export type ITransportationActivityFindByIdError = any
export const transportationActivityFindByIdAction = businessAsyncActionCreator.async<
  ITransportationActivityFindByIdRequest,
  ITransportationActivityFindByIdSuccess,
  ITransportationActivityFindByIdError
>('TRANSPORTATIONACTIVITY_FINDBYID')

export interface ITransportationActivityGetTemplateRequest {
  deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION'
  loadType: 'LIVE_LOAD' | 'DROP_AND_PICK'
  options?: any
}
export type ITransportationActivityGetTemplateSuccess = TransportationActivityDTO[]
export type ITransportationActivityGetTemplateError = any
export const transportationActivityGetTemplateAction = businessAsyncActionCreator.async<
  ITransportationActivityGetTemplateRequest,
  ITransportationActivityGetTemplateSuccess,
  ITransportationActivityGetTemplateError
>('TRANSPORTATIONACTIVITY_GETTEMPLATE')

export interface ITroubleTicketCreateRequest {
  body: TroubleTicketDTO
  options?: any
}
export type ITroubleTicketCreateSuccess = TroubleTicketDTO
export type ITroubleTicketCreateError = any
export const troubleTicketCreateAction = businessAsyncActionCreator.async<
  ITroubleTicketCreateRequest,
  ITroubleTicketCreateSuccess,
  ITroubleTicketCreateError
>('TROUBLETICKET_CREATE')

export interface ITroubleTicketFindByIdRequest {
  id: string
  options?: any
}
export type ITroubleTicketFindByIdSuccess = TroubleTicketDTO
export type ITroubleTicketFindByIdError = any
export const troubleTicketFindByIdAction = businessAsyncActionCreator.async<
  ITroubleTicketFindByIdRequest,
  ITroubleTicketFindByIdSuccess,
  ITroubleTicketFindByIdError
>('TROUBLETICKET_FINDBYID')

export interface ITroubleTicketProceedRequest {
  id: string
  body: TroubleTicketUpdateDTO
  options?: any
}
export type ITroubleTicketProceedSuccess = TroubleTicketDTO
export type ITroubleTicketProceedError = any
export const troubleTicketProceedAction = businessAsyncActionCreator.async<
  ITroubleTicketProceedRequest,
  ITroubleTicketProceedSuccess,
  ITroubleTicketProceedError
>('TROUBLETICKET_PROCEED')

export interface ITroubleTicketUnsuccessfulRequest {
  id: string
  body: TroubleTicketUpdateDTO
  options?: any
}
export type ITroubleTicketUnsuccessfulSuccess = TroubleTicketDTO
export type ITroubleTicketUnsuccessfulError = any
export const troubleTicketUnsuccessfulAction = businessAsyncActionCreator.async<
  ITroubleTicketUnsuccessfulRequest,
  ITroubleTicketUnsuccessfulSuccess,
  ITroubleTicketUnsuccessfulError
>('TROUBLETICKET_UNSUCCESSFUL')

export interface IUiSettingsCreateRequest {
  body: UISettingsDTO
  options?: any
}
export type IUiSettingsCreateSuccess = UISettingsDTO
export type IUiSettingsCreateError = any
export const uiSettingsCreateAction = businessAsyncActionCreator.async<
  IUiSettingsCreateRequest,
  IUiSettingsCreateSuccess,
  IUiSettingsCreateError
>('UISETTINGS_CREATE')

export interface IUiSettingsDeleteAllRequest {
  options?: any
}
export type IUiSettingsDeleteAllSuccess = string
export type IUiSettingsDeleteAllError = any
export const uiSettingsDeleteAllAction = businessAsyncActionCreator.async<
  IUiSettingsDeleteAllRequest,
  IUiSettingsDeleteAllSuccess,
  IUiSettingsDeleteAllError
>('UISETTINGS_DELETEALL')

export interface IUiSettingsDeleteByUserIdRequest {
  userId: string
  options?: any
}
export type IUiSettingsDeleteByUserIdSuccess = UISettingsDTO
export type IUiSettingsDeleteByUserIdError = any
export const uiSettingsDeleteByUserIdAction = businessAsyncActionCreator.async<
  IUiSettingsDeleteByUserIdRequest,
  IUiSettingsDeleteByUserIdSuccess,
  IUiSettingsDeleteByUserIdError
>('UISETTINGS_DELETEBYUSERID')

export interface IUiSettingsFindByUserIdRequest {
  userId: string
  options?: any
}
export type IUiSettingsFindByUserIdSuccess = UISettingsDTO
export type IUiSettingsFindByUserIdError = any
export const uiSettingsFindByUserIdAction = businessAsyncActionCreator.async<
  IUiSettingsFindByUserIdRequest,
  IUiSettingsFindByUserIdSuccess,
  IUiSettingsFindByUserIdError
>('UISETTINGS_FINDBYUSERID')

export interface IUiSettingsUpdateByUserIdRequest {
  body: UISettingsDTO
  options?: any
}
export type IUiSettingsUpdateByUserIdSuccess = UISettingsDTO
export type IUiSettingsUpdateByUserIdError = any
export const uiSettingsUpdateByUserIdAction = businessAsyncActionCreator.async<
  IUiSettingsUpdateByUserIdRequest,
  IUiSettingsUpdateByUserIdSuccess,
  IUiSettingsUpdateByUserIdError
>('UISETTINGS_UPDATEBYUSERID')

export interface IVendorDeleteRequest {
  id: string
  options?: any
}
export type IVendorDeleteSuccess = Response
export type IVendorDeleteError = any
export const vendorDeleteAction = businessAsyncActionCreator.async<
  IVendorDeleteRequest,
  IVendorDeleteSuccess,
  IVendorDeleteError
>('VENDOR_DELETE')

export interface IVendorCreateRequest {
  body: VendorDTO
  options?: any
}
export type IVendorCreateSuccess = VendorViewDTO
export type IVendorCreateError = any
export const vendorCreateAction = businessAsyncActionCreator.async<
  IVendorCreateRequest,
  IVendorCreateSuccess,
  IVendorCreateError
>('VENDOR_CREATE')

export interface IVendorFindAllRequest {
  filter?: string
  sort?: string
  options?: any
}
export type IVendorFindAllSuccess = VendorViewDTO[]
export type IVendorFindAllError = any
export const vendorFindAllAction = businessAsyncActionCreator.async<
  IVendorFindAllRequest,
  IVendorFindAllSuccess,
  IVendorFindAllError
>('VENDOR_FINDALL')

export interface IVendorFindByIdRequest {
  id: string
  options?: any
}
export type IVendorFindByIdSuccess = VendorViewDTO
export type IVendorFindByIdError = any
export const vendorFindByIdAction = businessAsyncActionCreator.async<
  IVendorFindByIdRequest,
  IVendorFindByIdSuccess,
  IVendorFindByIdError
>('VENDOR_FINDBYID')

export interface IVendorUpdateRequest {
  id: string
  body: VendorDTO
  options?: any
}
export type IVendorUpdateSuccess = VendorViewDTO
export type IVendorUpdateError = any
export const vendorUpdateAction = businessAsyncActionCreator.async<
  IVendorUpdateRequest,
  IVendorUpdateSuccess,
  IVendorUpdateError
>('VENDOR_UPDATE')

export interface IWorkOrderConfirmDriverRequest {
  body: WorkOrderRefDTO
  options?: any
}
export type IWorkOrderConfirmDriverSuccess = Response
export type IWorkOrderConfirmDriverError = any
export const workOrderConfirmDriverAction = businessAsyncActionCreator.async<
  IWorkOrderConfirmDriverRequest,
  IWorkOrderConfirmDriverSuccess,
  IWorkOrderConfirmDriverError
>('WORKORDER_CONFIRMDRIVER')

export interface IWorkOrderGetWorOrderHistoryRequest {
  dispatchDeliveryOrderId: string
  vendorId: string
  options?: any
}
export type IWorkOrderGetWorOrderHistorySuccess = WorkOrderHistoryDTO
export type IWorkOrderGetWorOrderHistoryError = any
export const workOrderGetWorOrderHistoryAction = businessAsyncActionCreator.async<
  IWorkOrderGetWorOrderHistoryRequest,
  IWorkOrderGetWorOrderHistorySuccess,
  IWorkOrderGetWorOrderHistoryError
>('WORKORDER_GETWORORDERHISTORY')

export interface IWorkOrderRefuseDriverRequest {
  body: WorkOrderRefDTO
  options?: any
}
export type IWorkOrderRefuseDriverSuccess = Response
export type IWorkOrderRefuseDriverError = any
export const workOrderRefuseDriverAction = businessAsyncActionCreator.async<
  IWorkOrderRefuseDriverRequest,
  IWorkOrderRefuseDriverSuccess,
  IWorkOrderRefuseDriverError
>('WORKORDER_REFUSEDRIVER')
