import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { getStreetTurnStatus } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderStreetTurnDTO } from '../../../../../api/origin/business-logic'
import { openDDOinNewTab } from '../../../../../services/DTO/dispatchDeliveryOrder'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { useTabData } from '../../../../../hooks/useTabData'

type Props = {}

export const ContainerColumn = (props: Props) => {
  const gridItemDDOData = useGridItemDDO()
  const { dispatchDeliveryOrder, deliveryOrder, container } = gridItemDDOData
  const { tabData } = useTabData()
  const streetTurnMappingByDdoId: Record<string, DispatchDeliveryOrderStreetTurnDTO> = oc(
    tabData
  ).streetTurnMappingByDdoId({})
  const containerNumber = oc(container).number()
  const streetTurn = gridItemDDOData.streetTurn || oc(streetTurnMappingByDdoId)[dispatchDeliveryOrder.id]()
  const streetTurnStatus = getStreetTurnStatus({
    streetTurn,
    streetTurnCount: dispatchDeliveryOrder.streetTurnCount
  })
  const isExportDDO = oc(deliveryOrder).type() === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isStreetTurn =
    streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED ||
    streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED

  return (
    <div>
      <ValueContainer>
        {isStreetTurn ? (
          <>
            {isExportDDO ? (
              // Export Street Turn
              <div>
                <StreetTurnDDOLink
                  onClick={event =>
                    openDDOinNewTab({
                      // @ts-ignore
                      event,
                      ddoId: isExportDDO ? streetTurn.ddoImportId : streetTurn.ddoExportId,
                      ddoNumber: isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber
                    })
                  }
                >
                  DDO# {isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber}
                </StreetTurnDDOLink>
                <div style={{ marginTop: 3 }}>{containerNumber}</div>
              </div>
            ) : (
              // Import Street Turn
              <div>
                <div style={{ marginBottom: 2 }}>{containerNumber}</div>
                <StreetTurnDDOLink
                  onClick={event =>
                    openDDOinNewTab({
                      // @ts-ignore
                      event,
                      ddoId: isExportDDO ? streetTurn.ddoImportId : streetTurn.ddoExportId,
                      ddoNumber: isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber
                    })
                  }
                >
                  DDO# {isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber}
                </StreetTurnDDOLink>
              </div>
            )}
          </>
        ) : (
          containerNumber
        )}
      </ValueContainer>
    </div>
  )
}

const StreetTurnDDOLink = styled.div`
  color: #4555d0;
  white-space: nowrap;
  text-decoration: underline;

  &:hover {
    color: #5599ff;
  }
`
const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`
