import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { getStreetTurnStatus } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderStreetTurnDTO } from '../../../../../api/origin/business-logic'
import { openDDOinNewTab } from '../../../../../services/DTO/dispatchDeliveryOrder'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { useTabData } from '../../../../../hooks/useTabData'

type Props = {}

export const StreetTurnColumn = (props: Props) => {
  const gridItemDDOData = useGridItemDDO()
  const { dispatchDeliveryOrder, deliveryOrder, container } = gridItemDDOData
  const { tabData } = useTabData()
  const streetTurnMappingByDdoId: Record<string, DispatchDeliveryOrderStreetTurnDTO> = oc(
    tabData
  ).streetTurnMappingByDdoId({})
  const streetTurn = gridItemDDOData.streetTurn || oc(streetTurnMappingByDdoId)[dispatchDeliveryOrder.id]()
  const streetTurnStatus = getStreetTurnStatus({
    streetTurn,
    streetTurnCount: dispatchDeliveryOrder.streetTurnCount
  })
  const hasRejectedStreetTurn = oc(dispatchDeliveryOrder).hasRejectedStreetTurn(false)

  return (
    <Container>
      {streetTurnStatus && (
        <STLabel style={{ backgroundColor: ddoDirectory.streetTurnStatusColor[streetTurnStatus] }}>ST</STLabel>
      )}
      {hasRejectedStreetTurn && (
        <STLabel
          style={{
            backgroundColor: ddoDirectory.streetTurnStatusColor[DispatchDeliveryOrderStreetTurnDTO.StatusEnum.REJECTED]
          }}
        >
          ST
        </STLabel>
      )}
    </Container>
  )
}

const STLabel = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  font-size: 12px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`
const Container = styled.div`
  justify-content: center;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
`
