import * as React from 'react'
import { StyledWidget, Preview, Popover, PopoverContainer, ButtonClose, WidgetTitle, WidgetActions } from './styles'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import cn from 'classnames'
import { ActionButton } from '../Buttons/ActionButton'
import { Portal } from 'react-portal'
import { useApplicationSize } from '../../../hooks/useApplicationSize'

type BottomButton = {
  text: string
  onClick?: () => void
  isCloseButton?: boolean
  style?: any
}

type OwnProps = {
  fullScreen?: boolean
  title?: string
  extraPreviewStyle?: React.CSSProperties
  extraPopoverStyle?: React.CSSProperties
  RenderPreview: (props: any) => JSX.Element
  RenderDetails: (props: any) => JSX.Element
  preventCloseWidget?: (closeWidget: () => void) => void
  previewProps?: any
  detailsProps?: any
  shadowColor?: string
  highlighted?: boolean
  fixedWidth?: boolean
  disable?: boolean
  required?: boolean
  red?: any
  value?: any
  isTableWidget?: boolean
  isExternal?: boolean
  isInitialExpanded?: boolean
  bottomActions?: boolean | BottomButton[]
  widgetPreviewClassName?: string
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Widget = (props: Props) => {
  const {
    fullScreen,
    extraPreviewStyle,
    extraPopoverStyle,
    disable,
    required,
    value,
    shadowColor,
    highlighted,
    fixedWidth,
    red,
    isTableWidget,
    RenderDetails,
    RenderPreview,
    isExternal,
    detailsProps,
    previewProps,
    title,
    bottomActions,
    isInitialExpanded,
    widgetPreviewClassName,
    preventCloseWidget
  } = props
  const { wrapperRef, togglePopover, statePopover, setPopoverState } = useOutsideClick({
    initialState: isInitialExpanded,
    withoutOutsideClick: true
  })
  const { communicationHubWidth } = useApplicationSize()
  const closePopover = () => {
    if (preventCloseWidget) {
      preventCloseWidget(() => setPopoverState(false))
    } else {
      setPopoverState(false)
    }
  }
  const toggle = () => (disable ? null : togglePopover())

  const classNames = cn({ open: statePopover, fixedWidth, disable, tableWidget: isTableWidget }, 'save')

  const previewClassNames = cn('widget-preview', widgetPreviewClassName, {
    [shadowColor]: !disable && Boolean(shadowColor),
    errored: required && !value,
    highlighted,
    red
  })
  const popoverClassNames = cn('save', { fullScreen })

  return (
    <WidgetContext.Provider value={{ closePopover, openPopover: () => setPopoverState(true) }}>
      <StyledWidget ref={wrapperRef} className={classNames}>
        <Preview style={extraPreviewStyle} className={previewClassNames} isGrid={true} onClick={toggle}>
          <RenderPreview {...previewProps || {}} />
        </Preview>
        {statePopover ? (
          <PopoverWrapper isExternal={isExternal}>
            <PopoverContainer
              className={cn({ isExternal })}
              style={fullScreen ? { right: communicationHubWidth } : undefined}
            >
              <Popover style={extraPopoverStyle} className={popoverClassNames}>
                <ButtonClose className={'mdi mdi-close'} onClick={closePopover} />
                {Boolean(title) && <WidgetTitle>{title}</WidgetTitle>}
                <RenderDetails {...detailsProps || {}} />
                {Boolean(bottomActions) && (
                  <WidgetActions>
                    {Array.isArray(bottomActions) &&
                      bottomActions.map((button, index) => {
                        return (
                          <ActionButton
                            styles={button.style}
                            key={index}
                            filled={true}
                            round={true}
                            onClick={() => {
                              if (button.isCloseButton) {
                                closePopover()
                              }

                              if (button.onClick) {
                                button.onClick()
                              }
                            }}
                          >
                            {button.text}
                          </ActionButton>
                        )
                      })}
                    <ActionButton styles={{ marginLeft: 'auto' }} filled={true} round={true} onClick={closePopover}>
                      Ok
                    </ActionButton>
                  </WidgetActions>
                )}
              </Popover>
            </PopoverContainer>
          </PopoverWrapper>
        ) : null}
      </StyledWidget>
    </WidgetContext.Provider>
  )
}

type TWidgetPopoverContainer = {
  isExternal: boolean
  children: any
}
const PopoverWrapper = ({ children, isExternal }: TWidgetPopoverContainer) =>
  isExternal ? <Portal>{children}</Portal> : <>{children}</>

export const WidgetContext: React.Context<{ closePopover: () => void; openPopover: () => void }> = React.createContext(
  undefined
)
