import * as React from 'react'
import cn from 'classnames'
import { Close, TextBlock, Img, StyledTab, Text, Input } from './styledComponents'
import { ITab, TabDTO } from '../../../store/reducers/tabs/interfaces'
import { tabDirectory } from '../../../store/reducers/tabs/directory'
import { updateTab } from '../../../store/reducers/tabs/functions'
import { preventEvent } from '../../../services/functions/basic'

type Props = {
  tab: ITab
  deleteTab: () => void
  onTabSelect: () => void
  setContaxtMenu: React.Dispatch<
    React.SetStateAction<{
      tab: ITab
      position: {
        top: number
        left: number
      }
    }>
  >
}

export const Tab = (props: Props) => {
  const { tab, onTabSelect, setContaxtMenu } = props
  const [editMode, setEditMode] = React.useState({
    state: false,
    text: tab.label
  })

  const onInputFinish = (event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent) => {
    preventEvent(event)
    const label = editMode.text.trim()

    if (!label) {
      return
    }

    updateTab(tab.id, { label })
  }

  const deleteTab = (event: React.MouseEvent) => {
    preventEvent(event)
    props.deleteTab()
  }

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onInputFinish(event)
    } else if (event.key === 'Escape') {
      onEditCancel(event)
    }
  }

  const onDoubleClick = (event: React.MouseEvent) => {
    preventEvent(event)

    if (tab.type !== TabDTO.Type.deliveryOrder) {
      setEditMode(_ => ({ ..._, state: true }))
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    setEditMode(_ => ({ ..._, text: newValue }))
  }

  const onEditCancel = (event?: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent) => {
    preventEvent(event)
    setEditMode(_ => ({ ..._, state: false }))
  }

  const onContextMenu = (event: React.MouseEvent) => {
    preventEvent(event)
    const position = { top: event.clientY, left: event.clientX }

    setContaxtMenu({ position, tab })
  }

  return (
    Boolean(tab) && (
      <StyledTab
        data-id={tab.type + '-' + tab.id}
        style={editMode.state ? { minWidth: 200 } : undefined}
        className={cn('tab', {
          edit: editMode.state,
          active: tab.active
        })}
        // @ts-ignore
        onContextMenu={onContextMenu}
        onClick={!tab.active && !editMode.state ? onTabSelect : undefined}
      >
        <Img className={tabDirectory.iconClassName[tab.type]} />
        <TextBlock onDoubleClick={onDoubleClick}>
          <Text style={editMode.state ? { visibility: 'hidden' } : undefined}>
            {tab.label || tabDirectory.tabLabel[tab.type]}
          </Text>
        </TextBlock>

        {editMode.state ? (
          <TextBlock>
            <Input
              type="text"
              autoFocus={true}
              value={editMode.text}
              onChange={onInputChange}
              // @ts-ignore
              onKeyDown={onKeyDown}
              // @ts-ignore
              onBlur={onInputFinish}
              onFocus={event => {
                if (event.target && event.target.value) {
                  const temp_value = event.target.value
                  event.target.value = ''
                  event.target.value = temp_value
                }
              }}
            />
          </TextBlock>
        ) : (
          <Close className="mdi mdi-close" onClick={deleteTab} />
        )}
      </StyledTab>
    )
  )
}
