export enum PowerUnitColumn {
  TruckNumber = 'Power Unit (Truck) #',
  ActiveAvailability = 'Active / Availability',
  Drivers = 'Drivers',
  VIN = 'VIN',
  Type = 'Type',
  Year = 'Year',
  Make = 'Make',
  Model = 'Model',
  GVW = 'GVW',
  Ownership = 'Ownership',
  PortPassRFID = 'Port Pass RFID',
  LicensePlateNumber = 'License Plate #',
  RegistrationState = 'Registration State',
  RegistrationDate = 'Registration Date',
  RegistrationExpDate = 'Registration Exp. Date',
  RegistrationGVW = 'Registration GVW',
  RegistrationRestrictions = 'Registration Restrictions',
  DOTSafetyInspectionDate = 'DOT Safety Inspection Date',
  DOTSafetyInspectionAnnualExpDate = 'DOT Safety Inspection Annual Exp. Date',
  MaintenanceInspectionFrequency = 'Maintenance Inspection Frequency',
  InsuranceType = 'Insurance Type',
  InsuranceCarrier = 'Insurance Carrier',
  InsuranceExpDate = 'Insurance Exp. Date',
  InsurancePolicyNumber = 'Insurance Policy #'
}

export const powerUnitColumnSettings = {
  [PowerUnitColumn.TruckNumber]: { width: 160, active: true },
  [PowerUnitColumn.ActiveAvailability]: { width: 260, active: true },
  [PowerUnitColumn.Drivers]: { width: 260, active: true },
  [PowerUnitColumn.VIN]: { width: 165, active: true },
  [PowerUnitColumn.LicensePlateNumber]: { width: 125, active: true },
  [PowerUnitColumn.Type]: { width: 180, active: true },
  [PowerUnitColumn.Year]: { width: 160, active: true },
  [PowerUnitColumn.Make]: { width: 160, active: true },
  [PowerUnitColumn.Model]: { width: 160, active: true },
  [PowerUnitColumn.GVW]: { width: 160, active: true },
  [PowerUnitColumn.Ownership]: { width: 160, active: true },
  [PowerUnitColumn.PortPassRFID]: { width: 160, active: true },
  [PowerUnitColumn.RegistrationState]: { width: 160, active: true },
  [PowerUnitColumn.RegistrationDate]: { width: 160, active: true },
  [PowerUnitColumn.RegistrationExpDate]: { width: 160, active: true },
  [PowerUnitColumn.RegistrationGVW]: { width: 160, active: true },
  [PowerUnitColumn.RegistrationRestrictions]: { width: 170, active: true },
  [PowerUnitColumn.DOTSafetyInspectionDate]: { width: 200, active: true },
  [PowerUnitColumn.DOTSafetyInspectionAnnualExpDate]: { width: 210, active: true },
  [PowerUnitColumn.MaintenanceInspectionFrequency]: { width: 160, active: true },
  [PowerUnitColumn.InsuranceType]: { width: 160, active: true },
  [PowerUnitColumn.InsuranceCarrier]: { width: 160, active: true },
  [PowerUnitColumn.InsuranceExpDate]: { width: 160, active: true },
  [PowerUnitColumn.InsurancePolicyNumber]: { width: 160, active: true }
}
