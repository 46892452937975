import * as React from 'react'
import {
  ActivitiesViewDTO,
  BusinessPartnerViewDTO,
  BuySideQuoteDTO,
  ContainerTypeDTO,
  ContainerViewDTO,
  CustomerViewDTO,
  DateISOString,
  DateTimeRangeDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  DriverViewDTO,
  EquipmentDTO,
  LocationViewDTO,
  SellSideQuoteDTO,
  SteamShipLineViewDTO,
  TransportationActivityViewDTO
} from '../api/api'
import { ActivityGroup, TransportationActivityGroup } from '../services/DTO/activity/interfaces'
import { TCalculateQuotes } from '../services/functions/calculateDDOQuotes'
import { CorrectActivityData } from '../services/DTO/dispatchDeliveryOrder/functions'

export interface IGridItemDDOContext {
  isExpanded: boolean
  isQuotesModified: boolean
  enableEditing: boolean
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrder: DeliveryOrderViewDTO
  containerType: ContainerTypeDTO
  container: ContainerViewDTO
  steamShipLine: SteamShipLineViewDTO
  customer: CustomerViewDTO
  customerBusinessPartner: BusinessPartnerViewDTO
  equipment: EquipmentDTO
  pickupLocation: LocationViewDTO
  deliveryLocation: LocationViewDTO
  returnLocation: LocationViewDTO
  deliveryActivityLocation: LocationViewDTO
  sellSideQuote: SellSideQuoteDTO
  buySideQuotes: BuySideQuoteDTO[]
  pickupDriver: DriverViewDTO
  streetTurn: DispatchDeliveryOrderStreetTurnDTO
  deliveryDriver: DriverViewDTO
  returnDriver: DriverViewDTO
  deliveryActivityDriver: DriverViewDTO
  deliveryActivity: TransportationActivityViewDTO
  activities: ActivitiesViewDTO
  declinedVendorIds: string[]
  declinedVendorsNames: string
  initialDdoStatus: DispatchDeliveryOrderViewDTO.StatusEnum
  actualDDOStatus: DispatchDeliveryOrderViewDTO.StatusEnum
  activityGroups: ActivityGroup[]
  currentActivityGroup: TransportationActivityGroup
  quoteAmounts: TCalculateQuotes

  // isBlumeServiceCreator: boolean
  isImport: boolean
  isExport: boolean
  isRepo: boolean
  isReefer: boolean
  isChassisNumberRequired: boolean

  modifyDispatchDeliveryOrder: (dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO) => void
  modifyDispatchDeliveryOrderField: (key: keyof DispatchDeliveryOrderViewDTO) => (value: any) => void
  modifyDeliveryOrder: (deliveryOrder: DeliveryOrderViewDTO) => void
  modifyDeliveryOrderField: (key: keyof DeliveryOrderViewDTO) => (value: any) => void
  modifyActivityData: (activityData: CorrectActivityData) => void
  modifyActivities: (activities: ActivitiesViewDTO) => void
  changeStageLocation: (props: { stage: TransportationActivityViewDTO.StageEnum; locationId: string }) => void
  cancelDispatchDeliveryOrder: () => void
  modifyPhaseDate: (props: {
    phaseType: 'pickup' | 'delivery' | 'pick' | 'return'
    date: DateISOString | DateTimeRangeDTO
    checkForDateChange?: boolean
    callBackIfDatesChanged?: () => any
  }) => void
  cancelQuotesModifies: () => void
}

export const GridItemDDOContext = React.createContext<IGridItemDDOContext>(undefined)

// {
//   isExpanded: undefined,
//   dispatchDeliveryOrder: undefined,
//   deliveryOrder: undefined,
//   containerType: undefined,
//   container: undefined,
//   steamShipLine: undefined,
//   customer: undefined,
//   customerBusinessPartner: undefined,
//   equipment: undefined,
//   pickupLocation: undefined,
//   deliveryLocation: undefined,
//   returnLocation: undefined,
//   sellSideQuote: undefined,
//   deliveryActivityLocation: undefined,
//   pickupDriver: undefined,
//   deliveryDriver: undefined,
//   returnDriver: undefined,
//   deliveryActivityDriver: undefined,
//   deliveryActivity: undefined,
//   declinedVendorsNames: undefined,
//   ddoStatus: undefined,
//   currentActivityGroup: undefined,
//   isImport: undefined,
//   isExport: undefined,
//   isRepo: undefined,
//   isReefer: undefined,
//   isChassisNumberRequired: undefined,
//   modifyDeliveryOrder: undefined,
//   modifyDeliveryOrderField: undefined,
//   activities: undefined
// }
