import * as React from 'react'
import cn from 'classnames'
import { TableRowColumns } from '../../../common/grid/views/styles'
import { columnSettings, columnsValues } from '../data'
import { SelectColumn } from './SelectColumn'
import { TGridColumns, TabDTO } from '../../../../store/reducers/tabs/interfaces'

export type TGridItemColumns = {
  columnsSettings: TGridColumns
  isExpanded?: boolean
  gridItemId: string
  tabType: TabDTO.Type
  togglePreview: (event: React.MouseEvent) => void
  isHighlightRed?: boolean
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabDTO.Actions
}

const Columns = React.memo((props: TGridItemColumns) => {
  const {
    columnsSettings,
    gridItemId,
    togglePreview,
    tabType,
    isHighlightRed,
    isExpanded,
    actionType,
    isDisabledSelect,
    isSelected
  } = props
  const RenderColumn = columnsValues[tabType]
  const headings = Object.keys(columnSettings[tabType])

  return (
    <TableRowColumns
      className={cn('table__row-columns', {
        'table__row-columns_expanded': isExpanded,
        'highlight-red': isHighlightRed
      })}
      // @ts-ignore
      onClick={togglePreview}
    >
      <SelectColumn
        gridItemId={gridItemId}
        isSelected={isSelected}
        isDisabledSelect={isDisabledSelect}
        actionType={actionType}
      />
      {headings.map((title: string) => {
        const columnProps = columnsSettings[title]

        if (!(columnProps && columnProps.active)) {
          return null
        }

        return (
          <RenderColumn
            {...columnProps}
            key={title}
            className={'table__row-column'}
            columnName={title}
            style={{
              width: columnProps.width,
              minWidth: columnProps.minWidth,
              maxWidth: columnProps.maxWidth
            }}
          />
        )
      })}
    </TableRowColumns>
  )
})

export default Columns
