import * as React from 'react'
import { oc } from 'ts-optchain'
import { DriverDeductionsContext } from '../contexts/DriverDeductionsContext'
import {
  DeductionDTO,
  DeductionStatus,
  DeductionType,
  PaymentMethod,
  VendorDTO
} from '../api/origin/vendor-accounting-service'
import { isNewId, isNewObject } from '../services/DTO'
import { IDriverAccountingTabData } from '../store/reducers/tabs/interfaces'
import { requestDriverDeductions } from '../services/DTO/deduction/fetch'
import { requestVendorAccounting } from '../services/DTO/vendorAccounting/fetch'
import { generateDeduction } from '../services/functions/generate/generateDeduction'
import { isWeeklyDeduction } from '../services/functions/test/isWeeklyDeduction'
import { sortDeductions } from '../services/functions/sort/sortDeductions'
import { useAppSelector } from '../hooks/useAppSelector'
import { selectActiveApplicationTabId } from '../store/select/applicationTabSelect'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { tabActions } from '../store/reducers/tabs'
import { useExpandedItem } from '../hooks/useExpandedItem'
import { useGridItemDriver } from '../hooks/useGridItemDriver'
import { useDeductions } from '../hooks/useDeductions'
import { useVendorAccounting } from '../hooks/useVendorAccounting'
import { EntityType } from '../store/reducers/lists/interfaces'

type Props = {
  children?: any
}

export const DriverDeductionsProvider = React.memo((props: Props) => {
  const { children } = props
  const dispatch = useAppDispatch()
  const { setFetching, isModified, data, modifiedLists, modifyListItems, deleteModifiedListItems } = useExpandedItem()
  const { driver } = useGridItemDriver()
  const driverAccountingTabData = oc(data).driverAccountingTabData({} as IDriverAccountingTabData)
  const tabId = useAppSelector(selectActiveApplicationTabId)
  const [refundEscrowDeduction, setRefundEscrowDeduction] = React.useState<DeductionDTO>(null)
  const [closeEscrowDeduction, setCloseEscrowDeduction] = React.useState<DeductionDTO>(null)
  const [payBackDeduction, setPayBackDeduction] = React.useState<DeductionDTO>(null)
  const [showAccountingPopup, setShowAccountingPopup] = React.useState<boolean>(false)
  const isModifiedMode = isModified
  const vendorAccounting = useVendorAccounting({ id: driverAccountingTabData.vendorAccountingId, modifiedLists })
  const showClosedDeductions = oc(driverAccountingTabData).showClosedDeductions(true)
  const deductionIds = driverAccountingTabData.deductionIds || []
  const deductions = useDeductions({ deductionIds, modifiedLists })
  const currentDeductions = sortDeductions(
    deductions.filter(item => showClosedDeductions || item.status !== DeductionStatus.CLOSED)
  )

  const updateDriverAccountingTabData = (updatedData: Partial<IDriverAccountingTabData>) => {
    dispatch(
      tabActions.mergeExpandedItemData({
        tabId,
        mergeProps: { driverAccountingTabData: { ...driverAccountingTabData, ...updatedData } }
      })
    )
  }

  const requestDriverDeductionsWithSpinner = () => {
    setFetching(true)
    const newDeductionIds = deductionIds.filter(isNewId)

    requestDriverDeductions(driver.id)
      .then(requestedDeductions =>
        updateDriverAccountingTabData({
          deductionIds: (requestedDeductions || []).map(item => item.id).concat(newDeductionIds)
        })
      )
      .catch(() => {})
      .finally(() => setFetching(false))
  }

  const requestVendorAccountingData = async () => {
    return requestVendorAccounting(driver.id)
      .then(requestedVendor => {
        if (requestedVendor) {
          updateDriverAccountingTabData({ vendorAccountingId: requestedVendor.id })
        }

        return requestedVendor
      })
      .catch(() => undefined)
  }

  React.useEffect(() => {
    if (isNewObject(driver)) {
      return
    }

    let tries = 2
    let isExpiredData = false

    const requestAccountingTabData = () =>
      Promise.all([requestVendorAccounting(driver.id), requestDriverDeductions(driver.id)])
        .then(([requestedVendor, requestedDeductions]) => {
          if (!isExpiredData) {
            const newDeductionIds = deductionIds.filter(isNewId)

            updateDriverAccountingTabData({
              vendorAccountingId: oc(requestedVendor).id(),
              deductionIds: (requestedDeductions || []).map(item => item.id).concat(newDeductionIds)
            })

            if (!requestedDeductions.length && tries > 0) {
              tries--

              setTimeout(requestAccountingTabData, 1000)
            }
          }
        })
        .catch(() => {})

    requestAccountingTabData()

    return () => {
      isExpiredData = true
    }
  }, [])

  const toggleShowClosedDeductions = () => {
    updateDriverAccountingTabData({ showClosedDeductions: !showClosedDeductions })
  }

  const createDeduction = (options?: Partial<DeductionDTO>) => {
    const newDeduction = generateDeduction({ ...(options || {}), vendorId: driver.id })

    modifyListItems({ [EntityType.deduction]: [newDeduction] })
    updateDriverAccountingTabData({ deductionIds: deductionIds.concat(newDeduction.id) })
  }

  const updateDeductionField = (_deduction: DeductionDTO) => (prop: keyof DeductionDTO) => (value: any) => {
    const extraOptions = prop === 'type' && value === DeductionType.ESCROW ? { checkType: null, checkNumber: null } : {}

    modifyListItems({ [EntityType.deduction]: [{ ..._deduction, [prop]: value, ...extraOptions }] })

    if (!deductionIds.includes(_deduction.id)) {
      updateDriverAccountingTabData({ deductionIds: deductionIds.concat(_deduction.id) })
    }
  }

  const removeDeductionById = (deductionId: string) => {
    if (isNewId(deductionId)) {
      updateDriverAccountingTabData({ deductionIds: deductionIds.filter(id => id !== deductionId) })
      deleteModifiedListItems({ [EntityType.deduction]: [deductionId] })
    } else {
      const deleteDeduction = deductions.find(item => item.id === deductionId)

      if (deleteDeduction) {
        // @ts-ignore
        modifyListItems({ [EntityType.deduction]: [{ ...deleteDeduction, _delete: true }] })
      }
    }
  }

  const updateVendorAccountingField = (prop: keyof VendorDTO) => (value: any) => {
    if (vendorAccounting) {
      const _updatedVendorAccounting = { ...vendorAccounting, [prop]: value }

      if (prop === 'paymentMethod' && value === PaymentMethod.CHECK) {
        _updatedVendorAccounting.routingCode = undefined
        _updatedVendorAccounting.accountNumber = undefined
        _updatedVendorAccounting.voidedCheck = undefined
      }

      modifyListItems({ [EntityType.vendorAccounting]: [_updatedVendorAccounting] })
    }
  }

  return (
    <DriverDeductionsContext.Provider
      value={{
        driver,
        isModifiedMode,
        vendorAccounting,
        deductions,
        currentDeductions,
        weeklyDeductions: currentDeductions.filter(isWeeklyDeduction),
        notWeeklyDeductions: currentDeductions.filter(deduction => !isWeeklyDeduction(deduction)),
        showClosedDeductions,
        toggleShowClosedDeductions,
        setSpinnerState: setFetching,
        requestDriverDeductionsWithSpinner,
        requestVendorAccounting: requestVendorAccountingData,
        createDeduction,
        updateDeductionField,
        removeDeductionById,
        refundEscrowDeduction,
        setRefundEscrowDeduction,
        closeEscrowDeduction,
        setCloseEscrowDeduction,
        payBackDeduction,
        setPayBackDeduction,
        updateVendorAccountingField,
        showAccountingPopup,
        setShowAccountingPopup
      }}
      children={children}
    />
  )
})
