import { DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { isDDOStatusCancelled } from './isDDOStatusCancelled'

export const isDdoDisabled = (dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO): boolean => {
  if (dispatchDeliveryOrder) {
    return isDDOStatusCancelled(dispatchDeliveryOrder.status)
  }

  return false
}
