import * as React from 'react'
import { StyledStatistics, Cell, CellIcon, CellLabel, CellValue } from './styles'
import { IDateActivityGridKpi } from '../../../../interfaces'
import {
  DailyCountDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO
} from '../../../../../../../api/origin/business-logic'
import { getSchedulerDailyLfdCutOffCounts, getSchedulerKeyPerformanceIndicator } from '../../../../epics'
import { dateService } from '../../../../../../../services/timeService'
import { generateTab } from '../../../../../../../services/functions/generate/generateTab'
import { TabDTO } from '../../../../../../../store/reducers/tabs/interfaces'
// tslint:disable:max-line-length
import { dispatchDeliveryOrderFilterSettings } from '../../../../../../../services/DTO/dispatchDeliveryOrder/filterSettings'
import { DispatchDeliveryOrderColumn } from '../../../../../grid/columnSettings/dispatchDeliveryOrderColumnSettings'
import { createTab } from '../../../../../../../store/reducers/tabs/functions'

const extraLinkStyles = { cursor: 'pointer' }

type Props = {
  dateName: string
  isToday: boolean
  specificDate: string
  kpi: IDateActivityGridKpi
}

export const Statistics = React.memo((props: Props) => {
  const { dateName, specificDate, kpi, isToday } = props
  const [keyPerformanceIndicator, setKeyPerformanceIndicator] = React.useState(null)
  const [lfdCutoffNumbers, setLfdCutoffNumbers] = React.useState<DailyCountDTO>({
    cutoffCount: 0,
    lfdCount: 0
  })

  const createTabWithSettings = (dateType: 'LFD' | 'CutOff') => {
    const doType = dateType === 'LFD' ? DeliveryOrderViewDTO.TypeEnum.IMPORT : DeliveryOrderViewDTO.TypeEnum.EXPORT
    const generatedFilters = generateTab(TabDTO.Type.dispatchDeliveryOrder).uiSettings.filter.map(item => {
      if (item.column === dispatchDeliveryOrderFilterSettings[DispatchDeliveryOrderColumn.Status].name) {
        const arr = item.value

        if (Array.isArray(arr)) {
          return {
            ...item,
            value: arr.filter(_ => _ !== String(DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP))
          }
        } else {
          return item
        }
      }

      return item
    })

    createTab(TabDTO.Type.dispatchDeliveryOrder, {
      label: `${dateType}: ${props.dateName}`,
      uiSettings: {
        filter: [
          ...generatedFilters,
          { column: dispatchDeliveryOrderFilterSettings[DispatchDeliveryOrderColumn.Status].name, value: '' },
          {
            column: dispatchDeliveryOrderFilterSettings[DispatchDeliveryOrderColumn.CutOffLFD].name,
            // if there is any 'Last Free' or 'Cutoff' then it will be set time and activity
            // value: resetTimeToZero(`${props.dateName} ${new Date().getFullYear()}`)
            value: {
              from: dateService.convertStringDate(dateName).startDay,
              to: dateService.convertStringDate(dateName).endDay
            }
          },
          {
            column: dispatchDeliveryOrderFilterSettings[DispatchDeliveryOrderColumn.Type].name,
            // if there is any 'Last Free' or 'Cutoff' then it will be set time and activity
            // value: resetTimeToZero(`${props.dateName} ${new Date().getFullYear()}`)
            value: String(doType)
          }
        ]
      }
    })
  }

  React.useEffect(() => {
    let isExpiredData = false
    const updateCounts = () => {
      if (specificDate) {
        getSchedulerDailyLfdCutOffCounts(dateService.getStringDay(specificDate)).then(counts => {
          if (!isExpiredData) {
            if (typeof counts.lfdCount === 'number' && typeof counts.cutoffCount === 'number') {
              setLfdCutoffNumbers(counts)
            }
          }
        })
      }
    }
    const lfdCutoffNumbersInterval = setInterval(updateCounts, 1000 * 15)

    updateCounts()

    return () => {
      isExpiredData = true

      if (lfdCutoffNumbersInterval) {
        clearInterval(lfdCutoffNumbersInterval)
      }
    }
  }, [specificDate])

  React.useEffect(() => {
    let isExpiredData = false

    if (isToday) {
      getSchedulerKeyPerformanceIndicator().then(data => {
        if (!isExpiredData) {
          setKeyPerformanceIndicator(data)
        }
      })
    }

    return () => {
      isExpiredData = true
    }
  }, [])

  return (
    <StyledStatistics>
      <Cell className={'border'}>
        <CellLabel>Pickup</CellLabel>
        <CellValue>{kpi.pickupCount}</CellValue>
      </Cell>
      <Cell className={'border'}>
        <CellLabel>Delivery</CellLabel>
        <CellValue>{kpi.deliveryCount}</CellValue>
      </Cell>
      <Cell className={'border'}>
        <CellLabel>Return</CellLabel>
        <CellValue>{kpi.returnCount}</CellValue>
      </Cell>
      {isToday && Boolean(keyPerformanceIndicator) && (
        <>
          <Cell className={'border'}>
            <CellLabel>Unsuccessful</CellLabel>
            <CellValue>{keyPerformanceIndicator.unsuccessful}</CellValue>
          </Cell>
          <Cell className={'border'}>
            <CellLabel>In-process</CellLabel>
            <CellValue>{keyPerformanceIndicator.inProcess}</CellValue>
          </Cell>
          <Cell className={'border'}>
            <CellLabel>DEL COMPL</CellLabel>
            <CellValue>{keyPerformanceIndicator.completed}</CellValue>
          </Cell>
        </>
      )}

      <Cell
        className={'text-color-white'}
        style={lfdCutoffNumbers.cutoffCount ? extraLinkStyles : undefined}
        onClick={lfdCutoffNumbers.cutoffCount ? () => createTabWithSettings('CutOff') : undefined}
      >
        <CellIcon className={'mdi mdi-link'} />
        <CellLabel>VIEW CUTOFF</CellLabel>
        <CellValue>{lfdCutoffNumbers.cutoffCount}</CellValue>
      </Cell>
      <Cell
        className={'text-color-white'}
        style={lfdCutoffNumbers.lfdCount ? extraLinkStyles : undefined}
        onClick={lfdCutoffNumbers.lfdCount ? () => createTabWithSettings('LFD') : undefined}
      >
        <CellIcon className={'mdi mdi-link'} />
        <CellLabel>VIEW LFD</CellLabel>
        <CellValue>{lfdCutoffNumbers.lfdCount}</CellValue>
      </Cell>
    </StyledStatistics>
  )
})
