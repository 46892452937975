import { v4 } from 'uuid'
import {
  DateISOString,
  DriverDTO,
  DriverViewDTO,
  LocalDateTimeRangeDTO,
  LocationGridItemDTO,
  LocationDTO,
  LocationViewDTO,
  TransportationActivityViewDTO,
  VendorNameDTO
} from '../../../api/api'
import { createId } from '../../utils'
import { maxCompleteness } from '../../../constants/completeness'

type Props = {
  dispatchDeliveryOrderId: string
  stage: TransportationActivityViewDTO.StageEnum
  options?: {
    groupId?: string
    status?: TransportationActivityViewDTO.StatusEnum
    comboType?: TransportationActivityViewDTO.ComboTypeEnum
    number?: number
    type?: TransportationActivityViewDTO.TypeEnum
    stage?: TransportationActivityViewDTO.StageEnum
    destination?: LocationDTO | LocationViewDTO | LocationGridItemDTO
    destinationId?: string
    vendor?: VendorNameDTO | DriverDTO | DriverViewDTO
    vendorId?: string
    template?: boolean
    startPlannedDateTimeRange?: LocalDateTimeRangeDTO
    startActualDate?: DateISOString
    completionActualDate?: DateISOString
  }
}

export const generateTransportationActivity = (props: Props): TransportationActivityViewDTO => {
  const { dispatchDeliveryOrderId, stage } = props
  const options = props.options || {}

  return {
    id: createId(),
    completeness: maxCompleteness,
    number: 0,
    groupId: v4(),
    stage,
    status: TransportationActivityViewDTO.StatusEnum.NEW,
    type: undefined,
    dispatchDeliveryOrderId,
    startPlannedDateTimeRange: { to: null, from: null },
    startActualDate: undefined,
    completionActualDate: undefined,
    comboType: undefined,
    destinationId: undefined,
    destination: undefined,
    vendorId: undefined,
    vendor: undefined,
    template: false,
    ...options
  }
}
