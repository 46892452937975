import actionCreatorFactory, { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa'
export const qmpAsyncActionCreator: ActionCreatorFactory = actionCreatorFactory('QMP/ASYNC')
import {
  FetchAPI,
  FetchArgs,
  ApiError,
  BsqRateListDTO,
  BuySideQuoteRateDTO,
  ConditionDTO,
  ConditionPropertyValueDTO,
  CustomerQuoteDTO,
  CustomerQuoteListDTO,
  DuplicateError,
  DuplicateErrorList,
  PropertyDTO,
  RuleDTO,
  RuleSearchDTO,
  SellSideQuoteRateDTO,
  SsqRateGroupDTO,
  SsqRateListDTO,
  DateISOString
} from './api'
export interface IBuySideQuoteRateBatchCreateBuySideQuoteRateRequest {
  qMPOverwrite: boolean
  body: Array<BuySideQuoteRateDTO>
  options?: any
}
export type IBuySideQuoteRateBatchCreateBuySideQuoteRateSuccess = BsqRateListDTO
export type IBuySideQuoteRateBatchCreateBuySideQuoteRateError = any
export const buySideQuoteRateBatchCreateBuySideQuoteRateAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateBatchCreateBuySideQuoteRateRequest,
  IBuySideQuoteRateBatchCreateBuySideQuoteRateSuccess,
  IBuySideQuoteRateBatchCreateBuySideQuoteRateError
>('BUYSIDEQUOTERATE_BATCHCREATEBUYSIDEQUOTERATE')

export interface IBuySideQuoteRateCreateBuySideQuoteRateRequest {
  qMPOverwrite: boolean
  body: BuySideQuoteRateDTO
  options?: any
}
export type IBuySideQuoteRateCreateBuySideQuoteRateSuccess = BuySideQuoteRateDTO
export type IBuySideQuoteRateCreateBuySideQuoteRateError = any
export const buySideQuoteRateCreateBuySideQuoteRateAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateCreateBuySideQuoteRateRequest,
  IBuySideQuoteRateCreateBuySideQuoteRateSuccess,
  IBuySideQuoteRateCreateBuySideQuoteRateError
>('BUYSIDEQUOTERATE_CREATEBUYSIDEQUOTERATE')

export interface IBuySideQuoteRateExpireBuySideQuoteRequest {
  id: string
  options?: any
}
export type IBuySideQuoteRateExpireBuySideQuoteSuccess = BuySideQuoteRateDTO
export type IBuySideQuoteRateExpireBuySideQuoteError = any
export const buySideQuoteRateExpireBuySideQuoteAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateExpireBuySideQuoteRequest,
  IBuySideQuoteRateExpireBuySideQuoteSuccess,
  IBuySideQuoteRateExpireBuySideQuoteError
>('BUYSIDEQUOTERATE_EXPIREBUYSIDEQUOTE')

export interface IBuySideQuoteRateGetBuySideQuoteRateRequest {
  id: string
  options?: any
}
export type IBuySideQuoteRateGetBuySideQuoteRateSuccess = BuySideQuoteRateDTO
export type IBuySideQuoteRateGetBuySideQuoteRateError = any
export const buySideQuoteRateGetBuySideQuoteRateAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateGetBuySideQuoteRateRequest,
  IBuySideQuoteRateGetBuySideQuoteRateSuccess,
  IBuySideQuoteRateGetBuySideQuoteRateError
>('BUYSIDEQUOTERATE_GETBUYSIDEQUOTERATE')

export interface IBuySideQuoteRateGetBuySideQuoteRatesRequest {
  filter?: string
  sort?: string
  limit?: number
  options?: any
}
export type IBuySideQuoteRateGetBuySideQuoteRatesSuccess = BuySideQuoteRateDTO[]
export type IBuySideQuoteRateGetBuySideQuoteRatesError = any
export const buySideQuoteRateGetBuySideQuoteRatesAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateGetBuySideQuoteRatesRequest,
  IBuySideQuoteRateGetBuySideQuoteRatesSuccess,
  IBuySideQuoteRateGetBuySideQuoteRatesError
>('BUYSIDEQUOTERATE_GETBUYSIDEQUOTERATES')

export interface ICustomerQuoteBatchCreateCustomerQuoteRequest {
  qMPOverwrite: boolean
  body: Array<CustomerQuoteDTO>
  options?: any
}
export type ICustomerQuoteBatchCreateCustomerQuoteSuccess = CustomerQuoteListDTO
export type ICustomerQuoteBatchCreateCustomerQuoteError = any
export const customerQuoteBatchCreateCustomerQuoteAction = qmpAsyncActionCreator.async<
  ICustomerQuoteBatchCreateCustomerQuoteRequest,
  ICustomerQuoteBatchCreateCustomerQuoteSuccess,
  ICustomerQuoteBatchCreateCustomerQuoteError
>('CUSTOMERQUOTE_BATCHCREATECUSTOMERQUOTE')

export interface ICustomerQuoteCreateCustomerQuoteRequest {
  qMPOverwrite: boolean
  body: CustomerQuoteDTO
  options?: any
}
export type ICustomerQuoteCreateCustomerQuoteSuccess = CustomerQuoteDTO
export type ICustomerQuoteCreateCustomerQuoteError = any
export const customerQuoteCreateCustomerQuoteAction = qmpAsyncActionCreator.async<
  ICustomerQuoteCreateCustomerQuoteRequest,
  ICustomerQuoteCreateCustomerQuoteSuccess,
  ICustomerQuoteCreateCustomerQuoteError
>('CUSTOMERQUOTE_CREATECUSTOMERQUOTE')

export interface ICustomerQuoteExpireCustomerQuotesRequest {
  id: string
  options?: any
}
export type ICustomerQuoteExpireCustomerQuotesSuccess = CustomerQuoteDTO
export type ICustomerQuoteExpireCustomerQuotesError = any
export const customerQuoteExpireCustomerQuotesAction = qmpAsyncActionCreator.async<
  ICustomerQuoteExpireCustomerQuotesRequest,
  ICustomerQuoteExpireCustomerQuotesSuccess,
  ICustomerQuoteExpireCustomerQuotesError
>('CUSTOMERQUOTE_EXPIRECUSTOMERQUOTES')

export interface ICustomerQuoteGetCustomerQuoteRequest {
  id: string
  options?: any
}
export type ICustomerQuoteGetCustomerQuoteSuccess = CustomerQuoteDTO
export type ICustomerQuoteGetCustomerQuoteError = any
export const customerQuoteGetCustomerQuoteAction = qmpAsyncActionCreator.async<
  ICustomerQuoteGetCustomerQuoteRequest,
  ICustomerQuoteGetCustomerQuoteSuccess,
  ICustomerQuoteGetCustomerQuoteError
>('CUSTOMERQUOTE_GETCUSTOMERQUOTE')

export interface ICustomerQuoteGetCustomerQuotesRequest {
  filter?: string
  sort?: string
  limit?: number
  options?: any
}
export type ICustomerQuoteGetCustomerQuotesSuccess = CustomerQuoteDTO[]
export type ICustomerQuoteGetCustomerQuotesError = any
export const customerQuoteGetCustomerQuotesAction = qmpAsyncActionCreator.async<
  ICustomerQuoteGetCustomerQuotesRequest,
  ICustomerQuoteGetCustomerQuotesSuccess,
  ICustomerQuoteGetCustomerQuotesError
>('CUSTOMERQUOTE_GETCUSTOMERQUOTES')

export interface ICustomerQuoteGetRatesOfCustomerQuoteRequest {
  id: string
  options?: any
}
export type ICustomerQuoteGetRatesOfCustomerQuoteSuccess = SellSideQuoteRateDTO[]
export type ICustomerQuoteGetRatesOfCustomerQuoteError = any
export const customerQuoteGetRatesOfCustomerQuoteAction = qmpAsyncActionCreator.async<
  ICustomerQuoteGetRatesOfCustomerQuoteRequest,
  ICustomerQuoteGetRatesOfCustomerQuoteSuccess,
  ICustomerQuoteGetRatesOfCustomerQuoteError
>('CUSTOMERQUOTE_GETRATESOFCUSTOMERQUOTE')

export interface IIndexGetRequest {
  options?: any
}
export type IIndexGetSuccess = any
export type IIndexGetError = any
export const indexGetAction = qmpAsyncActionCreator.async<IIndexGetRequest, IIndexGetSuccess, IIndexGetError>(
  'INDEX_GET'
)

export interface IPropertyGetPropertiesRequest {
  options?: any
}
export type IPropertyGetPropertiesSuccess = PropertyDTO[]
export type IPropertyGetPropertiesError = any
export const propertyGetPropertiesAction = qmpAsyncActionCreator.async<
  IPropertyGetPropertiesRequest,
  IPropertyGetPropertiesSuccess,
  IPropertyGetPropertiesError
>('PROPERTY_GETPROPERTIES')

export interface IRuleGetRuleRequest {
  id: string
  options?: any
}
export type IRuleGetRuleSuccess = RuleDTO
export type IRuleGetRuleError = any
export const ruleGetRuleAction = qmpAsyncActionCreator.async<
  IRuleGetRuleRequest,
  IRuleGetRuleSuccess,
  IRuleGetRuleError
>('RULE_GETRULE')

export interface IRuleGetRulesRequest {
  filter?: string
  options?: any
}
export type IRuleGetRulesSuccess = RuleSearchDTO[]
export type IRuleGetRulesError = any
export const ruleGetRulesAction = qmpAsyncActionCreator.async<
  IRuleGetRulesRequest,
  IRuleGetRulesSuccess,
  IRuleGetRulesError
>('RULE_GETRULES')

export interface ISellSideQuoteRateBatchCreateSellSideQuoteRateRequest {
  qMPOverwrite: boolean
  body: Array<SellSideQuoteRateDTO>
  options?: any
}
export type ISellSideQuoteRateBatchCreateSellSideQuoteRateSuccess = SsqRateListDTO
export type ISellSideQuoteRateBatchCreateSellSideQuoteRateError = any
export const sellSideQuoteRateBatchCreateSellSideQuoteRateAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateBatchCreateSellSideQuoteRateRequest,
  ISellSideQuoteRateBatchCreateSellSideQuoteRateSuccess,
  ISellSideQuoteRateBatchCreateSellSideQuoteRateError
>('SELLSIDEQUOTERATE_BATCHCREATESELLSIDEQUOTERATE')

export interface ISellSideQuoteRateCreateSellSideQuoteRateRequest {
  qMPOverwrite: boolean
  body: SellSideQuoteRateDTO
  options?: any
}
export type ISellSideQuoteRateCreateSellSideQuoteRateSuccess = SellSideQuoteRateDTO
export type ISellSideQuoteRateCreateSellSideQuoteRateError = any
export const sellSideQuoteRateCreateSellSideQuoteRateAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateCreateSellSideQuoteRateRequest,
  ISellSideQuoteRateCreateSellSideQuoteRateSuccess,
  ISellSideQuoteRateCreateSellSideQuoteRateError
>('SELLSIDEQUOTERATE_CREATESELLSIDEQUOTERATE')

export interface ISellSideQuoteRateExpireSellSideQuoteRequest {
  id: string
  options?: any
}
export type ISellSideQuoteRateExpireSellSideQuoteSuccess = SellSideQuoteRateDTO
export type ISellSideQuoteRateExpireSellSideQuoteError = any
export const sellSideQuoteRateExpireSellSideQuoteAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateExpireSellSideQuoteRequest,
  ISellSideQuoteRateExpireSellSideQuoteSuccess,
  ISellSideQuoteRateExpireSellSideQuoteError
>('SELLSIDEQUOTERATE_EXPIRESELLSIDEQUOTE')

export interface ISellSideQuoteRateGetSellSideQuoteRateRequest {
  id: string
  options?: any
}
export type ISellSideQuoteRateGetSellSideQuoteRateSuccess = SellSideQuoteRateDTO
export type ISellSideQuoteRateGetSellSideQuoteRateError = any
export const sellSideQuoteRateGetSellSideQuoteRateAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateGetSellSideQuoteRateRequest,
  ISellSideQuoteRateGetSellSideQuoteRateSuccess,
  ISellSideQuoteRateGetSellSideQuoteRateError
>('SELLSIDEQUOTERATE_GETSELLSIDEQUOTERATE')

export interface ISellSideQuoteRateGetSellSideQuoteRateGroupsRequest {
  filter?: string
  sort?: string
  limit?: number
  options?: any
}
export type ISellSideQuoteRateGetSellSideQuoteRateGroupsSuccess = SsqRateGroupDTO[]
export type ISellSideQuoteRateGetSellSideQuoteRateGroupsError = any
export const sellSideQuoteRateGetSellSideQuoteRateGroupsAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateGetSellSideQuoteRateGroupsRequest,
  ISellSideQuoteRateGetSellSideQuoteRateGroupsSuccess,
  ISellSideQuoteRateGetSellSideQuoteRateGroupsError
>('SELLSIDEQUOTERATE_GETSELLSIDEQUOTERATEGROUPS')

export interface ISellSideQuoteRateGetSellSideQuoteRatesRequest {
  filter?: string
  sort?: string
  limit?: number
  options?: any
}
export type ISellSideQuoteRateGetSellSideQuoteRatesSuccess = SellSideQuoteRateDTO[]
export type ISellSideQuoteRateGetSellSideQuoteRatesError = any
export const sellSideQuoteRateGetSellSideQuoteRatesAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateGetSellSideQuoteRatesRequest,
  ISellSideQuoteRateGetSellSideQuoteRatesSuccess,
  ISellSideQuoteRateGetSellSideQuoteRatesError
>('SELLSIDEQUOTERATE_GETSELLSIDEQUOTERATES')
