import * as R from 'remeda'
import { oc } from 'ts-optchain'
import {
  OngoingActivityDTO,
  OngoingActivityGroupDTO,
  TransportationActivityViewDTO
} from '../../../../api/origin/business-logic'
import { groupOngoingActivitiesByDate } from './groupOngoingActivitiesByDate'
import { dateService } from '../../../../services/timeService'
import { IDateActivityGridKpi } from '../interfaces'

interface ITimeGroup {
  [time: string]: OngoingActivityGroupDTO[]
}

interface IOngoingActivityDateGroups {
  [date: string]: {
    timeGroup: ITimeGroup
    kpi: IDateActivityGridKpi
    headersNumber: number
    rowsNumber: number
  }
}

interface ISortedByDateAndTimeOngoingActivityGroups {
  missedActivities: OngoingActivityGroupDTO[]
  ongoingActivities: IOngoingActivityDateGroups
  activityCount: number
}

const sortTime = (
  ongoingActivityList: OngoingActivityGroupDTO[],
  options: {
    separateMissedGroup: boolean
    countHeaders: boolean
    countRows: boolean
    showCounters: boolean
  }
): ISortedByDateAndTimeOngoingActivityGroups => {
  const { separateMissedGroup, countHeaders, countRows, showCounters } = options
  let activityCount: number = 0

  const { ongoingActivityDateGroups, missedActivities } = groupOngoingActivitiesByDate(
    ongoingActivityList,
    separateMissedGroup
  )

  const ongoingActivities = Object.keys(ongoingActivityDateGroups).reduce((el, groupDate) => {
    const kpi: IDateActivityGridKpi = {
      pickupCount: 0,
      deliveryCount: 0,
      returnCount: 0
    }

    const getPlannedDate = (stageActivity: OngoingActivityDTO) => {
      return oc(stageActivity).plannedDate.from() || oc(stageActivity).plannedDate.to()
    }
    const activityIsNotCompleted = (stageActivity: OngoingActivityDTO): boolean => {
      return oc(stageActivity).status() !== OngoingActivityDTO.StatusEnum.COMPLETED
    }

    let rowsNumber: number = 0
    const driverList: string[] = []

    const timeGroup = ongoingActivityDateGroups[groupDate].reduce((dateTime, activity) => {
      activityCount++
      const time = activity.activityGroupDate.match(/T([0-9][0-9]):/)[1]

      if (!dateTime[time]) {
        dateTime[time] = []
      }

      if (countHeaders) {
        driverList.push(activity.activityGroupDriver)
      }

      if (countRows) {
        rowsNumber++
      }

      dateTime[time].push(activity)

      if (showCounters) {
        const pickupPlannedDate = getPlannedDate(activity.pickupActivity)
        const pickupIsNotCompleted = activityIsNotCompleted(activity.pickupActivity)
        if (pickupPlannedDate && pickupIsNotCompleted && dateService.isTheSameDay(groupDate, pickupPlannedDate)) {
          kpi.pickupCount++
        }

        const deliveryPlannedDate = getPlannedDate(activity.deliveryActivity)
        const deliveryIsNotCompleted = activityIsNotCompleted(activity.deliveryActivity)
        if (deliveryPlannedDate && deliveryIsNotCompleted && dateService.isTheSameDay(groupDate, deliveryPlannedDate)) {
          kpi.deliveryCount++
        }

        const returnPlannedDate = getPlannedDate(activity.returnActivity)
        const returnIsNotCompleted = activityIsNotCompleted(activity.returnActivity)
        if (returnPlannedDate && returnIsNotCompleted && dateService.isTheSameDay(groupDate, returnPlannedDate)) {
          kpi.returnCount++
        }
      }

      return dateTime
    }, {})

    return {
      ...el,
      [dateService.makeLabel(groupDate, { long: true, fullYear: true })]: {
        kpi,
        timeGroup,
        headersNumber: R.uniq(driverList).length,
        rowsNumber
      }
    }
  }, {})
  return {
    missedActivities,
    ongoingActivities,
    activityCount
  }
}

export type TCalcPhase = {
  stage: TransportationActivityViewDTO.StageEnum
  activity: OngoingActivityDTO
  extraActivity: OngoingActivityDTO | undefined
  isPending: boolean
  isActive: boolean
  isAssigned: boolean
}

const mapActivities = (
  activityGroup: OngoingActivityGroupDTO
): {
  phases: Record<TransportationActivityViewDTO.StageEnum, TCalcPhase>
  highlight: OngoingActivityGroupDTO.HighlightEnum
} => {
  const { highlight, extraActivity } = activityGroup

  return {
    phases: {
      [TransportationActivityViewDTO.StageEnum.PICKUP]: {
        stage: TransportationActivityViewDTO.StageEnum.PICKUP,
        activity: oc(activityGroup).pickupActivity({} as OngoingActivityDTO),
        extraActivity:
          extraActivity && extraActivity.stage === TransportationActivityViewDTO.StageEnum.PICKUP && extraActivity,
        isPending: highlight === OngoingActivityGroupDTO.HighlightEnum.PENDINGPICKUP,
        isActive: highlight === OngoingActivityGroupDTO.HighlightEnum.ACTIVEPICKUP,
        isAssigned: highlight === OngoingActivityGroupDTO.HighlightEnum.ASSIGNEDPICKUP
      },
      [TransportationActivityViewDTO.StageEnum.DELIVERY]: activityGroup.deliveryActivity
        ? {
            stage: TransportationActivityViewDTO.StageEnum.DELIVERY,
            activity: oc(activityGroup).deliveryActivity({} as OngoingActivityDTO),
            extraActivity:
              extraActivity &&
              extraActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
              extraActivity,
            isPending: highlight === OngoingActivityGroupDTO.HighlightEnum.PENDINGDELIVERY,
            isActive: highlight === OngoingActivityGroupDTO.HighlightEnum.ACTIVEDELIVERY,
            isAssigned: highlight === OngoingActivityGroupDTO.HighlightEnum.ASSIGNEDDELIVERY
          }
        : undefined,
      [TransportationActivityViewDTO.StageEnum.RETURN]: activityGroup.returnActivity
        ? {
            stage: TransportationActivityViewDTO.StageEnum.RETURN,
            activity: oc(activityGroup).returnActivity({} as OngoingActivityDTO),
            extraActivity:
              extraActivity && extraActivity.stage === TransportationActivityViewDTO.StageEnum.RETURN && extraActivity,
            isPending: highlight === OngoingActivityGroupDTO.HighlightEnum.PENDINGRETURN,
            isActive: highlight === OngoingActivityGroupDTO.HighlightEnum.ACTIVERETURN,
            isAssigned: highlight === OngoingActivityGroupDTO.HighlightEnum.ASSIGNEDRETURN
          }
        : undefined
    },
    highlight
  }
}

export { sortTime, mapActivities }
