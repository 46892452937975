import * as React from 'react'
import { oc } from 'ts-optchain'
import { StickiedLabel } from '../../styledComponents'
import { OngoingActivityGroupDTO } from '../../../../../../api/origin/business-logic'
import { OngoingActivityRow } from './OngoingActivityRow/OngoingActivityRow'
import { IDateActivityGrid, SchedulerSortBy } from '../../../interfaces'
import { DateGroupBody, DateGroupHeader, Group as StyledGroup, GroupBlock, GroupHeader } from './styles'
import { Statistics } from './Statistics'
import { sortOngoingActivityGroups, TSortOngoingActivityGroups } from '../../../functions/sortOngoingActivityGroups'
import { dateService } from '../../../../../../services/timeService'
import { useAppSelector } from '../../../../../../hooks/useAppSelector'
import { selectUndefined } from '../../../../../../store/select/undefinedSelect'
import { selectDrivers } from '../../../../../../store/select/driverSelect'

const titleWithoutDataMapping: Record<SchedulerSortBy, string> = {
  [SchedulerSortBy.driverAllStages]: 'DDO: No Driver Assigned',
  [SchedulerSortBy.deliveryStageDriver]: 'DDO: No Driver Assigned on Delivery Stage',
  [SchedulerSortBy.deliveryLocationCity]: 'Other',
  [SchedulerSortBy.date]: 'Other',
  [SchedulerSortBy.driverActivities]: 'Other'
}

type OwnProps = {
  specificDate: string
  date: IDateActivityGrid
  dateName: string
  sortBy: SchedulerSortBy
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Group = React.memo((props: Props) => {
  const { specificDate, date, dateName, sortBy } = props
  const driverMapping = useAppSelector(sortBy === SchedulerSortBy.deliveryStageDriver ? selectDrivers : selectUndefined)
  const isToday =
    dateName === dateService.makeLabel(dateService.createStringDate.startDay, { long: true, fullYear: true })

  let sortedOngoingActivityGroups: TSortOngoingActivityGroups = {
    sortedListWithTargetData: {},
    sortedListWithoutTargetData: []
  }

  switch (sortBy) {
    case SchedulerSortBy.deliveryStageDriver:
    case SchedulerSortBy.deliveryLocationCity:
    case SchedulerSortBy.driverAllStages:
      const sortedData = sortOngoingActivityGroups(sortBy, driverMapping)(
        Object.values(date.timeGroup).reduce((acc, curr) => {
          acc.push(...curr)
          return acc
        }, [])
      )

      sortedOngoingActivityGroups = sortedData
      break
    case SchedulerSortBy.date:
      sortedOngoingActivityGroups = {
        sortedListWithTargetData: date.timeGroup,
        sortedListWithoutTargetData: []
      }
      break
    default:
  }

  const renderRow = (ongoingActivity: OngoingActivityGroupDTO) => (
    <OngoingActivityRow key={ongoingActivity.id} activityGroup={ongoingActivity} />
  )

  return (
    <StyledGroup>
      <DateGroupHeader style={isToday ? extraCurrentDateHeaderStyles : extraDateHeaderStyles}>
        <StickiedLabel className={'left'}>{dateName}</StickiedLabel>
        {sortBy !== SchedulerSortBy.driverAllStages && (
          <StickiedLabel className={'right'}>
            <Statistics dateName={dateName} specificDate={specificDate} kpi={date.kpi} isToday={isToday} />
          </StickiedLabel>
        )}
      </DateGroupHeader>
      <DateGroupBody>
        {Object.keys(sortedOngoingActivityGroups.sortedListWithTargetData)
          .sort((a, b) => {
            switch (sortBy) {
              case SchedulerSortBy.deliveryStageDriver:
              case SchedulerSortBy.deliveryLocationCity:
              case SchedulerSortBy.driverAllStages:
                return a.localeCompare(b)
              case SchedulerSortBy.date:
              default:
                return parseInt(a, 10) - parseInt(b, 10)
            }
          })
          .map(label => (
            <GroupBlock key={label}>
              <GroupHeader>
                <StickiedLabel className={'left'}>
                  {label + (sortBy === SchedulerSortBy.date ? ':00' : '')}
                </StickiedLabel>
              </GroupHeader>
              {sortedOngoingActivityGroups.sortedListWithTargetData[label].map(renderRow)}
            </GroupBlock>
          ))}
        {Boolean(oc(sortedOngoingActivityGroups.sortedListWithoutTargetData).length()) && (
          <GroupBlock>
            <GroupHeader>
              <StickiedLabel className={'left'}>{titleWithoutDataMapping[sortBy] || 'Other'}</StickiedLabel>
            </GroupHeader>
            {sortedOngoingActivityGroups.sortedListWithoutTargetData.map(renderRow)}
          </GroupBlock>
        )}
      </DateGroupBody>
    </StyledGroup>
  )
})

const extraDateHeaderStyles = { justifyContent: 'space-between' }
const extraCurrentDateHeaderStyles = { ...extraDateHeaderStyles, backgroundColor: '#4555d0' }
