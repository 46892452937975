// tslint:disable
/**
 * Vendor-accounting-service Express API with Swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url'

import { Configuration } from './configuration'

const BASE_PATH = 'https://localhost'.replace(/\/+$/, '')

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|'
}

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string
  options: any
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration

  constructor(configuration?: Configuration, protected basePath: string = configuration.basePath) {
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError'
  constructor(public field: string, msg?: string) {
    super(msg)
  }
}

/**
 *
 * @export
 * @enum {string}
 */
export enum CheckType {
  ACH = <any>'ACH',
  CHECK = <any>'CHECK',
  AMEX = <any>'AMEX',
  MASTER = <any>'MASTER',
  VISA = <any>'VISA',
  CASH = <any>'CASH',
  ECHECK = <any>'E_CHECK',
  WIRE = <any>'WIRE'
}

/**
 *
 * @export
 * @interface CreateDeductionDTO
 */
export interface CreateDeductionDTO {
  /**
   *
   * @type {string}
   * @memberof CreateDeductionDTO
   */
  checkNumber?: string
  /**
   *
   * @type {CheckType}
   * @memberof CreateDeductionDTO
   */
  checkType?: CheckType
  /**
   *
   * @type {number}
   * @memberof CreateDeductionDTO
   */
  deductionAmount: number
  /**
   *
   * @type {string}
   * @memberof CreateDeductionDTO
   */
  description?: string
  /**
   *
   * @type {number}
   * @memberof CreateDeductionDTO
   */
  interest?: number
  /**
   *
   * @type {number}
   * @memberof CreateDeductionDTO
   */
  principle?: number
  /**
   *
   * @type {DateISOString}
   * @memberof CreateDeductionDTO
   */
  startDate?: DateISOString
  /**
   *
   * @type {DeductionType}
   * @memberof CreateDeductionDTO
   */
  type: DeductionType
  /**
   *
   * @type {string}
   * @memberof CreateDeductionDTO
   */
  vendorId: string
}

/**
 *
 * @export
 * @interface CreateDeductionTransactionDTO
 */
export interface CreateDeductionTransactionDTO {
  /**
   *
   * @type {number}
   * @memberof CreateDeductionTransactionDTO
   */
  amount: number
  /**
   *
   * @type {string}
   * @memberof CreateDeductionTransactionDTO
   */
  checkNumber?: string
  /**
   *
   * @type {CheckType}
   * @memberof CreateDeductionTransactionDTO
   */
  checkType?: CheckType
  /**
   *
   * @type {DateISOString}
   * @memberof CreateDeductionTransactionDTO
   */
  date: DateISOString
  /**
   *
   * @type {string}
   * @memberof CreateDeductionTransactionDTO
   */
  deductionId: string
  /**
   *
   * @type {string}
   * @memberof CreateDeductionTransactionDTO
   */
  description?: string
}

/**
 *
 * @export
 * @interface CreateVendorDeductionTransactionsDTO
 */
export interface CreateVendorDeductionTransactionsDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof CreateVendorDeductionTransactionsDTO
   */
  date: DateISOString
}

/**
 *
 * @export
 * @interface DeductionDTO
 */
export interface DeductionDTO {
  /**
   *
   * @type {string}
   * @memberof DeductionDTO
   */
  checkNumber?: string
  /**
   *
   * @type {CheckType}
   * @memberof DeductionDTO
   */
  checkType?: CheckType
  /**
   *
   * @type {DateISOString}
   * @memberof DeductionDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeductionDTO
   */
  createdBy?: string
  /**
   *
   * @type {number}
   * @memberof DeductionDTO
   */
  currentBalance?: number
  /**
   *
   * @type {number}
   * @memberof DeductionDTO
   */
  deductionAmount?: number
  /**
   *
   * @type {string}
   * @memberof DeductionDTO
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof DeductionDTO
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof DeductionDTO
   */
  interest?: number
  /**
   *
   * @type {number}
   * @memberof DeductionDTO
   */
  principle?: number
  /**
   *
   * @type {DateISOString}
   * @memberof DeductionDTO
   */
  startDate?: DateISOString
  /**
   *
   * @type {DeductionStatus}
   * @memberof DeductionDTO
   */
  status: DeductionStatus
  /**
   *
   * @type {number}
   * @memberof DeductionDTO
   */
  totalAmount?: number
  /**
   *
   * @type {DeductionType}
   * @memberof DeductionDTO
   */
  type: DeductionType
  /**
   *
   * @type {DateISOString}
   * @memberof DeductionDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeductionDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof DeductionDTO
   */
  vendorId: string
}

/**
 *
 * @export
 * @enum {string}
 */
export enum DeductionStatus {
  NEW = <any>'NEW',
  READY = <any>'READY',
  ACTIVE = <any>'ACTIVE',
  COMPLETED = <any>'COMPLETED',
  CLOSED = <any>'CLOSED'
}

/**
 *
 * @export
 * @interface DeductionTransactionDTO
 */
export interface DeductionTransactionDTO {
  /**
   *
   * @type {number}
   * @memberof DeductionTransactionDTO
   */
  amount: number
  /**
   *
   * @type {string}
   * @memberof DeductionTransactionDTO
   */
  checkNumber?: string
  /**
   *
   * @type {CheckType}
   * @memberof DeductionTransactionDTO
   */
  checkType?: CheckType
  /**
   *
   * @type {any}
   * @memberof DeductionTransactionDTO
   */
  createdAt?: any
  /**
   *
   * @type {string}
   * @memberof DeductionTransactionDTO
   */
  createdBy?: string
  /**
   *
   * @type {any}
   * @memberof DeductionTransactionDTO
   */
  date?: any
  /**
   *
   * @type {string}
   * @memberof DeductionTransactionDTO
   */
  deductionId: string
  /**
   *
   * @type {string}
   * @memberof DeductionTransactionDTO
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof DeductionTransactionDTO
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof DeductionTransactionDTO
   */
  paid?: boolean
  /**
   *
   * @type {DateISOString}
   * @memberof DeductionTransactionDTO
   */
  sendDate?: DateISOString
}

/**
 *
 * @export
 * @enum {string}
 */
export enum DeductionType {
  LOAN = <any>'LOAN',
  ESCROW = <any>'ESCROW',
  ELD = <any>'ELD',
  PARKING = <any>'PARKING',
  WCINSURANCE = <any>'WC_INSURANCE',
  TRUCKREGISTRATION = <any>'TRUCK_REGISTRATION',
  PDINSURANCE = <any>'PD_INSURANCE'
}

/**
 *
 * @export
 * @interface DocumentFileDTO
 */
export interface DocumentFileDTO {
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  contentType: string
  /**
   *
   * @type {DateISOString}
   * @memberof DocumentFileDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof DocumentFileDTO
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  thumb?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DocumentFileDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  url: string
}

/**
 *
 * @export
 * @interface DownloadDTO
 */
export interface DownloadDTO {
  /**
   *
   * @type {string}
   * @memberof DownloadDTO
   */
  endDate: string
  /**
   *
   * @type {string}
   * @memberof DownloadDTO
   */
  startDate: string
}

/**
 *
 * @export
 * @enum {string}
 */
export enum DriverType {
  COMPANY = <any>'COMPANY',
  OWNEROPERATOR = <any>'OWNER_OPERATOR',
  VENDOR = <any>'VENDOR',
  OUTSOURCE = <any>'OUTSOURCE'
}

/**
 *
 * @export
 * @interface FileDTO
 */
export interface FileDTO {
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  contentType: string
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof FileDTO
   */
  size: number
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  url: string
}

/**
 *
 * @export
 * @enum {string}
 */
export enum Frequency {
  DAILY = <any>'DAILY',
  WEEKLY = <any>'WEEKLY'
}

/**
 *
 * @export
 * @enum {string}
 */
export enum InactiveReason {
  CANDIDATE = <any>'CANDIDATE',
  CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
  CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
  EXPIREDCREDENTIALS = <any>'EXPIRED_CREDENTIALS',
  DRUGTESTNOTCOMPLETED = <any>'DRUG_TEST_NOT_COMPLETED',
  DOTSTOP = <any>'DOT_STOP',
  BIINSPECTIONNOTCOMPLETED = <any>'BI_INSPECTION_NOT_COMPLETED',
  QUARTERLYINSPECTIONNOTCOMPLETED = <any>'QUARTERLY_INSPECTION_NOT_COMPLETED',
  OUTOFDRIVINGHOURS = <any>'OUT_OF_DRIVING_HOURS',
  PAPERLOGNOTCOMPLETED = <any>'PAPER_LOG_NOT_COMPLETED',
  POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
  OFFDUTY = <any>'OFF_DUTY',
  TERMINATED = <any>'TERMINATED'
}

/**
 *
 * @export
 * @interface PaymentCheckDTO
 */
export interface PaymentCheckDTO {
  /**
   *
   * @type {number}
   * @memberof PaymentCheckDTO
   */
  amount: number
  /**
   *
   * @type {any}
   * @memberof PaymentCheckDTO
   */
  date: any
  /**
   *
   * @type {string}
   * @memberof PaymentCheckDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckDTO
   */
  number: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckDTO
   */
  reportUrl?: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckDTO
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckDTO
   */
  vendorId: string
}

/**
 *
 * @export
 * @interface PaymentCheckReportDTO
 */
export interface PaymentCheckReportDTO {
  /**
   *
   * @type {string}
   * @memberof PaymentCheckReportDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckReportDTO
   */
  reportUrl?: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckReportDTO
   */
  status: string
}

/**
 *
 * @export
 * @interface PaymentCheckReportParametersDTO
 */
export interface PaymentCheckReportParametersDTO {
  /**
   *
   * @type {string}
   * @memberof PaymentCheckReportParametersDTO
   */
  dateFrom: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckReportParametersDTO
   */
  dateTill: string
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentMethod {
  ACH = <any>'ACH',
  CHECK = <any>'CHECK'
}

/**
 *
 * @export
 * @enum {string}
 */
export enum UnavailabilityReason {
  ASSIGNED = <any>'ASSIGNED',
  INUSE = <any>'IN_USE',
  INACTIVE = <any>'INACTIVE'
}

/**
 *
 * @export
 * @interface UpdateDeductionDTO
 */
export interface UpdateDeductionDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateDeductionDTO
   */
  checkNumber?: string
  /**
   *
   * @type {CheckType}
   * @memberof UpdateDeductionDTO
   */
  checkType?: CheckType
  /**
   *
   * @type {number}
   * @memberof UpdateDeductionDTO
   */
  deductionAmount?: number
  /**
   *
   * @type {string}
   * @memberof UpdateDeductionDTO
   */
  description?: string
  /**
   *
   * @type {number}
   * @memberof UpdateDeductionDTO
   */
  interest?: number
  /**
   *
   * @type {number}
   * @memberof UpdateDeductionDTO
   */
  principle?: number
  /**
   *
   * @type {DateISOString}
   * @memberof UpdateDeductionDTO
   */
  startDate?: DateISOString
  /**
   *
   * @type {DeductionType}
   * @memberof UpdateDeductionDTO
   */
  type: DeductionType
  /**
   *
   * @type {string}
   * @memberof UpdateDeductionDTO
   */
  vendorId: string
}

/**
 *
 * @export
 * @interface UpdateDocumentFileDTO
 */
export interface UpdateDocumentFileDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentFileDTO
   */
  contentType: string
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentFileDTO
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof UpdateDocumentFileDTO
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentFileDTO
   */
  thumb?: string
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentFileDTO
   */
  url: string
}

/**
 *
 * @export
 * @interface UpdateVendorDTO
 */
export interface UpdateVendorDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateVendorDTO
   */
  accountNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateVendorDTO
   */
  active?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateVendorDTO
   */
  authUserId?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateVendorDTO
   */
  availability?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateVendorDTO
   */
  companyName?: string
  /**
   *
   * @type {DriverType}
   * @memberof UpdateVendorDTO
   */
  driverType?: DriverType
  /**
   *
   * @type {Frequency}
   * @memberof UpdateVendorDTO
   */
  frequency?: Frequency
  /**
   *
   * @type {InactiveReason}
   * @memberof UpdateVendorDTO
   */
  inactiveReason?: InactiveReason
  /**
   *
   * @type {string}
   * @memberof UpdateVendorDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof UpdateVendorDTO
   */
  parentId?: string
  /**
   *
   * @type {PaymentMethod}
   * @memberof UpdateVendorDTO
   */
  paymentMethod?: PaymentMethod
  /**
   *
   * @type {string}
   * @memberof UpdateVendorDTO
   */
  routingCode?: string
  /**
   *
   * @type {UnavailabilityReason}
   * @memberof UpdateVendorDTO
   */
  unavailabilityReason?: UnavailabilityReason
  /**
   *
   * @type {UpdateDocumentFileDTO}
   * @memberof UpdateVendorDTO
   */
  voidedCheck?: UpdateDocumentFileDTO
}

/**
 *
 * @export
 * @interface VendorDTO
 */
export interface VendorDTO {
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  accountNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof VendorDTO
   */
  active?: boolean
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  authUserId?: string
  /**
   *
   * @type {boolean}
   * @memberof VendorDTO
   */
  availability?: boolean
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  companyName?: string
  /**
   *
   * @type {DateISOString}
   * @memberof VendorDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {DriverType}
   * @memberof VendorDTO
   */
  driverType?: DriverType
  /**
   *
   * @type {Frequency}
   * @memberof VendorDTO
   */
  frequency: Frequency
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  id: string
  /**
   *
   * @type {InactiveReason}
   * @memberof VendorDTO
   */
  inactiveReason?: InactiveReason
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  parentId?: string
  /**
   *
   * @type {PaymentMethod}
   * @memberof VendorDTO
   */
  paymentMethod?: PaymentMethod
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  routingCode?: string
  /**
   *
   * @type {UnavailabilityReason}
   * @memberof VendorDTO
   */
  unavailabilityReason?: UnavailabilityReason
  /**
   *
   * @type {DateISOString}
   * @memberof VendorDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof VendorDTO
   */
  voidedCheck?: DocumentFileDTO
}

/**
 * BankingDetailsControllerApi - fetch parameter creator
 * @export
 */
export const BankingDetailsControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary get the vendor banking details
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankingDetailsControllerGet(vendorId: string, options: any = {}): FetchArgs {
      // verify required parameter 'vendorId' is not null or undefined
      if (vendorId === null || vendorId === undefined) {
        throw new RequiredError(
          'vendorId',
          'Required parameter vendorId was null or undefined when calling bankingDetailsControllerGet.'
        )
      }
      const localVarPath = `/banking-details`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (vendorId !== undefined) {
        localVarQueryParameter['vendorId'] = vendorId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * BankingDetailsControllerApi - functional programming interface
 * @export
 */
export const BankingDetailsControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary get the vendor banking details
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankingDetailsControllerGet(vendorId: string, options?: any): (basePath?: string) => Promise<VendorDTO> {
      const localVarFetchArgs = BankingDetailsControllerApiFetchParamCreator(configuration).bankingDetailsControllerGet(
        vendorId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * BankingDetailsControllerApi - factory interface
 * @export
 */
export const BankingDetailsControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary get the vendor banking details
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankingDetailsControllerGet(vendorId: string, options?: any) {
      return BankingDetailsControllerApiFp(configuration).bankingDetailsControllerGet(vendorId, options)(basePath)
    }
  }
}

/**
 * BankingDetailsControllerApi - object-oriented interface
 * @export
 * @class BankingDetailsControllerApi
 * @extends {BaseAPI}
 */
export class BankingDetailsControllerApi extends BaseAPI {
  /**
   *
   * @summary get the vendor banking details
   * @param {string} vendorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankingDetailsControllerApi
   */
  public bankingDetailsControllerGet(vendorId: string, options?: any) {
    return BankingDetailsControllerApiFp(this.configuration).bankingDetailsControllerGet(vendorId, options)(
      this.basePath
    )
  }
}

/**
 * BsqControllerApi - fetch parameter creator
 * @export
 */
export const BsqControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary download BSQs and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bsqControllerDownload(body: DownloadDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling bsqControllerDownload.'
        )
      }
      const localVarPath = `/bsq/download`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DownloadDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * BsqControllerApi - functional programming interface
 * @export
 */
export const BsqControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary download BSQs and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bsqControllerDownload(body: DownloadDTO, options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = BsqControllerApiFetchParamCreator(configuration).bsqControllerDownload(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * BsqControllerApi - factory interface
 * @export
 */
export const BsqControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary download BSQs and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bsqControllerDownload(body: DownloadDTO, options?: any) {
      return BsqControllerApiFp(configuration).bsqControllerDownload(body, options)(basePath)
    }
  }
}

/**
 * BsqControllerApi - object-oriented interface
 * @export
 * @class BsqControllerApi
 * @extends {BaseAPI}
 */
export class BsqControllerApi extends BaseAPI {
  /**
   *
   * @summary download BSQs and save to database
   * @param {DownloadDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BsqControllerApi
   */
  public bsqControllerDownload(body: DownloadDTO, options?: any) {
    return BsqControllerApiFp(this.configuration).bsqControllerDownload(body, options)(this.basePath)
  }
}

/**
 * DeductionControllerApi - fetch parameter creator
 * @export
 */
export const DeductionControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary close the deduction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerClose(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deductionControllerClose.'
        )
      }
      const localVarPath = `/deduction/{id}/close`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary create a deduction
     * @param {CreateDeductionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerCreate(body: CreateDeductionDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling deductionControllerCreate.'
        )
      }
      const localVarPath = `/deduction`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateDeductionDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the deduction
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerGetDeduction(deductionId: string, options: any = {}): FetchArgs {
      // verify required parameter 'deductionId' is not null or undefined
      if (deductionId === null || deductionId === undefined) {
        throw new RequiredError(
          'deductionId',
          'Required parameter deductionId was null or undefined when calling deductionControllerGetDeduction.'
        )
      }
      const localVarPath = `/deduction`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (deductionId !== undefined) {
        localVarQueryParameter['deductionId'] = deductionId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the list of vendor deductions
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerGetDeductionsByVendorId(vendorId: string, options: any = {}): FetchArgs {
      // verify required parameter 'vendorId' is not null or undefined
      if (vendorId === null || vendorId === undefined) {
        throw new RequiredError(
          'vendorId',
          'Required parameter vendorId was null or undefined when calling deductionControllerGetDeductionsByVendorId.'
        )
      }
      const localVarPath = `/deduction/list`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (vendorId !== undefined) {
        localVarQueryParameter['vendorId'] = vendorId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary delete the deduction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerRemove(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deductionControllerRemove.'
        )
      }
      const localVarPath = `/deduction/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary update the deduction
     * @param {string} id
     * @param {UpdateDeductionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerUpdate(id: string, body: UpdateDeductionDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deductionControllerUpdate.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling deductionControllerUpdate.'
        )
      }
      const localVarPath = `/deduction/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UpdateDeductionDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DeductionControllerApi - functional programming interface
 * @export
 */
export const DeductionControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary close the deduction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerClose(id: string, options?: any): (basePath?: string) => Promise<DeductionDTO> {
      const localVarFetchArgs = DeductionControllerApiFetchParamCreator(configuration).deductionControllerClose(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary create a deduction
     * @param {CreateDeductionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerCreate(body: CreateDeductionDTO, options?: any): (basePath?: string) => Promise<DeductionDTO> {
      const localVarFetchArgs = DeductionControllerApiFetchParamCreator(configuration).deductionControllerCreate(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the deduction
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerGetDeduction(deductionId: string, options?: any): (basePath?: string) => Promise<DeductionDTO> {
      const localVarFetchArgs = DeductionControllerApiFetchParamCreator(configuration).deductionControllerGetDeduction(
        deductionId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the list of vendor deductions
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerGetDeductionsByVendorId(
      vendorId: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DeductionDTO>> {
      const localVarFetchArgs = DeductionControllerApiFetchParamCreator(
        configuration
      ).deductionControllerGetDeductionsByVendorId(vendorId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary delete the deduction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerRemove(id: string, options?: any): (basePath?: string) => Promise<DeductionDTO> {
      const localVarFetchArgs = DeductionControllerApiFetchParamCreator(configuration).deductionControllerRemove(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary update the deduction
     * @param {string} id
     * @param {UpdateDeductionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerUpdate(
      id: string,
      body: UpdateDeductionDTO,
      options?: any
    ): (basePath?: string) => Promise<DeductionDTO> {
      const localVarFetchArgs = DeductionControllerApiFetchParamCreator(configuration).deductionControllerUpdate(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DeductionControllerApi - factory interface
 * @export
 */
export const DeductionControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary close the deduction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerClose(id: string, options?: any) {
      return DeductionControllerApiFp(configuration).deductionControllerClose(id, options)(basePath)
    },
    /**
     *
     * @summary create a deduction
     * @param {CreateDeductionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerCreate(body: CreateDeductionDTO, options?: any) {
      return DeductionControllerApiFp(configuration).deductionControllerCreate(body, options)(basePath)
    },
    /**
     *
     * @summary get the deduction
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerGetDeduction(deductionId: string, options?: any) {
      return DeductionControllerApiFp(configuration).deductionControllerGetDeduction(deductionId, options)(basePath)
    },
    /**
     *
     * @summary get the list of vendor deductions
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerGetDeductionsByVendorId(vendorId: string, options?: any) {
      return DeductionControllerApiFp(configuration).deductionControllerGetDeductionsByVendorId(vendorId, options)(
        basePath
      )
    },
    /**
     *
     * @summary delete the deduction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerRemove(id: string, options?: any) {
      return DeductionControllerApiFp(configuration).deductionControllerRemove(id, options)(basePath)
    },
    /**
     *
     * @summary update the deduction
     * @param {string} id
     * @param {UpdateDeductionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionControllerUpdate(id: string, body: UpdateDeductionDTO, options?: any) {
      return DeductionControllerApiFp(configuration).deductionControllerUpdate(id, body, options)(basePath)
    }
  }
}

/**
 * DeductionControllerApi - object-oriented interface
 * @export
 * @class DeductionControllerApi
 * @extends {BaseAPI}
 */
export class DeductionControllerApi extends BaseAPI {
  /**
   *
   * @summary close the deduction
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionControllerApi
   */
  public deductionControllerClose(id: string, options?: any) {
    return DeductionControllerApiFp(this.configuration).deductionControllerClose(id, options)(this.basePath)
  }

  /**
   *
   * @summary create a deduction
   * @param {CreateDeductionDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionControllerApi
   */
  public deductionControllerCreate(body: CreateDeductionDTO, options?: any) {
    return DeductionControllerApiFp(this.configuration).deductionControllerCreate(body, options)(this.basePath)
  }

  /**
   *
   * @summary get the deduction
   * @param {string} deductionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionControllerApi
   */
  public deductionControllerGetDeduction(deductionId: string, options?: any) {
    return DeductionControllerApiFp(this.configuration).deductionControllerGetDeduction(deductionId, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary get the list of vendor deductions
   * @param {string} vendorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionControllerApi
   */
  public deductionControllerGetDeductionsByVendorId(vendorId: string, options?: any) {
    return DeductionControllerApiFp(this.configuration).deductionControllerGetDeductionsByVendorId(vendorId, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary delete the deduction
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionControllerApi
   */
  public deductionControllerRemove(id: string, options?: any) {
    return DeductionControllerApiFp(this.configuration).deductionControllerRemove(id, options)(this.basePath)
  }

  /**
   *
   * @summary update the deduction
   * @param {string} id
   * @param {UpdateDeductionDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionControllerApi
   */
  public deductionControllerUpdate(id: string, body: UpdateDeductionDTO, options?: any) {
    return DeductionControllerApiFp(this.configuration).deductionControllerUpdate(id, body, options)(this.basePath)
  }
}

/**
 * DeductionTransactionControllerApi - fetch parameter creator
 * @export
 */
export const DeductionTransactionControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a escrow refund
     * @param {CreateDeductionTransactionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerCreate(body: CreateDeductionTransactionDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling deductionTransactionControllerCreate.'
        )
      }
      const localVarPath = `/deduction-transaction`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateDeductionTransactionDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the list of deduction transactions
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerGetDeductionTransactionsByDeductionId(
      deductionId: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'deductionId' is not null or undefined
      if (deductionId === null || deductionId === undefined) {
        throw new RequiredError(
          'deductionId',
          'Required parameter deductionId was null or undefined when calling deductionTransactionControllerGetDeductionTransactionsByDeductionId.'
        )
      }
      const localVarPath = `/deduction-transaction`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (deductionId !== undefined) {
        localVarQueryParameter['deductionId'] = deductionId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary deduction report
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerGetReport(deductionId: string, options: any = {}): FetchArgs {
      // verify required parameter 'deductionId' is not null or undefined
      if (deductionId === null || deductionId === undefined) {
        throw new RequiredError(
          'deductionId',
          'Required parameter deductionId was null or undefined when calling deductionTransactionControllerGetReport.'
        )
      }
      const localVarPath = `/deduction-transaction/report`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (deductionId !== undefined) {
        localVarQueryParameter['deductionId'] = deductionId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary publish a deduction transaction in the deduction transaction topic
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerPublish(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deductionTransactionControllerPublish.'
        )
      }
      const localVarPath = `/deduction-transaction/{id}/publish`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary delete the deduction transaction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerRemove(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deductionTransactionControllerRemove.'
        )
      }
      const localVarPath = `/deduction-transaction/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary delete the deduction transactions by vendorId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerRemoveByVendorId(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deductionTransactionControllerRemoveByVendorId.'
        )
      }
      const localVarPath = `/deduction-transaction/{id}/vendor`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DeductionTransactionControllerApi - functional programming interface
 * @export
 */
export const DeductionTransactionControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a escrow refund
     * @param {CreateDeductionTransactionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerCreate(
      body: CreateDeductionTransactionDTO,
      options?: any
    ): (basePath?: string) => Promise<DeductionTransactionDTO> {
      const localVarFetchArgs = DeductionTransactionControllerApiFetchParamCreator(
        configuration
      ).deductionTransactionControllerCreate(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the list of deduction transactions
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerGetDeductionTransactionsByDeductionId(
      deductionId: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DeductionTransactionDTO>> {
      const localVarFetchArgs = DeductionTransactionControllerApiFetchParamCreator(
        configuration
      ).deductionTransactionControllerGetDeductionTransactionsByDeductionId(deductionId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary deduction report
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerGetReport(
      deductionId: string,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DeductionTransactionControllerApiFetchParamCreator(
        configuration
      ).deductionTransactionControllerGetReport(deductionId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary publish a deduction transaction in the deduction transaction topic
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerPublish(
      id: string,
      options?: any
    ): (basePath?: string) => Promise<DeductionTransactionDTO> {
      const localVarFetchArgs = DeductionTransactionControllerApiFetchParamCreator(
        configuration
      ).deductionTransactionControllerPublish(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary delete the deduction transaction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerRemove(
      id: string,
      options?: any
    ): (basePath?: string) => Promise<DeductionTransactionDTO> {
      const localVarFetchArgs = DeductionTransactionControllerApiFetchParamCreator(
        configuration
      ).deductionTransactionControllerRemove(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary delete the deduction transactions by vendorId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerRemoveByVendorId(
      id: string,
      options?: any
    ): (basePath?: string) => Promise<DeductionTransactionDTO> {
      const localVarFetchArgs = DeductionTransactionControllerApiFetchParamCreator(
        configuration
      ).deductionTransactionControllerRemoveByVendorId(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DeductionTransactionControllerApi - factory interface
 * @export
 */
export const DeductionTransactionControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create a escrow refund
     * @param {CreateDeductionTransactionDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerCreate(body: CreateDeductionTransactionDTO, options?: any) {
      return DeductionTransactionControllerApiFp(configuration).deductionTransactionControllerCreate(body, options)(
        basePath
      )
    },
    /**
     *
     * @summary get the list of deduction transactions
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerGetDeductionTransactionsByDeductionId(deductionId: string, options?: any) {
      return DeductionTransactionControllerApiFp(
        configuration
      ).deductionTransactionControllerGetDeductionTransactionsByDeductionId(deductionId, options)(basePath)
    },
    /**
     *
     * @summary deduction report
     * @param {string} deductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerGetReport(deductionId: string, options?: any) {
      return DeductionTransactionControllerApiFp(configuration).deductionTransactionControllerGetReport(
        deductionId,
        options
      )(basePath)
    },
    /**
     *
     * @summary publish a deduction transaction in the deduction transaction topic
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerPublish(id: string, options?: any) {
      return DeductionTransactionControllerApiFp(configuration).deductionTransactionControllerPublish(id, options)(
        basePath
      )
    },
    /**
     *
     * @summary delete the deduction transaction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerRemove(id: string, options?: any) {
      return DeductionTransactionControllerApiFp(configuration).deductionTransactionControllerRemove(id, options)(
        basePath
      )
    },
    /**
     *
     * @summary delete the deduction transactions by vendorId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deductionTransactionControllerRemoveByVendorId(id: string, options?: any) {
      return DeductionTransactionControllerApiFp(configuration).deductionTransactionControllerRemoveByVendorId(
        id,
        options
      )(basePath)
    }
  }
}

/**
 * DeductionTransactionControllerApi - object-oriented interface
 * @export
 * @class DeductionTransactionControllerApi
 * @extends {BaseAPI}
 */
export class DeductionTransactionControllerApi extends BaseAPI {
  /**
   *
   * @summary create a escrow refund
   * @param {CreateDeductionTransactionDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionTransactionControllerApi
   */
  public deductionTransactionControllerCreate(body: CreateDeductionTransactionDTO, options?: any) {
    return DeductionTransactionControllerApiFp(this.configuration).deductionTransactionControllerCreate(body, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary get the list of deduction transactions
   * @param {string} deductionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionTransactionControllerApi
   */
  public deductionTransactionControllerGetDeductionTransactionsByDeductionId(deductionId: string, options?: any) {
    return DeductionTransactionControllerApiFp(
      this.configuration
    ).deductionTransactionControllerGetDeductionTransactionsByDeductionId(deductionId, options)(this.basePath)
  }

  /**
   *
   * @summary deduction report
   * @param {string} deductionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionTransactionControllerApi
   */
  public deductionTransactionControllerGetReport(deductionId: string, options?: any) {
    return DeductionTransactionControllerApiFp(this.configuration).deductionTransactionControllerGetReport(
      deductionId,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary publish a deduction transaction in the deduction transaction topic
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionTransactionControllerApi
   */
  public deductionTransactionControllerPublish(id: string, options?: any) {
    return DeductionTransactionControllerApiFp(this.configuration).deductionTransactionControllerPublish(id, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary delete the deduction transaction
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionTransactionControllerApi
   */
  public deductionTransactionControllerRemove(id: string, options?: any) {
    return DeductionTransactionControllerApiFp(this.configuration).deductionTransactionControllerRemove(id, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary delete the deduction transactions by vendorId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeductionTransactionControllerApi
   */
  public deductionTransactionControllerRemoveByVendorId(id: string, options?: any) {
    return DeductionTransactionControllerApiFp(this.configuration).deductionTransactionControllerRemoveByVendorId(
      id,
      options
    )(this.basePath)
  }
}

/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options: any = {}): FetchArgs {
      const localVarPath = `/health-check`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options: any = {}): FetchArgs {
      const localVarPath = `/prometheus`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).appControllerHealthCheck(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).promControllerIndex(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options?: any) {
      return DefaultApiFp(configuration).appControllerHealthCheck(options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promControllerIndex(options?: any) {
      return DefaultApiFp(configuration).promControllerIndex(options)(basePath)
    }
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary health check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerHealthCheck(options?: any) {
    return DefaultApiFp(this.configuration).appControllerHealthCheck(options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public promControllerIndex(options?: any) {
    return DefaultApiFp(this.configuration).promControllerIndex(options)(this.basePath)
  }
}

/**
 * FuelTransactionControllerApi - fetch parameter creator
 * @export
 */
export const FuelTransactionControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary download fuel transactions and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fuelTransactionControllerDownload(body: DownloadDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling fuelTransactionControllerDownload.'
        )
      }
      const localVarPath = `/fuel-transaction/download`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DownloadDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * FuelTransactionControllerApi - functional programming interface
 * @export
 */
export const FuelTransactionControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary download fuel transactions and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fuelTransactionControllerDownload(body: DownloadDTO, options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = FuelTransactionControllerApiFetchParamCreator(
        configuration
      ).fuelTransactionControllerDownload(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * FuelTransactionControllerApi - factory interface
 * @export
 */
export const FuelTransactionControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary download fuel transactions and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fuelTransactionControllerDownload(body: DownloadDTO, options?: any) {
      return FuelTransactionControllerApiFp(configuration).fuelTransactionControllerDownload(body, options)(basePath)
    }
  }
}

/**
 * FuelTransactionControllerApi - object-oriented interface
 * @export
 * @class FuelTransactionControllerApi
 * @extends {BaseAPI}
 */
export class FuelTransactionControllerApi extends BaseAPI {
  /**
   *
   * @summary download fuel transactions and save to database
   * @param {DownloadDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FuelTransactionControllerApi
   */
  public fuelTransactionControllerDownload(body: DownloadDTO, options?: any) {
    return FuelTransactionControllerApiFp(this.configuration).fuelTransactionControllerDownload(body, options)(
      this.basePath
    )
  }
}

/**
 * PaymentCheckControllerApi - fetch parameter creator
 * @export
 */
export const PaymentCheckControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create an accounting report
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerCreateAccountingReport(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling paymentCheckControllerCreateAccountingReport.'
        )
      }
      const localVarPath = `/payment-check/accounting-report/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary create a payment check report
     * @param {PaymentCheckReportParametersDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerCreatePaymentCheckReport(
      body: PaymentCheckReportParametersDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling paymentCheckControllerCreatePaymentCheckReport.'
        )
      }
      const localVarPath = `/payment-check`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'PaymentCheckReportParametersDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the payment check report by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerGetPaymentCheckReport(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling paymentCheckControllerGetPaymentCheckReport.'
        )
      }
      const localVarPath = `/payment-check/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the list of checks for some period
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerGetPaymentChecks(pageNo?: number, pageSize?: number, options: any = {}): FetchArgs {
      const localVarPath = `/payment-check`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * PaymentCheckControllerApi - functional programming interface
 * @export
 */
export const PaymentCheckControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create an accounting report
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerCreateAccountingReport(id: string, options?: any): (basePath?: string) => Promise<FileDTO> {
      const localVarFetchArgs = PaymentCheckControllerApiFetchParamCreator(
        configuration
      ).paymentCheckControllerCreateAccountingReport(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary create a payment check report
     * @param {PaymentCheckReportParametersDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerCreatePaymentCheckReport(
      body: PaymentCheckReportParametersDTO,
      options?: any
    ): (basePath?: string) => Promise<PaymentCheckReportDTO> {
      const localVarFetchArgs = PaymentCheckControllerApiFetchParamCreator(
        configuration
      ).paymentCheckControllerCreatePaymentCheckReport(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the payment check report by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerGetPaymentCheckReport(
      id: string,
      options?: any
    ): (basePath?: string) => Promise<PaymentCheckReportDTO> {
      const localVarFetchArgs = PaymentCheckControllerApiFetchParamCreator(
        configuration
      ).paymentCheckControllerGetPaymentCheckReport(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the list of checks for some period
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerGetPaymentChecks(
      pageNo?: number,
      pageSize?: number,
      options?: any
    ): (basePath?: string) => Promise<Array<PaymentCheckDTO>> {
      const localVarFetchArgs = PaymentCheckControllerApiFetchParamCreator(
        configuration
      ).paymentCheckControllerGetPaymentChecks(pageNo, pageSize, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * PaymentCheckControllerApi - factory interface
 * @export
 */
export const PaymentCheckControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create an accounting report
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerCreateAccountingReport(id: string, options?: any) {
      return PaymentCheckControllerApiFp(configuration).paymentCheckControllerCreateAccountingReport(id, options)(
        basePath
      )
    },
    /**
     *
     * @summary create a payment check report
     * @param {PaymentCheckReportParametersDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerCreatePaymentCheckReport(body: PaymentCheckReportParametersDTO, options?: any) {
      return PaymentCheckControllerApiFp(configuration).paymentCheckControllerCreatePaymentCheckReport(body, options)(
        basePath
      )
    },
    /**
     *
     * @summary get the payment check report by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerGetPaymentCheckReport(id: string, options?: any) {
      return PaymentCheckControllerApiFp(configuration).paymentCheckControllerGetPaymentCheckReport(id, options)(
        basePath
      )
    },
    /**
     *
     * @summary get the list of checks for some period
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentCheckControllerGetPaymentChecks(pageNo?: number, pageSize?: number, options?: any) {
      return PaymentCheckControllerApiFp(configuration).paymentCheckControllerGetPaymentChecks(
        pageNo,
        pageSize,
        options
      )(basePath)
    }
  }
}

/**
 * PaymentCheckControllerApi - object-oriented interface
 * @export
 * @class PaymentCheckControllerApi
 * @extends {BaseAPI}
 */
export class PaymentCheckControllerApi extends BaseAPI {
  /**
   *
   * @summary create an accounting report
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentCheckControllerApi
   */
  public paymentCheckControllerCreateAccountingReport(id: string, options?: any) {
    return PaymentCheckControllerApiFp(this.configuration).paymentCheckControllerCreateAccountingReport(id, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary create a payment check report
   * @param {PaymentCheckReportParametersDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentCheckControllerApi
   */
  public paymentCheckControllerCreatePaymentCheckReport(body: PaymentCheckReportParametersDTO, options?: any) {
    return PaymentCheckControllerApiFp(this.configuration).paymentCheckControllerCreatePaymentCheckReport(
      body,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary get the payment check report by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentCheckControllerApi
   */
  public paymentCheckControllerGetPaymentCheckReport(id: string, options?: any) {
    return PaymentCheckControllerApiFp(this.configuration).paymentCheckControllerGetPaymentCheckReport(id, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary get the list of checks for some period
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentCheckControllerApi
   */
  public paymentCheckControllerGetPaymentChecks(pageNo?: number, pageSize?: number, options?: any) {
    return PaymentCheckControllerApiFp(this.configuration).paymentCheckControllerGetPaymentChecks(
      pageNo,
      pageSize,
      options
    )(this.basePath)
  }
}

/**
 * TollTransactionControllerApi - fetch parameter creator
 * @export
 */
export const TollTransactionControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary download toll transactions and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tollTransactionControllerDownload(body: DownloadDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling tollTransactionControllerDownload.'
        )
      }
      const localVarPath = `/toll-transaction/download`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DownloadDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * TollTransactionControllerApi - functional programming interface
 * @export
 */
export const TollTransactionControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary download toll transactions and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tollTransactionControllerDownload(body: DownloadDTO, options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = TollTransactionControllerApiFetchParamCreator(
        configuration
      ).tollTransactionControllerDownload(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * TollTransactionControllerApi - factory interface
 * @export
 */
export const TollTransactionControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary download toll transactions and save to database
     * @param {DownloadDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tollTransactionControllerDownload(body: DownloadDTO, options?: any) {
      return TollTransactionControllerApiFp(configuration).tollTransactionControllerDownload(body, options)(basePath)
    }
  }
}

/**
 * TollTransactionControllerApi - object-oriented interface
 * @export
 * @class TollTransactionControllerApi
 * @extends {BaseAPI}
 */
export class TollTransactionControllerApi extends BaseAPI {
  /**
   *
   * @summary download toll transactions and save to database
   * @param {DownloadDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TollTransactionControllerApi
   */
  public tollTransactionControllerDownload(body: DownloadDTO, options?: any) {
    return TollTransactionControllerApiFp(this.configuration).tollTransactionControllerDownload(body, options)(
      this.basePath
    )
  }
}

/**
 * VendorControllerApi - fetch parameter creator
 * @export
 */
export const VendorControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a deduction transactions for vendor on the date = job date minus one day
     * @param {string} id
     * @param {CreateVendorDeductionTransactionsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerCreateVendorDeductionTransactions(
      id: string,
      body: CreateVendorDeductionTransactionsDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling vendorControllerCreateVendorDeductionTransactions.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling vendorControllerCreateVendorDeductionTransactions.'
        )
      }
      const localVarPath = `/vendor/{id}/deduction-transaction`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CreateVendorDeductionTransactionsDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary get the vendor
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerGet(vendorId: string, options: any = {}): FetchArgs {
      // verify required parameter 'vendorId' is not null or undefined
      if (vendorId === null || vendorId === undefined) {
        throw new RequiredError(
          'vendorId',
          'Required parameter vendorId was null or undefined when calling vendorControllerGet.'
        )
      }
      const localVarPath = `/vendor`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (vendorId !== undefined) {
        localVarQueryParameter['vendorId'] = vendorId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary update the vendor
     * @param {string} id
     * @param {UpdateVendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerUpdate(id: string, body: UpdateVendorDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling vendorControllerUpdate.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling vendorControllerUpdate.'
        )
      }
      const localVarPath = `/vendor/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UpdateVendorDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * VendorControllerApi - functional programming interface
 * @export
 */
export const VendorControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a deduction transactions for vendor on the date = job date minus one day
     * @param {string} id
     * @param {CreateVendorDeductionTransactionsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerCreateVendorDeductionTransactions(
      id: string,
      body: CreateVendorDeductionTransactionsDTO,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = VendorControllerApiFetchParamCreator(
        configuration
      ).vendorControllerCreateVendorDeductionTransactions(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary get the vendor
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerGet(vendorId: string, options?: any): (basePath?: string) => Promise<VendorDTO> {
      const localVarFetchArgs = VendorControllerApiFetchParamCreator(configuration).vendorControllerGet(
        vendorId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary update the vendor
     * @param {string} id
     * @param {UpdateVendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerUpdate(
      id: string,
      body: UpdateVendorDTO,
      options?: any
    ): (basePath?: string) => Promise<VendorDTO> {
      const localVarFetchArgs = VendorControllerApiFetchParamCreator(configuration).vendorControllerUpdate(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * VendorControllerApi - factory interface
 * @export
 */
export const VendorControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary create a deduction transactions for vendor on the date = job date minus one day
     * @param {string} id
     * @param {CreateVendorDeductionTransactionsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerCreateVendorDeductionTransactions(
      id: string,
      body: CreateVendorDeductionTransactionsDTO,
      options?: any
    ) {
      return VendorControllerApiFp(configuration).vendorControllerCreateVendorDeductionTransactions(id, body, options)(
        basePath
      )
    },
    /**
     *
     * @summary get the vendor
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerGet(vendorId: string, options?: any) {
      return VendorControllerApiFp(configuration).vendorControllerGet(vendorId, options)(basePath)
    },
    /**
     *
     * @summary update the vendor
     * @param {string} id
     * @param {UpdateVendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vendorControllerUpdate(id: string, body: UpdateVendorDTO, options?: any) {
      return VendorControllerApiFp(configuration).vendorControllerUpdate(id, body, options)(basePath)
    }
  }
}

/**
 * VendorControllerApi - object-oriented interface
 * @export
 * @class VendorControllerApi
 * @extends {BaseAPI}
 */
export class VendorControllerApi extends BaseAPI {
  /**
   *
   * @summary create a deduction transactions for vendor on the date = job date minus one day
   * @param {string} id
   * @param {CreateVendorDeductionTransactionsDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VendorControllerApi
   */
  public vendorControllerCreateVendorDeductionTransactions(
    id: string,
    body: CreateVendorDeductionTransactionsDTO,
    options?: any
  ) {
    return VendorControllerApiFp(this.configuration).vendorControllerCreateVendorDeductionTransactions(
      id,
      body,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary get the vendor
   * @param {string} vendorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VendorControllerApi
   */
  public vendorControllerGet(vendorId: string, options?: any) {
    return VendorControllerApiFp(this.configuration).vendorControllerGet(vendorId, options)(this.basePath)
  }

  /**
   *
   * @summary update the vendor
   * @param {string} id
   * @param {UpdateVendorDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VendorControllerApi
   */
  public vendorControllerUpdate(id: string, body: UpdateVendorDTO, options?: any) {
    return VendorControllerApiFp(this.configuration).vendorControllerUpdate(id, body, options)(this.basePath)
  }
}

export type DateISOString = string // 2018-04-15T08:36:01.711Z
