import styled from 'styled-components'
import { Column, DefaultColumn } from '../../../../../../UI/GridSystem'
import theme from '../../../../../../../styles/theme'
import { activityDirectory } from '../../../../../../../services/DTO/activity/directory'
import { TransportationActivityViewDTO } from '../../../../../../../api/origin/business-logic'

export const convertHexToRGBA = (hexCode: string, alpha: number): string => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${alpha || 1})`
}

export const activityStageColor = {
  Pickup: activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.PICKUP],
  Delivery: activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.DELIVERY],
  Return: activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.RETURN],
  PICKUP: activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.PICKUP],
  DELIVERY: activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.DELIVERY],
  RETURN: activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.RETURN],
  [TransportationActivityViewDTO.StageEnum.PICKUP]:
    activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.PICKUP],
  [TransportationActivityViewDTO.StageEnum.DELIVERY]:
    activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.DELIVERY],
  [TransportationActivityViewDTO.StageEnum.RETURN]:
    activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.RETURN],
  rgba: (
    stage: 'PICKUP' | 'DELIVERY' | 'RETURN' | TransportationActivityViewDTO.StageEnum,
    alpha: number = 1
  ): string => {
    switch (stage) {
      case 'PICKUP':
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        return convertHexToRGBA(activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.PICKUP], alpha)
      case 'DELIVERY':
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
        return convertHexToRGBA(activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.DELIVERY], alpha)
      case 'RETURN':
      case TransportationActivityViewDTO.StageEnum.RETURN:
        return convertHexToRGBA(activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.RETURN], alpha)
      default:
        return ''
    }
  }
}

export const ColorLine = styled.span`
  flex: none;
  width: 14px;
  font-size: 16px;
  color: white;
  border-radius: 3px 0 0 3px;
  overflow: hidden;
`

export const DeleteRow = styled.div`
  width: 40px;
  display: flex;
  flex-shrink: 0;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s ease;
  cursor: pointer;
  padding: 0 !important;
  margin-left: auto;

  &:before {
    opacity: 0.5;
  }

  &.disabled {
    cursor: default;
    &:before {
      opacity: 0.1 !important;
    }
  }

  &:hover:before {
    opacity: 1;
  }
`
export const ActivityColumn = styled.div`
  height: 50px;
  display: flex;
  padding: 7px 10px;
`

export const ActivityColumnWithLabel = styled(ActivityColumn)`
  font-weight: 500;
  padding: 7px 10px 7px 0;

  .placeholder {
    font-weight: 400;
  }

  .label {
    padding: 0 11px;
  }

  &.PICKUP {
    .selected,
    .label {
      color: ${activityStageColor.PICKUP};
    }
  }
  &.DELIVERY {
    .selected,
    .label {
      color: ${activityStageColor.DELIVERY};
    }
  }
  &.RETURN {
    .selected,
    .label {
      color: ${activityStageColor.RETURN};
    }
  }
`
const ActivityRowGridTemplateColumns = [
  '16px', // arrows
  '330px', // driver/location
  '40px', // type icon
  '180px', // type
  // 70, // note
  '200px', // status
  '220px', // planned date
  '170px', // actual start
  '170px', // actual complete
  '80px', // ssq
  '80px', // bsq
  'minmax(180px, 250px)', // doc
  'minmax(40px, 1fr)' // '40px' // delete
].join(' ')

export const StyledActivityRowConfig = styled.div`
  min-width: 100%;
  display: inline-grid;
  grid-template-columns: ${ActivityRowGridTemplateColumns};
`
export const StyledActivityRow = styled(StyledActivityRowConfig)`
  min-height: 100px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: inset 0 0 0 1px ${theme.colors.tableBorder};
  background-color: white;
  margin-top: 8px;

  .input-container,
  .select-container,
  .date-field {
    background-color: white;
  }

  &.trouble-ticket {
    > div:not(:nth-child(1)):not(.driver-location-column) {
      padding-top: 40px;
    }
  }
  &.bobtail-button-top {
    > div:not(:nth-child(1)):not(.type-column) {
      padding-top: 24px;
    }
  }
  &.bobtail-button-bottom {
    > div:not(:nth-child(1)):not(.type-column) {
      padding-bottom: 24px;
    }
  }

  > div {
    &:not(:first-child) {
      border-left: 1px solid rgba(229, 229, 229, 0.5);
    }

    > ${Column}, > ${DefaultColumn} {
      padding: 7px 10px;
    }

    > ${ActivityColumn}, ${Column} {
      &.border-bottom {
        border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      }

      &.border-top {
        border-top: 1px solid rgba(229, 229, 229, 0.5);
      }
    }
  }
`
export const DabbleColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;

  > div {
    display: flex;
    align-items: center;
  }
`
export const QuoteColumn = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  padding: 5px;
`
export const DriverLocationRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  > div {
    flex: 1 0 auto;

    &.driver .input-container:before,
    .widget-preview:before {
      color: #4f4f4f;
      display: flex;
      align-items: center;
      font: normal normal normal 24px/1 'Material Design Icons';
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.driver .input-container {
      &:before {
        content: '\\F04D4';
        margin: 0 -30px 0 5px;
      }

      &.showOffline:before {
        color: #f5a85f;
      }
      &.showOnline:before {
        color: #65d16c;
      }
      &.inactive:before {
        color: ${theme.colors.defaultRed};
      }

      input,
      .disabled-input {
        padding-left: 33px;
      }
    }

    .widget-preview {
      padding-left: 30px;

      &:before {
        position: absolute;
        left: 4px;
      }

      &.isLocationInvalid:before {
        color: ${theme.colors.defaultRed};
      }
    }
  }
`

export const DOStyledRow = styled.div`
  display: flex;
  align-items: stretch;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid ${theme.colors.tableBorder};
  background-color: white;
  margin-top: 5px;

  > div {
    border-left: 1px solid rgba(229, 229, 229, 0.5);
    background-color: white;
    padding: 10px;

    &:first-child {
      border-color: rgba(0, 0, 0, 0);
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  .hazmat-column label {
    display: none !important;
  }

  &.canceled {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`
export const Columns = styled.div`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #6c707d;
  font-size: 12px;
  user-select: none;

  > div {
    height: 20px;
    align-items: center;
    border-left: 1px solid ${theme.colors.tableBorder};
    padding-left: 20px;

    /* &:first-child {
      padding-left: 30px;
    } */

    &:first-child {
      border-color: rgba(0, 0, 0, 0) !important;
    }

    &.required:after {
      content: '*';
      margin-left: 3px;
      color: ${theme.colors.defaultRed};
    }
  }

  &.darkBg > div {
    border-color: #c6cce3;
  }
`
export const DDOActivityTable = styled.div`
  height: 450px;
  background: linear-gradient(to right, ${theme.colors.tableBorder} 50%, rgba(255, 255, 255, 0) 0%) repeat-x top;
  background-size: 12px 1px;
  padding: 5px 20px 0 20px;
  overflow: auto;

  .table__row_full-height & {
    height: calc(100% - 85px);
  }
`
