import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { saveDriver as saveDriverPromise } from '../../saveEntity/saveDriver'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { DriverViewDTO } from '../../../api/api'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { getListsState } from '../../../store'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'
import { isNewItem } from '../../utils'
import { TabExpandedItemDataProp } from '../../../store/reducers/tabs/interfaces'
import { filterModifiedDeductions } from '../../functions/filter/filterModifiedDeductions'

export const saveDriver = (expandedItemContext: TExpandedItemContext) => {
  const {
    tabId,
    parentItem,
    data,
    parentItemId,
    modifiedLists,
    setFetching,
    deleteModifiedListItems,
    clearExpandedItemModifies
  } = expandedItemContext
  const driver: DriverViewDTO = parentItem
  const modifiedDriver = oc(modifiedLists)[EntityType.driver][parentItemId]()
  const store = getListsState()
  const { deductionIds, vendorAccountingId } = oc(data)[TabExpandedItemDataProp.driverAccountingTabData]({})
  const modifiedVendorAccounting = oc(modifiedLists)[EntityType.vendorAccounting][vendorAccountingId]()
  const updatedDeductions = filterModifiedDeductions({ deductionIds, modifiedLists })
  const condition =
    testEntityVersion(store, EntityType.driver)(modifiedDriver) &&
    testEntityVersion(store, EntityType.vendorAccounting)(modifiedVendorAccounting) &&
    updatedDeductions.every(testEntityVersion(store, EntityType.deduction))

  const save = () => {
    return saveDriverPromise({
      driverId: parentItemId,
      driver: modifiedDriver,
      vendorAccounting: modifiedVendorAccounting,
      deductions: updatedDeductions,
      onVendorAccountingSaveComplete: id => deleteModifiedListItems({ [EntityType.vendorAccounting]: [id] }),
      onDeductionSaveComplete: id => deleteModifiedListItems({ [EntityType.deduction]: [id] })
    }).then(handleSavedGridItem({ tabId, isNewItem: isNewItem(driver) }))
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
