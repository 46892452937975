import * as React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { theme } from '../../../../../styles/theme'
import { ButtonClear } from '../../../../UI/DataFields/Select/styles'
import { DispatchDeliveryOrderStreetTurnDTO } from '../../../../../api/api'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'

type Props = {
  disabled?: boolean
  placeholder: string
  selectedValues: DispatchDeliveryOrderStreetTurnDTO.StatusEnum[]
  onClearButtonClick: () => void
}

export const DdoHeaderColumnStatusLabel = (props: Props) => {
  const { placeholder = '', selectedValues, onClearButtonClick, disabled } = props

  return (
    <StyledLabel className={cn({ multiselect: !disabled && selectedValues && selectedValues.length > 1 })}>
      <Status>
        {disabled || !(selectedValues && selectedValues.length) ? (
          <Value>{placeholder}</Value>
        ) : selectedValues.length > 1 ? (
          <NumberOfValues>{selectedValues.length}</NumberOfValues>
        ) : (
          <Value>{ddoDirectory.streetTurnStatusLabel[selectedValues[0]] || selectedValues[0]}</Value>
        )}
        {!disabled && Boolean(selectedValues.length) && (
          <ButtonClear
            className={'mdi mdi-close-circle'}
            onClick={event => {
              event.stopPropagation()
              return onClearButtonClick()
            }}
          />
        )}
      </Status>
    </StyledLabel>
  )
}

const StyledLabel = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  cursor: pointer;

  .multiselect {
    justify-content: center;
  }

  ${ButtonClear} {
    color: #e0e0e0 !important;

    &:hover {
      color: #445366 !important;
    }
  }
`
const Status = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`
const Value = styled.div`
  color: #6c707d;
  font-size: 10px;
`
const NumberOfValues = styled.div`
  display: inline-block;
  line-height: 18px;
  background: ${theme.colors.basicBlueColor};
  font-weight: 500;
  font-size: 14px;
  color: #f5f6fa;
  border-radius: 2px;
  padding: 0 5px;
  margin-left: auto;
`
