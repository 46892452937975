import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { ITab, ColumnDTO } from '../../store/reducers/tabs/interfaces'
import { getApplicationTabsState, getListsState } from '../../store'
import { updateTab } from '../../store/reducers/tabs/functions'
import { EntityType } from '../../store/reducers/lists/interfaces'
import { isWebSocketGridDdoValid } from '../functions/test/isWebSocketGridDdoValid'

export const updateStoreObjectsWithTabsItemList = ({
  objectType,
  actionToUpdate,
  objectsToUpdate,
  objectsToAdd,
  listState
}: {
  objectType: EntityType
  objectsToUpdate: any[]
  objectsToAdd: any[]
  actionToUpdate: any
  listState?: Record<string, any>
}) => {
  const tabs = getApplicationTabsState()
  const storeList = getListsState()[objectType] || {}
  const objects = objectsToAdd.concat(objectsToUpdate)

  if (!objects.length) {
    return
  }

  // update objects in store
  actionToUpdate(objects)

  // remove unsuitable Objects from grid
  const filteredTabs: ITab[] = tabs.filter(({ visited, type, permissions: { websoketsUpdate } }) => {
    // @ts-ignore
    return websoketsUpdate && visited && type === objectType
  })

  filteredTabs.forEach(tab => {
    const filters = tab.uiSettings.filter
    const sorting = tab.uiSettings.sorting
    const { unsuitableIds, suitableIds } = filterObjectsByTabFilter(objects, filters, objectType)
    const gridItemIds: string[] = tab.gridItemIds || []
    let updatedGridItemIds: string[] = gridItemIds
    let hasUpdates = false
    let needSort = false
    const checkSortingColumns = ['daysOut']
    checkSortingColumns.push(...checkSortingColumns.map(column => '-' + column))

    if (suitableIds.length) {
      updatedGridItemIds = R.uniq(suitableIds.concat(updatedGridItemIds))
      hasUpdates = updatedGridItemIds.length !== gridItemIds.length

      if (objectType === EntityType.dispatchDeliveryOrder && checkSortingColumns.includes(sorting)) {
        needSort = true
      }
    }

    if (unsuitableIds.length) {
      updatedGridItemIds = updatedGridItemIds.filter(id => !unsuitableIds.includes(id))
      hasUpdates = updatedGridItemIds.length !== gridItemIds.length
    }

    if (needSort) {
      hasUpdates = true
      const propName = sorting[0] === '-' ? sorting.slice(1) : sorting
      const updatedObjectsMapping: Record<string, any> = objects.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})
      const itemsToSort: any[] = []
      const staticIds: string[] = []
      const sortFn = sorting.includes('-')
        ? (a: any, b: any) => {
            return b[propName] - a[propName]
          }
        : (a: any, b: any) => {
            return a[propName] - b[propName]
          }

      updatedGridItemIds.forEach(id => {
        const item = updatedObjectsMapping[id] || (listState || storeList)[id]

        if (item && typeof oc(item)[propName]() === 'number') {
          itemsToSort.push(item)
        } else {
          staticIds.push(id)
        }
      })

      updatedGridItemIds = itemsToSort
        .sort(sortFn)
        .map(item => item.id)
        .concat(staticIds)
    }

    if (hasUpdates) {
      updateTab(tab.id, { gridItemIds: updatedGridItemIds })
    }
  })
}

export const filterObjectsByTabFilter = (
  items: any[],
  filters: ColumnDTO.Filter[],
  entityType: EntityType
): { suitableIds: string[]; unsuitableIds: string[] } => {
  const suitableIds: string[] = []
  const unsuitableIds: string[] = []

  if (filters && filters.length) {
    items.forEach(item => {
      const isSuitable = filters.every(filter => {
        const { column, value, path, combinationOfValuePaths, isEqualToValue } = filter

        // use column value = NULL to omit the field
        if (value === null || value === undefined) {
          return true
        }

        if (
          entityType === EntityType.dispatchDeliveryOrder &&
          isWebSocketGridDdoValid({ dispatchDeliveryOrder: item, filter })
        ) {
          return true
        }

        if (combinationOfValuePaths && combinationOfValuePaths.length) {
          const objectFieldsValues = combinationOfValuePaths.map(_path => getObjectFieldValueByPath(item, _path))
          return objectFieldsValues.some(objectFieldValue => compareValues(value, objectFieldValue, isEqualToValue))
        } else {
          const objectFieldValue = getObjectFieldValueByPath(item, path || column)
          return compareValues(value, objectFieldValue, isEqualToValue)
        }
      })

      if (isSuitable) {
        suitableIds.push(item.id)
      } else {
        unsuitableIds.push(item.id)
      }
    })
  } else {
    suitableIds.push(...items.map(({ id }) => id))
  }

  return { suitableIds, unsuitableIds }
}

export const compareValues = (filterValue: any, entityValue: any, isEqualToValue: boolean): boolean => {
  const match = (() => {
    if (entityValue === undefined || entityValue === null) {
      return false
    }

    // multi select
    if (Array.isArray(filterValue)) {
      if (filterValue.length === 0) {
        return true
      }

      return filterValue.some(item => String(entityValue).toUpperCase() === String(item).toUpperCase())
    }

    // date
    if (typeof filterValue === 'object' && (filterValue.from && filterValue.to)) {
      const filterDate: { from: string; to: string } = filterValue
      const from = Date.parse(filterDate.from)
      const to = Date.parse(filterDate.to)
      const currentDate = Date.parse(entityValue)

      return currentDate >= from && currentDate <= to
    }

    // string
    return String(entityValue)
      .toUpperCase()
      .includes(String(filterValue).toUpperCase())
  })()

  return isEqualToValue === false ? !match : match
}

export const getObjectFieldValueByPath = (object: any, path: string): any => {
  return path.split('.').reduce((acc, curr) => (acc ? acc[curr] : undefined), object)
}
