export const regExpOnlyNumbers: RegExp = /[^0-9]+/g
export const regExpOnlyLetters: RegExp = /[^a-zA-Z]+/g
export const regExpOnlyNumbersAndLetters: RegExp = /[^a-zA-Z0-9]+/g
// tslint:disable-next-line:max-line-length
export const regExpEmail: RegExp = /^([a-zA-Z0-9_]+[a-zA-Z0-9-_]*[\.]?[a-zA-Z0-9-_]+)@(([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]{2,}\.)+[a-zA-Z0-9-]{2,}$/

export const useRegExp = (_regExp: RegExp | string, str: string, replaceTo?: any): string => {
  let regExp = _regExp

  if (!regExp) {
    return str
  }

  try {
    if (typeof _regExp === 'string') {
      regExp = new RegExp(_regExp, 'g')
    }

    return (str || '').replace(regExp, replaceTo || '')
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(`useRegExp function error (regExp: ${regExp}, str: ${str})`, e)
    return ''
  }
}

export const leaveOnlyNumbersAndLetters = (str: string): string => {
  return useRegExp(regExpOnlyNumbersAndLetters, str)
}
