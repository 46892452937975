import { PowerUnitColumn } from '../../../components/common/grid/columnSettings/powerUnitColumnSettings'
import { ColumnDTO } from '../../../store/reducers/tabs/interfaces'
import { powerUnitLists } from '../../select/powerUnitLists'

export const powerUnitFilterSettings = {
  [PowerUnitColumn.TruckNumber]: {
    name: 'number',
    type: ColumnDTO.Type.number
  },
  [PowerUnitColumn.ActiveAvailability]: {
    name: 'active',
    type: ColumnDTO.Type.list,
    items: [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }],
    disabled: true
  },
  [PowerUnitColumn.Drivers]: {
    name: 'drivers',
    type: ColumnDTO.Type.string,
    disabled: true
  },
  [PowerUnitColumn.Type]: {
    name: 'powerUnitType',
    type: ColumnDTO.Type.list,
    items: powerUnitLists.powerUnitType
  },
  [PowerUnitColumn.VIN]: {
    name: 'vin',
    type: ColumnDTO.Type.string
  },
  [PowerUnitColumn.LicensePlateNumber]: {
    name: 'licensePlate',
    type: ColumnDTO.Type.string
  },
  [PowerUnitColumn.Year]: {
    name: 'year',
    type: ColumnDTO.Type.number
  },
  [PowerUnitColumn.Make]: {
    name: 'make',
    type: ColumnDTO.Type.string
  },
  [PowerUnitColumn.Model]: {
    name: 'model',
    type: ColumnDTO.Type.string
  },
  [PowerUnitColumn.GVW]: {
    name: 'weight',
    type: ColumnDTO.Type.number
  },
  [PowerUnitColumn.Ownership]: {
    name: 'ownership',
    type: ColumnDTO.Type.list,
    items: powerUnitLists.ownership
  },
  [PowerUnitColumn.PortPassRFID]: {
    name: 'portPassRfid',
    type: ColumnDTO.Type.string,
    disabled: true
  },
  [PowerUnitColumn.RegistrationState]: {
    name: 'registrationStateId',
    path: 'registration.stateId',
    type: ColumnDTO.Type.state
  },
  [PowerUnitColumn.RegistrationDate]: {
    name: 'registrationRegisteredDate',
    path: 'registration.registeredDate',
    type: ColumnDTO.Type.date,
    newDateTimePicker: true
  },
  [PowerUnitColumn.RegistrationExpDate]: {
    name: 'registrationExpirationDate',
    path: 'registration.expirationDate',
    type: ColumnDTO.Type.date,
    newDateTimePicker: true
  },
  [PowerUnitColumn.RegistrationGVW]: {
    name: 'registrationWeight',
    path: 'registration.weight',
    type: ColumnDTO.Type.number
  },
  [PowerUnitColumn.RegistrationRestrictions]: {
    name: 'registrationRestrictions',
    path: 'registration.restrictions',
    type: ColumnDTO.Type.list,
    items: powerUnitLists.restrictions
  },
  [PowerUnitColumn.DOTSafetyInspectionDate]: {
    name: 'dotSafetyInspectionPerformedDate',
    path: 'dotSafetyInspection.performedDate',
    type: ColumnDTO.Type.date,
    newDateTimePicker: true
  },
  [PowerUnitColumn.DOTSafetyInspectionAnnualExpDate]: {
    name: 'dotSafetyInspectionExpirationDate',
    path: 'dotSafetyInspection.expirationDate',
    type: ColumnDTO.Type.date,
    newDateTimePicker: true
  },
  [PowerUnitColumn.MaintenanceInspectionFrequency]: {
    name: 'maintenanceInspectionFrequency',
    type: ColumnDTO.Type.list,
    items: powerUnitLists.maintenanceInspectionFrequency
  },
  [PowerUnitColumn.InsuranceType]: {
    name: 'insuranceType',
    path: 'insurance.insuranceType',
    type: ColumnDTO.Type.list,
    items: powerUnitLists.insuranceType
  },
  [PowerUnitColumn.InsuranceCarrier]: {
    name: 'insuranceCarrier',
    path: 'insurance.carrier',
    type: ColumnDTO.Type.string
  },
  [PowerUnitColumn.InsuranceExpDate]: {
    name: 'insuranceExpirationDate',
    path: 'insurance.expirationDate',
    type: ColumnDTO.Type.date,
    newDateTimePicker: true
  },
  [PowerUnitColumn.InsurancePolicyNumber]: {
    name: 'insurancePolicyNumber',
    path: 'insurance.policyNumber',
    type: ColumnDTO.Type.string
  }
}
