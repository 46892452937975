import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import theme from '../../../../../../styles/theme'
import { Widget } from '../../../'
import { Window } from './preview'
import { Popover } from '../popover'
import { makeLocationTitle } from '../../../../../../services/DTO/location/functions'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { isLocationGEOValid } from '../../../../../../services/functions/test/isLocationGEOValid'
import { isLocationValid } from '../../../../../../services/functions/test/isLocationValid'
import { useLocation } from '../../../../../../hooks/useLocation'
import { isFullObject } from '../../../../../../services/functions/test/isFullObject'

type Props = {
  disableWidget?: boolean
  widgetTitle?: string
  isExternal?: boolean
  id: string | undefined
  changeLocationId: (id: string) => void
  required?: boolean
  disabled?: boolean
  isShortName?: boolean
  widgetPreviewClassName?: string
}

export const LocationWidget = (props: Props) => {
  const {
    disabled,
    disableWidget,
    widgetTitle,
    isExternal,
    id,
    changeLocationId,
    required,
    isShortName,
    widgetPreviewClassName
  } = props
  const { modifiedLists } = useExpandedItem()
  const location = useLocation({ id, modifiedLists })
  const isModifiedMode = Boolean(oc(modifiedLists)[EntityType.location][id]())
  const isLocationGEOInvalid = isLocationGEOValid(location) === false

  let title = ''
  if (location) {
    if (isShortName) {
      title = isFullObject(location)
        ? oc(location).code() || oc(location).name() || makeLocationTitle(location)
        : oc(location).shortName() || oc(location).longName() || oc(location).name()
    } else {
      title = isFullObject(location)
        ? makeLocationTitle(location)
        : [oc(location).shortName(), oc(location).cityStateZip()].filter(Boolean).join(', ')
    }
  }

  return (
    <Widget
      disable={disableWidget && !isLocationGEOInvalid}
      red={isLocationGEOInvalid}
      title={widgetTitle || 'Location'}
      isExternal={isExternal}
      highlighted={isLocationGEOInvalid || (isFullObject(location) && !isLocationValid(location))}
      required={required}
      value={location}
      widgetPreviewClassName={widgetPreviewClassName}
      isTableWidget={true}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        title,
        disabled: disableWidget || disabled
      }}
      detailsProps={{
        disableEditing: disableWidget || disabled,
        changeLocationId,
        location,
        isModifiedMode
      }}
    />
  )
}

export const StyledWindow = styled.span`
  padding-right: 20px;

  &:before {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 30px;
    color: ${theme.colors.basicBlueColor} !important;
    font-size: 18px !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
`
