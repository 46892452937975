import * as React from 'react'
import cn from 'classnames'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'
import styled from 'styled-components'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../api/api'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { saveDispatchDeliveryOrder } from '../../../../../../services/saveEntity/saveDispatchDeliveryOrder'

type Props = {}

export const AcceptRejectButtons = React.memo((props: Props) => {
  const { isModified, setFetching } = useExpandedItem()
  const { dispatchDeliveryOrder } = useGridItemDDO()
  const statusMapping = {
    [DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED]: 'Accept',
    [DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED]: 'Reject'
  }

  if (dispatchDeliveryOrder.status !== DispatchDeliveryOrderViewDTO.StatusEnum.NEW) {
    return null
  }

  return (
    <Container>
      {[DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED, DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED].map(
        status => {
          const disabled = isModified

          return (
            <Button
              key={status}
              className={cn(ddoDirectory.statusIconClassName[status], { disabled })}
              style={{
                backgroundColor: ddoDirectory.statusColor[status],
                width: 100
              }}
              children={statusMapping[status] || 'Status'}
              onClick={
                disabled
                  ? undefined
                  : () => {
                      setFetching(true)
                      saveDispatchDeliveryOrder({
                        dispatchDeliveryOrder: { ...dispatchDeliveryOrder, status }
                      }).finally(() => setFetching(false))
                    }
              }
            />
          )
        }
      )}
    </Container>
  )
})

const Container = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  align-self: stretch;
  align-items: center;
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 0 15px;
  margin: 0 15px;
  user-select: none;
`

const Button = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
  background-color: #4555d0;
  color: white;
  padding: 0 9px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:before {
    font-size: 18px;
    margin-right: 5px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
`
